import React from "react";
import { IconProps } from "icons/types";

interface EmptyProps {
  Icon: React.FC<IconProps>;
  title: string;
  subtitle?: string;
}

export const Empty: React.FC<EmptyProps> = ({ Icon, title, subtitle }) => (
  <section className="flex flex-1 flex-col items-center justify-center">
    <div className="flex w-[64px] md:w-[56px] aspect-square bg-gray1 rounded-full items-center justify-center text-gray9">
      <Icon size={36} />
    </div>

    <span className="text-white mt-6 md:mt-4 text-xl md:text-lg sm:text-base font-bold">
      {title}
    </span>

    {subtitle && (
      <span className="text-gray6 mt-2 font-normal text-base max-w-[260px] text-center">
        {subtitle}
      </span>
    )}
  </section>
);
