import React from "react";

import { IconProps } from "./types";

export const ChevronLeftIcon: React.FC<IconProps> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3664 2.17574C10.1321 1.94142 9.75216 1.94142 9.51785 2.17574L4.11785 7.57574C3.88354 7.81005 3.88354 8.18995 4.11785 8.42426L9.51785 13.8243C9.75216 14.0586 10.1321 14.0586 10.3664 13.8243C10.6007 13.5899 10.6007 13.2101 10.3664 12.9757L5.39064 8L10.3664 3.02426C10.6007 2.78995 10.6007 2.41005 10.3664 2.17574Z"
      fill="currentColor"
    />
  </svg>
);
