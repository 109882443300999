import React from "react";

import { IconProps } from "./types";

export const ClockIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M12.2412 6C12.7935 6 13.2412 6.44772 13.2412 7V12C13.2412 12.5523 12.7935 13 12.2412 13H7.24121C6.68893 13 6.24121 12.5523 6.24121 12C6.24121 11.4477 6.68893 11 7.24121 11H11.2412V7C11.2412 6.44772 11.6889 6 12.2412 6Z"
      fill="currentColor"
    />
    <path
      d="M22.2412 12C22.2412 17.5228 17.7641 22 12.2412 22C6.71836 22 2.24121 17.5228 2.24121 12C2.24121 6.47715 6.71836 2 12.2412 2C17.7641 2 22.2412 6.47715 22.2412 12ZM20.2412 12C20.2412 16.4183 16.6595 20 12.2412 20C7.82293 20 4.24121 16.4183 4.24121 12C4.24121 7.58172 7.82293 4 12.2412 4C16.6595 4 20.2412 7.58172 20.2412 12Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
    />
  </svg>
);
