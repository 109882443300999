import React from "react";

import { getProfilePicture } from "utils/getProfilePicture";
import { Image } from "components/Image";
import { PlusIcon } from "icons/PlusIcon";
import { CloseIcon } from "icons/CloseIcon";
import { User } from "types";

interface ItemUserProps {
  user: User;
  isShowPlus?: boolean;
  isShowClose?: boolean;
  onClick?: () => void;
}

export const ItemUser: React.FC<ItemUserProps> = ({
  user,
  isShowPlus,
  isShowClose,
  onClick,
}) => (
  <div
    className={`relative flex items-center p-2 sm:p-1 pr-4 sm:pr-4 rounded-full border border-gray4 bg-gray3 ${
      onClick ? "btn-hover-after" : ""
    }`}
    onClick={onClick}
  >
    {isShowPlus && (
      <div className="text-white mx-2">
        <PlusIcon size={16} />
      </div>
    )}

    <Image
      rounded="full"
      size={32}
      iconSize={20}
      src={getProfilePicture(user)}
    />

    <span className="text-white text-sm font-normal ml-2">
      {user.name} {user.surname}
    </span>

    {isShowClose && (
      <div className="text-white ml-2">
        <CloseIcon size={16} />
      </div>
    )}
  </div>
);
