import React, { useCallback } from "react";
import { IconProps, ToastContainer, TypeOptions } from "react-toastify";

import { CloseIcon } from "icons/CloseIcon";
import { SuccessIcon } from "icons/SuccessIcon";
import { ErrorIcon } from "icons/ErrorIcon";
import { InfoIcon } from "icons/InfoIcon";

export const Toast: React.FC = () => {
  const toastClassName = useCallback(
    (props?: { type?: TypeOptions; defaultClassName?: string }) => {
      const className = `${props?.defaultClassName} p-0 pl-4 min-h-[46px] rounded-lg text-sm font-normal border sm:mb-4`;

      switch (props?.type) {
        case "success":
          return `${className} bg-[#012920] border-lightGreen text-lightGreen`;
        case "error":
          return `${className} bg-[#2f1111] border-red text-red`;

        default:
          return `${className} border-white text-black`;
      }
    },
    []
  );

  const closeButton = useCallback(
    () => (
      <div className="flex h-[46px] ml-4 aspect-square justify-center items-center">
        <CloseIcon size={16} />
      </div>
    ),
    []
  );

  const icon = useCallback(({ type }: IconProps) => {
    switch (type) {
      case "success":
        return <SuccessIcon />;
      case "info":
        return <InfoIcon />;
      case "error":
        return <ErrorIcon />;
    }
  }, []);

  return (
    <ToastContainer
      hideProgressBar
      position="top-center"
      className="w-auto min-w-[300px] sm:left-[50%] sm:-translate-x-[50%] sm:top-4 sm:p-1"
      toastClassName={toastClassName}
      bodyClassName="p-0 m-0"
      closeButton={closeButton}
      icon={icon}
    />
  );
};
