import React from "react";

import { IconProps } from "./types";

export const DashedCircle: React.FC<IconProps> = ({ size = 40 }) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none">
    <circle
      cx="20"
      cy="20"
      r="19.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeDasharray="6 6"
    />
  </svg>
);
