import React from "react";

import { IconProps } from "./types";

export const UploadIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M12.8149 7.41564V15C12.8149 15.5531 12.3681 16 11.8149 16C11.2618 16 10.8149 15.5531 10.8149 15V7.41564L8.52119 9.70939C8.13057 10.1 7.49619 10.1 7.10557 9.70939C6.71494 9.31876 6.71494 8.68439 7.10557 8.29376L11.1056 4.29376C11.4962 3.90314 12.1306 3.90314 12.5212 4.29376L16.5212 8.29376C16.9118 8.68439 16.9118 9.31876 16.5212 9.70939C16.1306 10.1 15.4962 10.1 15.1056 9.70939L12.8149 7.41564ZM5.81494 15H9.81494C9.81494 16.1031 10.7118 17 11.8149 17C12.9181 17 13.8149 16.1031 13.8149 15H17.8149C18.9181 15 19.8149 15.8969 19.8149 17V18C19.8149 19.1031 18.9181 20 17.8149 20H5.81494C4.71182 20 3.81494 19.1031 3.81494 18V17C3.81494 15.8969 4.71182 15 5.81494 15ZM17.3149 18.25C17.5139 18.25 17.7046 18.171 17.8453 18.0303C17.9859 17.8897 18.0649 17.6989 18.0649 17.5C18.0649 17.3011 17.9859 17.1103 17.8453 16.9697C17.7046 16.829 17.5139 16.75 17.3149 16.75C17.116 16.75 16.9253 16.829 16.7846 16.9697C16.644 17.1103 16.5649 17.3011 16.5649 17.5C16.5649 17.6989 16.644 17.8897 16.7846 18.0303C16.9253 18.171 17.116 18.25 17.3149 18.25Z"
      fill="currentColor"
    />
  </svg>
);
