import React, { useCallback } from "react";
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { Badge } from "components/Badge";

type MenuItem = { title: string; route: string; isBadge?: boolean };

interface HorizontalMenuProps {
  menuItems: MenuItem[];
  disableOffset?: boolean;
}

export const HorizontalMenu: React.FC<HorizontalMenuProps> = ({
  menuItems,
  disableOffset,
}) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const nav = useCallback(
    (route: string) => {
      navigate(generatePath(route, params), { replace: true });
    },
    [params, navigate]
  );

  return (
    <section
      className={`flex border-gray3 border-b ${
        disableOffset ? "" : "px-8 md:px-6 sm:px-4"
      }`}
    >
      {menuItems.map((item) => {
        const isActive = Boolean(matchPath(item.route, location.pathname));

        return (
          <span
            key={item.route}
            className={`relative text-base sm:text-sm sm:mr-0 sm:flex-grow sm:text-center font-normal py-1 mr-8 cursor-pointer ${
              isActive ? "border-b-2 border-green text-white" : "text-gray6"
            }`}
            onClick={() => nav(item.route)}
          >
            {item.title}

            {item.isBadge && <Badge small />}
          </span>
        );
      })}
    </section>
  );
};
