import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useTypedOutletContext } from "hooks/useTypedOutletContext";
import { SER } from "utils/serverErrorHandler";
import { getProfilePicture } from "utils/getProfilePicture";
import { ModalNewConnection } from "components/Modal/ModalNewConnection";
import { DropdownMenuContact } from "components/DropdownMenu/DropdownMenuContact";
import { Button } from "components/Button";
import { Image } from "components/Image";
import { Search } from "components/Search";
import { Badge } from "components/Badge";
import { Empty } from "components/Empty";
import { Loading } from "components/Loading";
import { NetworkIcon } from "icons/NetworkIcon";
import { PlusIcon } from "icons/PlusIcon";
import { Contact } from "types";

export const LaboratoryDataConnections: React.FC = () => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contactsPending, setContactsPending] = useState<Contact[]>([]);
  const [contactsOutgoing, setContactsOutgoing] = useState<Contact[]>([]);
  const [isModalNewConnection, setIsModalNewConnection] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  const { t } = useTranslation();

  const { permissions } = useTypedOutletContext();

  const labId = useMemo(() => Number(params.labId), [params]);

  const getContacts = useCallback(() => {
    SER(async () => {
      setIsLoading(true);

      const { data: contacts } = await serverApi.getContacts(labId);
      setContacts(contacts.data);

      setIsLoading(false);
    });
  }, [labId]);

  const getContactRequests = useCallback(() => {
    if (permissions.connections) {
      SER(async () => {
        const [{ data: contactsPending }, { data: contactsOutgoing }] =
          await Promise.all([
            serverApi.getContactsPending(labId),
            serverApi.getContactsOutgoing(labId),
          ]);

        setContactsPending(contactsPending.data);
        setContactsOutgoing(contactsOutgoing.data);
      });
    }
  }, [permissions, labId]);

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  useEffect(() => {
    getContactRequests();
  }, [getContactRequests]);

  const showModalNewConnection = useCallback(() => {
    setIsModalNewConnection(true);
  }, []);

  const hideModalNewConnection = useCallback(() => {
    setIsModalNewConnection(false);
    getContacts();
    getContactRequests();
  }, [getContacts, getContactRequests]);

  const disconnectContactCB = useCallback(() => {
    getContacts();
    getContactRequests();
  }, [getContacts, getContactRequests]);

  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target }) => {
      setSearchValue(target.value);
    }, []);

  const filteredContacts = useMemo(
    () =>
      contacts.filter((contact) =>
        contact.name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [contacts, searchValue]
  );

  const contactsComponent = useMemo(
    () => (
      <>
        <div className="mt-6 sm:mt-4 flex min-h-[48px] border-b border-gray3 items-center">
          <div className="w-[72px]" />

          <div className="px-4">
            <span className="text-gray6 text-sm font-normal">
              {t("common.name")}
            </span>
          </div>
        </div>

        {filteredContacts.map((contact) => (
          <div
            key={contact.id}
            className="group flex py-3 pr-8 sm:pr-6 items-center justify-between transition-all duration-200 ease-in-out hover:bg-gray1"
          >
            <div className="flex flex-1 items-center">
              <div className="w-[72px] px-4">
                <Image
                  rounded="lg"
                  size={40}
                  iconSize={20}
                  src={getProfilePicture(contact)}
                />
              </div>
              <div className="px-4 flex flex-col">
                <span className="text-base font-semibold text-white leading-5">
                  {contact.name}
                </span>
                <span className="text-base font-normal text-gray6 leading-5">
                  {contact.realm}
                </span>
              </div>
            </div>

            {permissions.connections && (
              <DropdownMenuContact
                contact={contact}
                disconnectContactCB={disconnectContactCB}
              />
            )}
          </div>
        ))}
      </>
    ),
    [t, permissions, filteredContacts, disconnectContactCB]
  );

  return (
    <>
      <div className="flex justify-between sm:flex-wrap sm:justify-end">
        {contacts.length ? (
          <Search
            placeholder={t("common.search_data")}
            onChange={onChangeSearch}
          />
        ) : (
          <div className="flex-1" />
        )}

        {permissions.connections && (
          <div className={`relative ${contacts.length ? "sm:mt-4" : ""}`}>
            <Button IconBefore={PlusIcon} onClick={showModalNewConnection}>
              {t("common.new_connection")}
            </Button>

            {contactsOutgoing.length || contactsPending.length ? (
              <Badge />
            ) : null}
          </div>
        )}
      </div>

      {isLoading ? (
        <Loading />
      ) : contacts.length ? (
        contactsComponent
      ) : (
        <Empty
          Icon={NetworkIcon}
          title={t("common.no_connection_active_warning.0")}
          subtitle={t("common.no_connection_active_warning.1")}
        />
      )}

      {isModalNewConnection && labId ? (
        <ModalNewConnection
          labId={labId}
          contactsPending={contactsPending}
          contactsOutgoing={contactsOutgoing}
          hideModal={hideModalNewConnection}
        />
      ) : null}
    </>
  );
};
