import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Outlet,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { ROUTES } from "constants/routes";
import { SER } from "utils/serverErrorHandler";
import { getDeviceDetailsRoute } from "utils/getDeviceDetailsRoute";
import { getDevicesMessagesUnreadCount } from "utils/getDevicesMessagesUnreadCount";
import { Header } from "components/Header";
import { HorizontalMenu } from "components/HorizontalMenu";
import { ItemDevice } from "components/Item/ItemDevice";
import { Button } from "components/Button";
import { ChevronLeftIcon } from "icons/ChevronLeftIcon";
import { CopyIcon } from "icons/CopyIcon";
import { RefreshIcon } from "icons/RefreshIcon";
import { Device } from "types";

export const DeviceLayout: React.FC = () => {
  const { t } = useTranslation();
  const { deviceType, unitKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [device, setDevice] = useState<Device | undefined>(location.state);
  const [filteredDevices, setFilteredDevices] = useState<Device[]>([]);
  const [refreshCount, setRefreshCount] = React.useState(0);

  const getDevice = useCallback(() => {
    if (unitKey) {
      SER(async () => {
        const devices = await getDevicesMessagesUnreadCount([unitKey]);

        if (devices.length) {
          setDevice(devices[0]);
        } else {
          navigate(generatePath(ROUTES.DEVICES, { deviceType: deviceType! }));
        }
      });
    }
  }, [unitKey, deviceType, navigate]);

  const getFilteredDevices = useCallback(() => {
    SER(async () => {
      const { data: machines } = await serverApi.getUserMachineList();
      const { data: devices } = await serverApi.getDevices(
        machines.data.map(({ uuid }) => uuid)
      );

      const filteredDevices = devices
        .filter((device) => device.type === deviceType)
        .sort((a, b) => (a.unitName > b.unitName ? 1 : -1));

      setFilteredDevices(filteredDevices);
    });
  }, [deviceType]);

  useEffect(() => {
    getDevice();
    getFilteredDevices();
  }, [getDevice, getFilteredDevices]);

  const refresh = useCallback(() => {
    setRefreshCount(refreshCount + 1);
    getDevice();
  }, [refreshCount, getDevice]);

  const currentDeviceIndex = useMemo(
    () => filteredDevices.findIndex((device) => device.unitKey === unitKey),
    [filteredDevices, unitKey]
  );

  const changeDevice = useCallback(
    (side: "left" | "right") => {
      let nextDeviceIndex = currentDeviceIndex + (side === "left" ? -1 : 1);

      if (nextDeviceIndex === filteredDevices.length) nextDeviceIndex = 0;
      else if (nextDeviceIndex === -1)
        nextDeviceIndex = filteredDevices.length - 1;

      const nextDevice = filteredDevices[nextDeviceIndex];

      navigate(getDeviceDetailsRoute(nextDevice), {
        state: nextDevice,
        replace: true,
      });
    },
    [filteredDevices, currentDeviceIndex, navigate]
  );

  const copyUnitKey = useCallback(() => {
    if (device) {
      navigator.clipboard.writeText(device.unitKey);
    }
  }, [device]);

  const menuItems = useMemo(
    () =>
      deviceType === "milling"
        ? [
            { title: t("common.processes"), route: ROUTES.DEVICE_PROCESSES },
            {
              title: t("common.maintenance"),
              route: ROUTES.DEVICE_MAINTENANCE,
            },
            {
              title: t("common.messages"),
              route: ROUTES.DEVICE_MESSAGES,
            },
          ]
        : [
            { title: t("common.process"), route: ROUTES.DEVICE_PROCESS },
            { title: t("common.history"), route: ROUTES.DEVICE_HISTORY },
            {
              title: t("common.messages"),
              route: ROUTES.DEVICE_MESSAGES,
            },
          ],
    [t, deviceType]
  );

  const deviceButtonComponent = useCallback(
    (side: "left" | "right") => {
      const isActive = filteredDevices.length > 1 && currentDeviceIndex !== -1;

      return (
        <div
          className="absolute w-[48px] aspect-square rounded-2xl flex items-center justify-center bg-gray3 top-[170px]"
          style={{
            [side]: "-18px",
            rotate: `${side === "left" ? "0" : "180"}deg`,
            color: isActive ? "white" : "#4D4D4D",
            cursor: isActive ? "pointer" : "auto",
          }}
          onClick={() => isActive && changeDevice(side)}
        >
          <ChevronLeftIcon size={24} />
        </div>
      );
    },
    [filteredDevices, currentDeviceIndex, changeDevice]
  );

  return (
    <>
      <Header
        title={t("common.devices")}
        backRoute={generatePath(ROUTES.DEVICES, { deviceType: "all" })}
      >
        <Button type="grayNoOffset" IconBefore={RefreshIcon} onClick={refresh}>
          {t("common.refresh")}
        </Button>
      </Header>

      {device ? (
        <section className="flex flex-1 pt-6 pl-12 xl:pl-8 pr-8 pb-8 md:p-6 sm:p-0 min-h-0">
          <div className="lg:hidden">
            <ItemDevice device={device}>
              {deviceButtonComponent("left")}
              {deviceButtonComponent("right")}
            </ItemDevice>

            <div className="mt-4 p-4 text-white bg-gray1 rounded-lg flex items-center justify-between">
              <span className="text-base font-normal">{device.unitKey}</span>

              <div
                className="cursor-pointer hover:opacity-50 active:opacity-25"
                onClick={copyUnitKey}
              >
                <CopyIcon />
              </div>
            </div>
          </div>

          <div className="ml-12 xl:ml-8 lg:ml-0 pl-12 xl:pl-8 lg:pl-0 border-l lg:border-0 border-gray3 flex flex-1 flex-col overflow-hidden">
            <HorizontalMenu disableOffset menuItems={menuItems} />

            <Outlet key={refreshCount} />
          </div>
        </section>
      ) : null}
    </>
  );
};
