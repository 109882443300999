import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { UpdateLabUsersData } from "api/server";
import { useAuthProvider } from "provider";
import { SER } from "utils/serverErrorHandler";
import { Modal } from "components/Modal/Modal";
import { ItemRoleGroup } from "components/Item/ItemRoleGroup";
import { ItemUser } from "components/Item/ItemUser";
import { Button } from "components/Button";
import { Select } from "components/Select";
import { Id, Group, User, Role } from "types";

interface ModalEditMemberProps {
  labId: Id;
  users: User[];
  hideModal: () => void;
}

export const ModalEditMember: React.FC<ModalEditMemberProps> = ({
  labId,
  users,
  hideModal,
}) => {
  const [labRoles, setLabRoles] = useState<Role[]>([]);
  const [labGroups, setLabGroups] = useState<Group[]>([]);
  const [selectedRoleId, setSelectedRoleId] = useState<Id | null>(null);
  const [selectedGroupIds, setSelectedGroupIds] = useState<Id[]>([]);
  const [isShowRoleSelect, setIsShowRoleSelect] = useState(false);

  const { t } = useTranslation();
  const { user } = useAuthProvider();

  const getData = useCallback(() => {
    SER(async () => {
      if (user) {
        const [
          { data: labGroups },
          { data: labRoles },
          { data: currentUserRole },
        ] = await Promise.all([
          serverApi.getLabGroups(labId),
          serverApi.getLabRoles(labId),
          serverApi.getUserRole(labId, user.id),
        ]);

        setLabRoles(
          labRoles.data.filter(
            (role: Role) => role.r_index > currentUserRole.data.r_index
          )
        );

        if (users.length === 1 && users[0].id === user.id) {
          setIsShowRoleSelect(false);
        } else {
          const isShowRoleSelect = users
            .filter(({ id }) => user.id !== id)
            .every(({ role }) => role.r_index > currentUserRole.data.r_index);

          setIsShowRoleSelect(isShowRoleSelect);

          if (users.length === 1 && isShowRoleSelect) {
            setSelectedRoleId(users[0].role.id);
          }
        }

        setLabGroups(labGroups.data);

        if (users.length === 1) {
          setSelectedGroupIds(users[0].groups.map((group) => group.id));
        }
      }
    }, hideModal);
  }, [labId, user, users, hideModal]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = useCallback(() => {
    const data: UpdateLabUsersData = {
      members: users.map((user) => user.id),
      groups: selectedGroupIds,
    };

    if (selectedRoleId) data.role = selectedRoleId;

    SER(async () => {
      await serverApi.updateLabUsers(labId, data);
      hideModal();
    });
  }, [labId, users, selectedRoleId, selectedGroupIds, hideModal]);

  const options = useMemo(
    () => labRoles.map((role) => ({ value: role.id, label: role.name })),
    [labRoles]
  );

  const selectedGroups = useMemo(
    () => labGroups.filter(({ id }) => selectedGroupIds.includes(id)),
    [labGroups, selectedGroupIds]
  );

  const notSelectedGroups = useMemo(
    () => labGroups.filter(({ id }) => !selectedGroupIds.includes(id)),
    [labGroups, selectedGroupIds]
  );

  const selectGroup = useCallback(
    (groupId: Id) => {
      setSelectedGroupIds([...new Set([...selectedGroupIds, groupId])]);
    },
    [selectedGroupIds]
  );

  const deselectGroup = useCallback(
    (groupId: Id) => {
      setSelectedGroupIds(selectedGroupIds.filter((id) => id !== groupId));
    },
    [selectedGroupIds]
  );

  const titleComponent = useCallback(
    (title: string) => (
      <h2 className="mb-6 sm:mb-4 text-white font-bold text-base leading-5">
        {title}
      </h2>
    ),
    []
  );

  const emptyComponent = useCallback(
    (title: string) => (
      <div className="h-[64px] border border-dashed border-gray3 flex items-center justify-center rounded-lg">
        <span className="text-gray6 text-sm font-normal">{title}</span>
      </div>
    ),
    []
  );

  const groupComponent = useCallback(
    (group: Group, isSelected: boolean) => (
      <ItemRoleGroup
        key={group.id}
        title={group.name}
        isShowPlus={!isSelected}
        isShowClose={isSelected}
        onClick={() =>
          isSelected ? deselectGroup(group.id) : selectGroup(group.id)
        }
      />
    ),
    [deselectGroup, selectGroup]
  );

  return (
    <Modal
      title={
        users.length === 1 ? t("common.edit_member") : t("common.edit_members")
      }
      footer={
        <div className="flex flex-1 justify-end">
          <Button type="transparent" onClick={hideModal}>
            {t("common.cancel")}
          </Button>
          <Button onClick={onSubmit}>{t("common.save_changes")}</Button>
        </div>
      }
    >
      <div>
        <div className="flex flex-wrap gap-x-4 gap-y-2 px-8 py-6 sm:px-6 sm:py-4 border-b border-gray3">
          {users.map((user) => (
            <ItemUser key={user.id} user={user} />
          ))}
        </div>

        {isShowRoleSelect && (
          <div className="px-8 py-6 sm:px-6 sm:py-4 border-b border-gray3">
            {titleComponent(t("common.role"))}

            <Select
              options={options}
              value={selectedRoleId}
              setValue={setSelectedRoleId}
            />
          </div>
        )}

        <div className="px-8 py-6 sm:px-6 sm:py-4">
          {titleComponent(t("common.groups"))}

          {selectedGroups.length ? (
            <div className="flex flex-wrap gap-x-4 gap-y-2">
              {selectedGroups.map((group) => groupComponent(group, true))}
            </div>
          ) : (
            emptyComponent(t("common.add_group_warning"))
          )}
        </div>

        {!notSelectedGroups.length ? null : (
          <div className="px-8 py-6 sm:px-6 sm:py-4 bg-gray3 flex flex-wrap gap-x-4 gap-y-2">
            {notSelectedGroups.map((group) => groupComponent(group, false))}
          </div>
        )}
      </div>
    </Modal>
  );
};
