import { serverInstance as api } from "api/instances";
import { createUrlencoded } from "utils/createUrlencoded";

type SoftwareName = "machines";

const URL = "software";

const getSoftwareId = async () => {
  const { data: software } = await api.get(URL);

  return software.data.find(
    (item: { name: string; id: string }) => item.name === "LabDashboard"
  ).id;
};

export const getSoftwareData = async (softwareName: SoftwareName) => {
  const softwareId = await getSoftwareId();

  return api.get(`${URL}/data/${softwareId}/${softwareName}`);
};

export const setSoftwareData = async (
  softwareName: SoftwareName,
  data: { order: string[] }
) => {
  const softwareId = await getSoftwareId();

  return api.request({
    method: "post",
    url: `${URL}/data/${softwareId}/${softwareName}`,
    data: createUrlencoded(data),
  });
};
