import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useAuthProvider } from "provider";
import { useTypedOutletContext } from "hooks/useTypedOutletContext";
import { SER } from "utils/serverErrorHandler";
import { getProfilePicture } from "utils/getProfilePicture";
import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { DropdownMenuMember } from "components/DropdownMenu/DropdownMenuMember";
import { ModalAddMember } from "components/Modal/ModalAddMember";
import { ModalMembersFilter } from "components/Modal/ModalMembersFilter";
import { ModalGroups } from "components/Modal/ModalGroups";
import { ItemRoleGroup } from "components/Item/ItemRoleGroup";
import { Button } from "components/Button";
import { Image } from "components/Image";
import { ThreeDotsButton } from "components/ThreeDotsButton";
import { Search } from "components/Search";
import { Badge } from "components/Badge";
import { Loading } from "components/Loading";
import { CheckboxIcon } from "icons/CheckboxIcon";
import { CloseIcon } from "icons/CloseIcon";
import { PlusIcon } from "icons/PlusIcon";
import { ThreeDotsVerticalIcon } from "icons/ThreeDotsVerticalIcon";
import { Id, User } from "types";

const groupsToShow = 3;
const iconSize = getCurrentScreenData({ sm: 20, all: 24 });

export const LaboratoryMembers: React.FC = () => {
  const [labUsers, setLabUsers] = useState<User[]>([]);
  const [isModalAddMember, setIsModalAddMember] = useState(false);
  const [isModalGroups, setIsModalGroups] = useState(false);
  const [isModalMembersFilter, setIsModalMembersFilter] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<Id[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Id[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [isSelectionEnabled, setIsSelectionEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  const { t } = useTranslation();

  const { user } = useAuthProvider();
  const { permissions, labJoinRequestList, getLabJoinRequestList } =
    useTypedOutletContext();

  const labId = useMemo(() => Number(params.labId), [params]);

  const getLabData = useCallback(async () => {
    SER(async () => {
      setIsLoading(true);

      const { data: labUsers } = await serverApi.getLabUsersSummary(labId);
      setLabUsers(labUsers.data);

      setIsLoading(false);
    });
  }, [labId]);

  useEffect(() => {
    getLabData();
  }, [getLabData]);

  const showModalMembersFilter = useCallback(() => {
    setIsModalMembersFilter(true);
  }, []);

  const hideModalMembersFilter = useCallback(() => {
    setIsModalMembersFilter(false);
    setSelectedUsers([]);
  }, []);

  const showModalAddMember = useCallback(() => {
    setIsModalAddMember(true);
  }, []);

  const hideModalAddMember = useCallback(() => {
    setIsModalAddMember(false);
    getLabData();
    getLabJoinRequestList();
  }, [getLabData, getLabJoinRequestList]);

  const hideModalCB = useCallback(() => {
    setSelectedUsers([]);
    getLabData();
  }, [getLabData]);

  const showModalGroups = useCallback((user: User) => {
    setSelectedUsers([user]);
    setIsModalGroups(true);
  }, []);

  const hideModalGroups = useCallback(() => {
    setIsModalGroups(false);
    setSelectedUsers([]);
  }, []);

  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target }) => {
      setSearchValue(target.value);
    }, []);

  const filteredLabUsers = useMemo(
    () =>
      labUsers.filter((labUser) => {
        const searchFilter = `${labUser.name} ${labUser.surname}`
          .toLowerCase()
          .includes(searchValue.toLowerCase());
        const roleFilter = selectedRoles.length
          ? selectedRoles.includes(labUser.role.id)
          : true;
        const groupFilter = selectedGroups.length
          ? labUser.groups.some(({ id }) => selectedGroups.includes(id))
          : true;

        return searchFilter && roleFilter && groupFilter;
      }),
    [labUsers, searchValue, selectedRoles, selectedGroups]
  );

  const selectUser = useCallback(
    (user: User) => {
      const newSelectedUsers = selectedUsers.find(({ id }) => id === user.id)
        ? selectedUsers.filter(({ id }) => id !== user.id)
        : [...selectedUsers, user];

      setSelectedUsers(newSelectedUsers);
      setIsSelectionEnabled(Boolean(newSelectedUsers.length));
    },
    [selectedUsers, setSelectedUsers]
  );

  const selectAllUsers = useCallback(() => {
    const newSelectedUsers =
      selectedUsers.length === filteredLabUsers.length ? [] : filteredLabUsers;

    setSelectedUsers(newSelectedUsers);
    setIsSelectionEnabled(Boolean(newSelectedUsers.length));
  }, [selectedUsers, filteredLabUsers]);

  useEffect(() => {
    if (selectedUsers.length === 0) setIsSelectionEnabled(false);
  }, [selectedUsers]);

  const checkboxComponent = useCallback(
    (isActive: boolean, user?: User) => (
      <div
        className="cursor-pointer"
        onClick={() => (user ? selectUser(user) : selectAllUsers())}
      >
        {isActive ? (
          <CheckboxIcon />
        ) : (
          <div className="min-w-[24px] aspect-square rounded-full transition-all duration-200 ease-in-out border border-gray6 bg-gray3 opacity-0 group-hover:opacity-100 sm:opacity-100" />
        )}
      </div>
    ),
    [selectUser, selectAllUsers]
  );

  const labUsersComponent = useMemo(
    () => (
      <div className="xl:overflow-x-scroll xl:max-w-[calc(100vw-128px)] md:max-w-[calc(100vw-112px)] sm:max-w-[calc(100vw-32px)]">
        <div className="xl:min-w-[1150px]">
          <div className="group mt-6 sm:mt-4 pr-16 flex min-h-[48px] border-b border-gray3 items-center">
            <div className="min-w-[56px] px-4">
              {permissions.members &&
                checkboxComponent(
                  isSelectionEnabled &&
                    selectedUsers.length === filteredLabUsers.length
                )}
            </div>
            <div className="min-w-[72px]" />
            <div className="w-3/12 px-4">
              <span className="text-gray6 text-sm font-normal">
                {t("common.name")}
              </span>
            </div>
            <div className="w-3/12 px-4">
              <span className="text-gray6 text-sm font-normal">
                {t("common.username")}
              </span>
            </div>
            <div className="w-2/12 px-4">
              <span className="text-gray6 text-sm font-normal">
                {t("common.role")}
              </span>
            </div>
            <div className="w-4/12 px-4">
              <span className="text-gray6 text-sm font-normal">
                {t("common.groups")}
              </span>
            </div>
          </div>

          {filteredLabUsers.map((labUser) => {
            const isActive =
              isSelectionEnabled &&
              selectedUsers.map(({ id }) => id).includes(labUser.id);

            const isHighlighted =
              !isSelectionEnabled && selectedUsers[0]?.id === labUser.id;

            return (
              <div
                key={labUser.id}
                className={`group flex py-2 pr-8 items-center justify-between transition-all duration-200 ease-in-out ${
                  isActive ? "bg-gray3" : "hover:bg-gray1"
                } ${isHighlighted ? "bg-gray1" : ""}`}
              >
                <div className="min-w-[56px] px-4">
                  {permissions.members && checkboxComponent(isActive, labUser)}
                </div>
                <div className="min-w-[72px] px-4">
                  <Image
                    rounded="full"
                    size={40}
                    iconSize={20}
                    src={getProfilePicture(labUser)}
                  />
                </div>
                <div className="w-3/12 px-4">
                  <span className="text-white text-base font-normal">
                    {labUser.name} {labUser.surname}
                    {labUser.id === user?.id ? (
                      <span className="text-gray6"> ({t("common.you")})</span>
                    ) : null}
                  </span>
                </div>
                <div className="w-3/12 px-4">
                  <span className="text-white text-base font-normal">
                    {labUser.username}
                  </span>
                </div>

                <div className="w-2/12 px-4">
                  <span className="text-white text-base font-normal capitalize">
                    {labUser.role.name}
                  </span>
                </div>

                <div className="w-4/12 px-4 flex flex-wrap gap-x-4 gap-y-2">
                  {labUser.groups.slice(0, groupsToShow).map((group) => (
                    <ItemRoleGroup
                      key={group.id}
                      title={group.name}
                      onClick={() => showModalGroups(labUser)}
                    />
                  ))}

                  {labUser.groups.length > groupsToShow && (
                    <ItemRoleGroup
                      isTransparent
                      title={`+${labUser.groups.length - groupsToShow}`}
                      onClick={() => showModalGroups(labUser)}
                    />
                  )}
                </div>

                {permissions.members && !isSelectionEnabled ? (
                  <div
                    className={`transition-all duration-200 ease-in-out ${
                      isHighlighted
                        ? "opacity-100"
                        : "opacity-0 group-hover:opacity-100 sm:opacity-100"
                    }`}
                  >
                    <DropdownMenuMember
                      labId={labId}
                      members={[labUser]}
                      hideModalCB={hideModalCB}
                    >
                      <ThreeDotsButton />
                    </DropdownMenuMember>
                  </div>
                ) : (
                  <div className="min-w-[32px]" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    ),
    [
      t,
      permissions,
      filteredLabUsers,
      isSelectionEnabled,
      selectedUsers,
      user,
      labId,
      checkboxComponent,
      showModalGroups,
      hideModalCB,
    ]
  );

  return (
    <>
      <div className="flex justify-between lg:flex-wrap lg:justify-end">
        {labUsers.length && (
          <Search
            placeholder={t("common.search_member")}
            onChange={onChangeSearch}
            isActiveFitler={Boolean(
              selectedRoles.length || selectedGroups.length
            )}
            onClickFilter={showModalMembersFilter}
          />
        )}

        <div
          className={`flex sm:flex-1 sm:justify-between sm:flex-row-reverse ${
            labUsers.length ? "lg:mt-4 " : ""
          }`}
        >
          {isSelectionEnabled && selectedUsers.length ? (
            <div className="h-[48px] sm:h-[42px] rounded-lg bg-gray3 text-white flex">
              <div
                className="w-[48px] sm:w-[42px] flex justify-center items-center cursor-pointer"
                onClick={() => setSelectedUsers([])}
              >
                <CloseIcon size={iconSize} />
              </div>

              <div className="px-6 sm:px-4 flex items-center border-x border-gray4">
                <span className="text-base sm:text-sm font-semibold">
                  {selectedUsers.length} {t("common.selected")}
                </span>
              </div>

              <DropdownMenuMember
                labId={labId}
                members={selectedUsers}
                hideModalCB={hideModalCB}
              >
                <div className="w-[48px] sm:w-[42px] flex justify-center items-center cursor-pointer">
                  <ThreeDotsVerticalIcon size={iconSize} />
                </div>
              </DropdownMenuMember>
            </div>
          ) : null}

          {permissions.members && (
            <div className="relative sm:-ml-4">
              <Button IconBefore={PlusIcon} onClick={showModalAddMember}>
                {t("common.add_member")}
              </Button>

              {labJoinRequestList.length ? <Badge /> : null}
            </div>
          )}
        </div>
      </div>

      {isLoading ? <Loading /> : labUsers.length ? labUsersComponent : null}

      {isModalAddMember && labId ? (
        <ModalAddMember labId={labId} hideModal={hideModalAddMember} />
      ) : null}

      {isModalMembersFilter && (
        <ModalMembersFilter
          labUsers={labUsers}
          selectedRoles={selectedRoles}
          selectedGroups={selectedGroups}
          setSelectedRoles={setSelectedRoles}
          setSelectedGroups={setSelectedGroups}
          hideModal={hideModalMembersFilter}
        />
      )}

      {isModalGroups && selectedUsers.length === 1 && (
        <ModalGroups user={selectedUsers[0]} hideModal={hideModalGroups} />
      )}
    </>
  );
};
