import React from "react";

import { IconProps } from "./types";

export const QRCodeIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 25 25" fill="none">
    <path
      d="M5.5 4.5C4.94772 4.5 4.5 4.94772 4.5 5.5V8.5C4.5 9.05228 4.05228 9.5 3.5 9.5C2.94772 9.5 2.5 9.05228 2.5 8.5V5.5C2.5 3.84315 3.84315 2.5 5.5 2.5H8.5C9.05228 2.5 9.5 2.94772 9.5 3.5C9.5 4.05228 9.05228 4.5 8.5 4.5H5.5Z"
      fill="currentColor"
    />
    <path
      d="M9.31818 9.31818H10.2273V10.2273H9.31818V9.31818Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 7.5C7.94772 7.5 7.5 7.94771 7.5 8.5V11.0455C7.5 11.5977 7.94771 12.0455 8.5 12.0455H11.0455C11.5977 12.0455 12.0455 11.5977 12.0455 11.0455V8.5C12.0455 7.94772 11.5977 7.5 11.0455 7.5H8.5ZM11.0455 8.5H8.5L8.5 11.0455L11.0455 11.0455L11.0455 8.5Z"
      fill="currentColor"
    />
    <path
      d="M9.31818 14.7727H10.2273V15.6818H9.31818V14.7727Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 12.9545C7.94772 12.9545 7.5 13.4023 7.5 13.9545V16.5C7.5 17.0523 7.94771 17.5 8.5 17.5H11.0455C11.5977 17.5 12.0455 17.0523 12.0455 16.5V13.9545C12.0455 13.4023 11.5977 12.9545 11.0455 12.9545H8.5ZM11.0455 13.9545H8.5L8.5 16.5H11.0455L11.0455 13.9545Z"
      fill="currentColor"
    />
    <path
      d="M15.6818 9.31818H14.7727V10.2273H15.6818V9.31818Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9545 8.5C12.9545 7.94771 13.4023 7.5 13.9545 7.5H16.5C17.0523 7.5 17.5 7.94772 17.5 8.5V11.0455C17.5 11.5977 17.0523 12.0455 16.5 12.0455H13.9545C13.4023 12.0455 12.9545 11.5977 12.9545 11.0455V8.5ZM13.9545 8.5H16.5V11.0455L13.9545 11.0455L13.9545 8.5Z"
      fill="currentColor"
    />
    <path
      d="M13.8636 12.9545H12.9545V13.8636H13.8636V12.9545Z"
      fill="currentColor"
    />
    <path
      d="M14.7727 14.7727H15.6818V15.6818H14.7727V14.7727Z"
      fill="currentColor"
    />
    <path
      d="M13.8636 16.5909H12.9545V17.5H13.8636V16.5909Z"
      fill="currentColor"
    />
    <path d="M16.5909 16.5909H17.5V17.5H16.5909V16.5909Z" fill="currentColor" />
    <path d="M17.5 12.9545H16.5909V13.8636H17.5V12.9545Z" fill="currentColor" />
    <path
      d="M4.5 19.5C4.5 20.0523 4.94772 20.5 5.5 20.5H8.5C9.05228 20.5 9.5 20.9477 9.5 21.5C9.5 22.0523 9.05228 22.5 8.5 22.5H5.5C3.84315 22.5 2.5 21.1569 2.5 19.5V16.5C2.5 15.9477 2.94772 15.5 3.5 15.5C4.05228 15.5 4.5 15.9477 4.5 16.5V19.5Z"
      fill="currentColor"
    />
    <path
      d="M19.5 4.5C20.0523 4.5 20.5 4.94772 20.5 5.5V8.5C20.5 9.05228 20.9477 9.5 21.5 9.5C22.0523 9.5 22.5 9.05228 22.5 8.5V5.5C22.5 3.84315 21.1569 2.5 19.5 2.5H16.5C15.9477 2.5 15.5 2.94772 15.5 3.5C15.5 4.05228 15.9477 4.5 16.5 4.5H19.5Z"
      fill="currentColor"
    />
    <path
      d="M20.5 19.5C20.5 20.0523 20.0523 20.5 19.5 20.5H16.5C15.9477 20.5 15.5 20.9477 15.5 21.5C15.5 22.0523 15.9477 22.5 16.5 22.5H19.5C21.1569 22.5 22.5 21.1569 22.5 19.5V16.5C22.5 15.9477 22.0523 15.5 21.5 15.5C20.9477 15.5 20.5 15.9477 20.5 16.5V19.5Z"
      fill="currentColor"
    />
  </svg>
);
