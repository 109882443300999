import React from "react";

import { IconProps } from "./types";

export const UsersIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size / 2} viewBox="0 0 28 14" fill="none">
    <path
      d="M8.66901 9.01776C7.43416 10.0904 6.73813 11.4608 6.73813 12.6905C6.73813 13.0518 6.82714 13.3889 6.99764 13.6786H1.30751C0.364023 13.6786 0 13.3072 0 12.6385C0 10.5807 2.09499 8.10685 5.44548 8.10685C6.71626 8.10685 7.80642 8.46273 8.66901 9.01776ZM8.03076 4.11C8.03076 5.7221 6.83472 6.97017 5.44548 6.97017C4.06368 6.97017 2.86018 5.7221 2.86018 4.12486C2.86018 2.54248 4.07111 1.33154 5.44548 1.33154C6.82728 1.33154 8.03076 2.51276 8.03076 4.11Z"
      fill="currentColor"
    />
    <path
      d="M28 12.6385C28 13.3072 27.636 13.6786 26.6926 13.6786H21.0114C21.1811 13.3889 21.2694 13.0518 21.2694 12.6905C21.2694 11.4599 20.5741 10.0883 19.3386 9.0154C20.2005 8.46174 21.2901 8.10685 22.562 8.10685C25.9125 8.10685 28 10.5807 28 12.6385ZM25.1473 4.11C25.1473 5.7221 23.9513 6.97017 22.562 6.97017C21.1802 6.97017 19.9693 5.7221 19.9693 4.12486C19.9693 2.54248 21.1802 1.33154 22.562 1.33154C23.9438 1.33154 25.1473 2.51276 25.1473 4.11Z"
      fill="currentColor"
    />
    <path
      d="M14.0111 6.79941C15.6009 6.79941 16.9827 5.37304 16.9827 3.51578C16.9827 1.6808 15.6009 0.321289 14.0111 0.321289C12.4213 0.321289 11.0395 1.71053 11.0395 3.53063C11.0395 5.37304 12.4139 6.79941 14.0111 6.79941ZM9.01883 13.6787H18.9885C19.7835 13.6787 20.259 13.3073 20.259 12.6906C20.259 10.7739 17.8594 8.12924 14.0037 8.12924C10.1554 8.12924 7.75586 10.7739 7.75586 12.6906C7.75586 13.3073 8.23132 13.6787 9.01883 13.6787Z"
      fill="currentColor"
    />
  </svg>
);
