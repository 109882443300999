import React from "react";

import { IconProps } from "./types";

export const SelectIcon: React.FC<IconProps> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      d="M8.96824 2.27813C8.52322 1.90729 7.87681 1.90729 7.43179 2.27813L4.2159 4.95804C3.96134 5.17018 3.92694 5.54852 4.13908 5.80309C4.35122 6.05765 4.72956 6.09205 4.98413 5.87991L8.20002 3.2L11.4159 5.87991C11.6705 6.09205 12.0488 6.05765 12.2609 5.80309C12.4731 5.54852 12.4387 5.17018 12.1841 4.95804L8.96824 2.27813Z"
      fill="currentColor"
    />
    <path
      d="M12.1841 11.042L8.96824 13.7219C8.52322 14.0927 7.87681 14.0927 7.43179 13.7219L4.2159 11.042C3.96134 10.8298 3.92694 10.4515 4.13908 10.1969C4.35122 9.94235 4.72956 9.90795 4.98413 10.1201L8.20002 12.8L11.4159 10.1201C11.6705 9.90795 12.0488 9.94235 12.2609 10.1969C12.4731 10.4515 12.4387 10.8298 12.1841 11.042Z"
      fill="currentColor"
    />
  </svg>
);
