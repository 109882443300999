import React, { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useAuthProvider } from "provider";

import { ROUTES } from "constants/routes";
import { NavBar } from "components/NavBar";

export const RootLayout: React.FC = () => {
  const { user } = useAuthProvider();

  return useMemo(() => {
    if (user === undefined) return null;
    else if (user === null) return <Navigate replace to={ROUTES.LOGIN} />;

    return (
      <div className="flex h-screen sm:flex-col">
        <NavBar />

        <div className="flex flex-1 flex-col bg-black overflow-hidden">
          <Outlet />
        </div>
      </div>
    );
  }, [user]);
};
