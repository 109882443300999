import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

interface InputProps {
  name?: string;
  placeholder?: string;
  label?: string;
  className?: string;
  type?: React.HTMLInputTypeAttribute;
  value?: string | undefined;
  defaultValue?: string | null;
  disabled?: boolean;
  required?: boolean;
  buttonOneLine?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

export const Input: React.FC<InputProps> = ({
  name,
  placeholder,
  label,
  className,
  type,
  value,
  defaultValue,
  disabled,
  required = true,
  buttonOneLine,
  onChange,
}) => (
  <>
    {label ? (
      <span className="block mt-3 mb-2 text-sm font-normal text-gray6">
        {label}
      </span>
    ) : null}

    {name === "phone" ? (
      <PhoneInput
        name={name}
        value={defaultValue || undefined}
        className="!border-0"
        inputClassName="!bg-gray3 !border-0 !h-[40px] !rounded-r-lg !flex-1 !text-white !text-sm !font-normal !px-4"
        countrySelectorStyleProps={{
          buttonClassName:
            "!bg-gray3 !border-0 !h-[40px] !px-4 !rounded-l-lg !border-r !border-gray4",
          flagClassName: "!w-[20px] !m-0",
          dropdownArrowClassName: "!m-0 !ml-3",
          dropdownStyleProps: {
            className: "!bg-gray3 !rounded-lg !p-0",
            listItemClassName: "!h-[40px] !px-4",
            listItemFlagClassName: "!w-[20px] !mr-3",
            listItemCountryNameClassName:
              "!flex-1 !text-sm !font-normal text-white",
          },
        }}
      />
    ) : (
      <input
        disabled={disabled}
        required={required}
        name={name}
        type={type}
        autoComplete={type}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue || undefined}
        className={`${
          className
            ? className
            : "rounded-lg border-2 border-transparent focus:border-white"
        } ${
          buttonOneLine ? "h-[48px] sm:h-[42px]" : "h-[40px]"
        } w-full bg-gray3 text-white text-sm font-normal px-4 placeholder-gray6 disabled:opacity-50 rounded-lg`}
        onChange={onChange}
      />
    )}
  </>
);
