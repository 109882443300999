import { serverInstance as api } from "api/instances";
import { Id, UserJoinRequest, LabJoinRequest } from "types";

const URL = "lab/join";

export const joinLab = (realm: string) => api.get(`${URL}/request/${realm}`);

export const acceptJoinRequest = (joinId: Id) =>
  api.get(`${URL}/accept/${joinId}`);

export const declineJoinRequest = (joinId: Id) =>
  api.get(`${URL}/decline/${joinId}`);

export const deleteJoinRequest = (joinId: Id) => api.delete(`${URL}/${joinId}`);

export const getUserJoinRequestList = () =>
  api.get<{ data: UserJoinRequest[] }>(URL);

export const getLabJoinRequestList = (labId: Id) =>
  api.get<{ data: LabJoinRequest[] }>(`${URL}/${labId}`);
