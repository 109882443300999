import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useAuthProvider } from "provider";
import { ROUTES } from "constants/routes";
import { SER } from "utils/serverErrorHandler";
import { getProfilePicture } from "utils/getProfilePicture";
import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { DropdownMenuLab } from "components/DropdownMenu/DropdownMenuLab";
import { Header } from "components/Header";
import { Image } from "components/Image";
import { HorizontalMenu } from "components/HorizontalMenu";
import { Permissions, LabJoinRequest, Lab } from "types";

const defaultPermissions: Permissions = {
  laboratory: false,
  members: false,
  groups: false,
  licenses: false,
  connections: false,
  devices: false,
};

const contentSize = getCurrentScreenData({
  sm: { image: 100, icon: 30 },
  md: { image: 120, icon: 40 },
  all: { image: 136, icon: 50 },
});

export const LabLayout: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useAuthProvider();

  const [lab, setLab] = useState<Lab | undefined>(location.state);
  const [permissions, setPermissions] =
    useState<Permissions>(defaultPermissions);
  const [labJoinRequestList, setLabJoinRequestList] = useState<
    LabJoinRequest[]
  >([]);

  const labId = useMemo(() => Number(params.labId), [params]);

  const toLaboratories = useCallback(() => {
    navigate(ROUTES.LABORATORIES);
  }, [navigate]);

  const getData = useCallback(async () => {
    SER(async () => {
      if (user) {
        const [{ data: lab }, { data: userRole }] = await Promise.all([
          serverApi.getLab(labId),
          serverApi.getUserRole(labId, user.id),
        ]);

        setLab(lab.data);

        setPermissions({
          laboratory: userRole.data.r_index === 0, // only owner
          members: userRole.data.r_index <= 1, // owner or admin
          groups: userRole.data.r_index <= 1, // owner or admin
          connections: userRole.data.r_index <= 1, // owner or admin
          devices: userRole.data.r_index <= 2, // owner or admin or moderator
          licenses: userRole.data.r_index <= 3, // owner or admin or moderator or license manager
        });
      }
    }, toLaboratories);
  }, [labId, user, toLaboratories]);

  const getLabJoinRequestList = useCallback(() => {
    if (permissions.members) {
      SER(async () => {
        const { data: labJoinRequestList } =
          await serverApi.getLabJoinRequestList(labId);

        setLabJoinRequestList(labJoinRequestList.data);
      });
    }
  }, [permissions, labId]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    getLabJoinRequestList();
  }, [getLabJoinRequestList]);

  const menuItems = useMemo(
    () => [
      {
        title: t("common.members"),
        route: ROUTES.LABORATORY_MEMBERS,
        isBadge: Boolean(labJoinRequestList.length),
      },
      { title: t("common.groups"), route: ROUTES.LABORATORY_GROUPS },
      { title: t("common.licenses"), route: ROUTES.LABORATORY_LICENSES },
      {
        title: t("common.data_connections"),
        route: ROUTES.LABORATORY_DATA_CONNECTIONS,
      },
      {
        title: t("common.devices"),
        route: ROUTES.LABORATORY_DEVICES,
      },
    ],
    [t, labJoinRequestList]
  );

  return (
    <>
      {Boolean(user?.multilab) && (
        <Header
          title={t("common.laboratories")}
          backRoute={ROUTES.LABORATORIES}
        />
      )}

      {lab ? (
        <section className="p-8 flex items-center md:p-6 sm:p-4">
          <div className="border border-[rgba(255,255,255,.1)] rounded-lg">
            <Image
              key={lab.updated_at}
              rounded="lg"
              size={contentSize.image}
              iconSize={contentSize.icon}
              src={getProfilePicture(lab)}
            />
          </div>

          <div className="min-w-[184px] ml-6 mr-10 md:ml-6 sm:ml-4 sm:min-w-[150px]">
            <h1 className="text-white text-2xl font-bold md:text-xl">
              {lab.name}
            </h1>
            <span className="text-gray6 text-lg font-normal md:text-base">
              {lab.realm}
            </span>
          </div>

          <DropdownMenuLab
            lab={lab}
            permissions={permissions}
            manageLabCB={getData}
          />
        </section>
      ) : null}

      <HorizontalMenu menuItems={menuItems} />

      <section className="px-8 py-6 md:px-6 sm:px-4 sm:py-4 overflow-y-scroll flex flex-col flex-1">
        <Outlet
          context={{ permissions, labJoinRequestList, getLabJoinRequestList }}
        />
      </section>
    </>
  );
};
