import { serverInstance as api } from "api/instances";
import { Contact, Id } from "types";

const URL = "transfer/contacts";

export const getContacts = (labId: Id) =>
  api.get<{ data: Contact[] }>(`${URL}/${labId}`);

export const getContactsPending = (labId: Id) =>
  api.get<{ data: Contact[] }>(`${URL}/pending/${labId}`);

export const acceptContactPending = (contactId: Id) =>
  api.get(`${URL}/accept/${contactId}`);

export const declineContactPending = (contactId: Id) =>
  api.get(`${URL}/deny/${contactId}`);

export const getContactsOutgoing = (labId: Id) =>
  api.get<{ data: Contact[] }>(`${URL}/outgoing/${labId}`);

export const requestContactOutgoing = (labId: Id, name: string) =>
  api.get(`${URL}/request/${labId}/${name}`);

export const deleteContactOutgoing = (contactId: Id) =>
  api.delete(`${URL}/remove/request/${contactId}`);

export const deleteContact = (contactId: Id) =>
  api.delete(`${URL}/remove/${contactId}`);
