import React from "react";

import { IconProps } from "./types";

export const EditIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 25 25" fill="none">
    <path
      d="M20.7105 4.44839C19.5671 3.30496 17.7132 3.30496 16.5698 4.44839L6.96206 14.0561L11.1028 18.1968L20.7105 8.58913C21.8539 7.4457 21.8539 5.59183 20.7105 4.44839Z"
      fill="currentColor"
    />
    <path
      d="M9.53139 19.7682L5.39065 15.6275L5.04952 15.9686C4.90743 16.1107 4.80652 16.2887 4.75756 16.4836L3.57871 21.1759C3.51731 21.4203 3.73886 21.6419 3.98326 21.5805L8.67534 20.4013C8.8702 20.3524 9.04815 20.2515 9.19023 20.1094L9.53139 19.7682Z"
      fill="currentColor"
    />
  </svg>
);
