import React from "react";

import { IconProps } from "./types";

export const ShareIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M19 12.5C19 11.9478 19.4478 11.5 20 11.5C20.5522 11.5 21 11.9478 21 12.5V18C21 19.6543 19.6543 21 18 21H6C4.3457 21 3 19.6543 3 18V6C3 4.3457 4.3457 3 6 3H11.5C12.0522 3 12.5 3.44776 12.5 4C12.5 4.55224 12.0522 5 11.5 5H6C5.44873 5 5 5.44873 5 6V18C5 18.5513 5.44873 19 6 19H18C18.5513 19 19 18.5513 19 18V12.5Z"
      fill="currentColor"
    />
    <path
      d="M14.9999 3H18.9999C20.103 3 20.9999 3.89698 20.9999 5V9C20.9999 9.55224 20.5522 10 19.9999 10C19.4477 10 18.9999 9.55224 18.9999 9V6.41407L12.707 12.707C12.5117 12.9023 12.2558 13 11.9999 13C11.7441 13 11.4882 12.9023 11.2929 12.707C10.9023 12.3164 10.9023 11.6836 11.2929 11.293L17.5859 5H14.9999C14.4477 5 13.9999 4.55224 13.9999 4C13.9999 3.44776 14.4477 3 14.9999 3Z"
      fill="currentColor"
    />
  </svg>
);
