import React from "react";

import { IconProps } from "./types";

export const LicenseEmptyIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none">
    <path
      d="M27.5 33.3334C29.4891 33.3334 31.3968 32.5432 32.8033 31.1367C34.2098 29.7301 35 27.8225 35 25.8334C35 23.8442 34.2098 21.9366 32.8033 20.5301C31.3968 19.1235 29.4891 18.3334 27.5 18.3334C25.5109 18.3334 23.6032 19.1235 22.1967 20.5301C20.7902 21.9366 20 23.8442 20 25.8334C20 27.8225 20.7902 29.7301 22.1967 31.1367C23.6032 32.5432 25.5109 33.3334 27.5 33.3334ZM28.6771 25.8334L30.5885 27.7448C30.9115 28.0677 30.9115 28.599 30.5885 28.9219C30.2656 29.2448 29.7344 29.2448 29.4115 28.9219L27.5 27.0104L25.5885 28.9219C25.2656 29.2448 24.7344 29.2448 24.4115 28.9219C24.0885 28.599 24.0885 28.0677 24.4115 27.7448L26.3229 25.8334L24.4115 23.9219C24.0885 23.599 24.0885 23.0677 24.4115 22.7448C24.7344 22.4219 25.2656 22.4219 25.5885 22.7448L27.5 24.6563L29.4115 22.7448C29.7344 22.4219 30.2656 22.4219 30.5885 22.7448C30.9115 23.0677 30.9115 23.599 30.5885 23.9219L28.6771 25.8334Z"
      fill="currentColor"
    />
    <path
      d="M5 10C5 8.16148 6.49479 6.66669 8.33333 6.66669H16.6667V13.3334C16.6667 14.2552 17.4115 15 18.3333 15H25V17.0117C22.9338 17.5961 21.1642 18.8869 19.9685 20.6066C19.8691 20.2576 19.5465 20 19.1667 20H10.8333C10.375 20 10 20.375 10 20.8334C10 21.2917 10.375 21.6667 10.8333 21.6667H19.1667C19.2269 21.6667 19.2858 21.6602 19.3425 21.6479C19.0682 22.1814 18.8447 22.7454 18.6784 23.3334H10.8333C10.375 23.3334 10 23.7084 10 24.1667C10 24.625 10.375 25 10.8333 25H18.3707C18.346 25.2745 18.3333 25.5524 18.3333 25.8334C18.3333 26.1143 18.346 26.3922 18.3707 26.6667H10.8333C10.375 26.6667 10 27.0417 10 27.5C10 27.9584 10.375 28.3334 10.8333 28.3334H18.6784C19.2515 30.3596 20.5039 32.1006 22.174 33.2949C22.0085 33.3202 21.8391 33.3334 21.6667 33.3334H8.33333C6.49479 33.3334 5 31.8386 5 30V10Z"
      fill="currentColor"
    />
    <path d="M25 13.3334H18.3333V6.66669L25 13.3334Z" fill="currentColor" />
  </svg>
);
