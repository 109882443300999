import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { SER } from "utils/serverErrorHandler";
import { getImageDimension } from "utils/getImageDimension";
import { convertImageToBase64 } from "utils/convertImageToBase64";
import { getProfilePicture } from "utils/getProfilePicture";
import { isValidNumber } from "utils/isValidNumber";
import { ModalEditImage } from "components/Modal/ModalEditImage";
import { Modal } from "components/Modal/Modal";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { ButtonIcon } from "components/ButtonIcon";
import { Select } from "components/Select";
import { UsersIcon } from "icons/UsersIcon";
import { DashedCircle } from "icons/DashedCircle";
import { UploadIcon } from "icons/UploadIcon";
import { InfoIcon } from "icons/InfoIcon";
import { Lab } from "types";

const maxImageSize = 400 * 1000;

type FormEventValues = {
  name: { value: string };
  country: { value: string };
  city: { value: string };
  street: { value: string };
  street_number: { value: string };
  postcode: { value: string };
  primary_email: { value: string };
  backup_email: { value: string };
  phone: { value: string };
};

interface ModalManageLabProps {
  lab?: Lab;
  hideModal: (isUpdate?: boolean) => void;
}

export const ModalManageLab: React.FC<ModalManageLabProps> = ({
  lab,
  hideModal,
}) => {
  const [blobImage, setBlobImage] = useState("");
  const [defaultBlobImage, setDefaultBlobImage] = useState("");
  const [isModalEditImage, setIsModalEditImage] = useState(false);
  const [pageNumber, setPageNumber] = useState<1 | 2>(1);
  const [memberEmail, setMemberEmail] = useState("");
  const [members, setMembers] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState(
    lab?.type || "dental_technician"
  );

  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (lab?.profile_picture) {
      fetch(getProfilePicture(lab))
        .then((res) => res.blob())
        .then((file) => {
          setBlobImage(URL.createObjectURL(file));
          setDefaultBlobImage(blobImage);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lab]);

  const showModalEditImage = useCallback(() => {
    setIsModalEditImage(true);
  }, []);

  const hideModalEditImage = useCallback(
    (isResetBlobImage?: boolean) => {
      setIsModalEditImage(false);
      if (isResetBlobImage) setBlobImage(defaultBlobImage);
    },
    [defaultBlobImage]
  );

  const onSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (pageNumber === 1) {
        setPageNumber(2);
        return;
      }

      const target = event.target as typeof event.target & FormEventValues;

      const blob = await fetch(blobImage).then((res) => res.blob());
      const profile_picture = blobImage
        ? await convertImageToBase64(blob)
        : null;

      const data = {
        name: target.name.value,
        country: target.country.value,
        city: target.city.value,
        street: target.street.value,
        street_number: target.street_number.value,
        postcode: target.postcode.value,
        primary_email: target.primary_email.value,
        backup_email: target.backup_email.value,
        phone: target.phone.value,
        type: selectedType,
        ...(profile_picture ? { profile_picture } : {}),
        ...(lab ? { members } : {}),
      };

      if (isValidNumber(data.phone)) {
        SER(async () => {
          if (lab) {
            await serverApi.updateLab(lab.id, data);
            toast.success(t("message.lab_updated"));
          } else {
            await serverApi.createLab(data);
            toast.success(t("message.lab_added"));
          }

          hideModal(true);
        });
      } else {
        toast.error(t("message.phone_warning"));
      }
    },
    [t, blobImage, pageNumber, members, selectedType, lab, hideModal]
  );

  const onChangeImage = useCallback(
    async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const file = target.files?.[0];

      if (file) {
        const { size } = await getImageDimension(file);

        if (size > maxImageSize) {
          toast.error(t("message.image_warning"));
        } else {
          setBlobImage(URL.createObjectURL(file));
          showModalEditImage();
        }

        target.value = "";
      }
    },
    [t, showModalEditImage]
  );

  const onChangeMemberEmail: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target }) => {
      setMemberEmail(target.value);
    }, []);

  const addMember = useCallback(
    (email: string) => {
      if (!members.includes(email)) {
        setMembers([...members, email]);
      }
    },
    [members]
  );

  const removeMember = useCallback(
    (email: string) => {
      setMembers(members.filter((member) => member !== email));
    },
    [members]
  );

  const checkUser = useCallback(() => {
    SER(async () => {
      await serverApi.checkUser({ email: memberEmail });
      addMember(memberEmail);
      setMemberEmail("");
    });
  }, [memberEmail, addMember]);

  const typeOptions = useMemo(
    () => [
      { value: "dental_technician", label: t("common.dental_technician") },
      { value: "dentist", label: t("common.dentist") },
    ],
    [t]
  );

  const firstPageComponent = useMemo(
    () => (
      <div className={pageNumber === 2 ? "hidden" : ""}>
        <div className="pb-6 border-b border-gray3">
          <h2 className="mb-6 text-white font-bold text-base leading-5">
            {t("common.cover_picture")}
          </h2>

          <div className="flex items-center">
            {blobImage ? (
              <img
                src={blobImage}
                alt=""
                className="w-[160px] sm:w-[140px] aspect-square rounded-lg"
              />
            ) : (
              <div className="w-[160px] sm:w-[140px] aspect-square rounded-lg bg-gray3 text-gray6 flex items-center justify-center">
                <UsersIcon size={50} />
              </div>
            )}

            <label
              htmlFor="profile_picture"
              className="relative cursor-pointer ml-4 btn-hover-after after:rounded-lg"
            >
              <Button
                isLabelFor
                type="transparentBorder"
                IconBefore={UploadIcon}
              >
                {t("common.upload_image")}
              </Button>
            </label>

            <input
              id="profile_picture"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              onChange={onChangeImage}
              className="hidden"
            />
          </div>
        </div>

        <h2 className="mt-8 mb-5 text-white font-bold text-base leading-5">
          {t("common.name")} & {t("common.type")}
        </h2>

        <Input name="name" label={t("common.name")} defaultValue={lab?.name} />

        <span className="block mt-3 mb-2 text-sm font-normal text-gray6">
          {t("common.type")}
        </span>

        <Select
          options={typeOptions}
          value={selectedType}
          setValue={setSelectedType}
          disabled={Boolean(lab)}
        />

        {lab ? (
          <div className="mt-4 flex text-gray6 items-center">
            <InfoIcon />

            <span className="ml-2 font-normal text-sm">
              {t("common.change_lab_type_info")}
            </span>
          </div>
        ) : null}

        <h2 className="mt-8 mb-5 text-white font-bold text-base leading-5">
          {t("common.address")}
        </h2>

        <Input
          name="country"
          label={t("common.country")}
          defaultValue={lab?.country}
        />

        <div className="flex">
          <div className="flex-1">
            <Input
              name="city"
              label={t("common.city")}
              defaultValue={lab?.city}
            />
          </div>
          <div className="ml-4">
            <Input
              name="postcode"
              label={t("common.postcode")}
              defaultValue={lab?.postcode}
            />
          </div>
        </div>

        <div className="flex">
          <div className="flex-1">
            <Input
              name="street"
              label={t("common.street")}
              defaultValue={lab?.street}
            />
          </div>

          <div className="ml-4">
            <Input
              name="street_number"
              label={t("common.number")}
              defaultValue={lab?.street_number}
            />
          </div>
        </div>
      </div>
    ),
    [
      t,
      blobImage,
      lab,
      pageNumber,
      typeOptions,
      selectedType,
      setSelectedType,
      onChangeImage,
    ]
  );

  const secondPageComponent = useMemo(
    () => (
      <div className={pageNumber === 1 ? "hidden" : ""}>
        <h2 className="mb-5 text-white font-bold text-base leading-5">
          {t("common.phone_number")} & {t("common.emails")}
        </h2>

        <Input
          name="phone"
          label={t("common.phone_number")}
          defaultValue={lab?.phone}
          required={pageNumber === 2}
        />
        <Input
          type="email"
          name="primary_email"
          label={t("common.primary_email")}
          defaultValue={lab?.primary_email}
          required={pageNumber === 2}
        />
        <Input
          type="email"
          name="backup_email"
          label={t("common.backup_email_optional")}
          defaultValue={lab?.backup_email}
          required={false}
        />

        {lab ? null : (
          <>
            <h2 className="mt-8 mb-5 text-white font-bold text-base leading-5">
              {t("common.members")}
            </h2>

            <div className="flex">
              <Input
                buttonOneLine
                value={memberEmail}
                placeholder={t("common.type_email")}
                required={false}
                onChange={onChangeMemberEmail}
              />
              <Button isButton onClick={checkUser}>
                {t("common.invite")}
              </Button>
            </div>

            {members.map((member) => (
              <div
                key={member}
                className="mt-6 flex items-center justify-between"
              >
                <div className="flex items-center text-gray4">
                  <DashedCircle />

                  <span className="ml-4 text-base text-gray6 font-normal">
                    {member}
                  </span>
                </div>

                <ButtonIcon size="sm" onClick={() => removeMember(member)} />
              </div>
            ))}
          </>
        )}
      </div>
    ),
    [
      t,
      lab,
      pageNumber,
      members,
      memberEmail,
      onChangeMemberEmail,
      checkUser,
      removeMember,
    ]
  );

  const footerComponent = useMemo(
    () => (
      <div className="flex flex-1 justify-between items-center">
        <div className="flex flex-1">
          {pageNumber === 2 && (
            <Button type="transparentBorder" onClick={() => setPageNumber(1)}>
              {t("common.back")}
            </Button>
          )}
        </div>

        <span className="text-base text-gray6 font-normal">{pageNumber}/2</span>

        <div className="flex flex-1 justify-end">
          {pageNumber === 1 ? (
            <Button
              type="grayNoOffset"
              onClick={() => formRef.current?.requestSubmit()}
            >
              {t("common.next")}
            </Button>
          ) : (
            <Button onClick={() => formRef.current?.requestSubmit()}>
              {lab ? t("common.update") : t("common.create")}
            </Button>
          )}
        </div>
      </div>
    ),
    [lab, pageNumber, t]
  );

  return (
    <>
      <Modal
        title={
          lab ? t("common.edit_laboratory") : t("common.create_laboratory")
        }
        footer={footerComponent}
        hideModal={hideModal}
      >
        <form
          ref={formRef}
          onSubmit={onSubmit}
          className="px-8 py-6 sm:px-6 sm:py-4"
        >
          {firstPageComponent}
          {secondPageComponent}
        </form>
      </Modal>

      {isModalEditImage ? (
        <ModalEditImage
          blobImage={blobImage}
          setBlobImage={setBlobImage}
          hideModal={hideModalEditImage}
        />
      ) : null}
    </>
  );
};
