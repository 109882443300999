import React from "react";

import { IconProps } from "./types";

export const ThreeDotsHorizontalIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <rect
      x="0.5"
      y="0.5"
      width="31"
      height="31"
      rx="7.5"
      stroke="currentColor"
      strokeOpacity="0.2"
    />
    <path
      d="M11.4 14.8C12.0627 14.8 12.6 15.3373 12.6 16C12.6 16.6628 12.0627 17.2 11.4 17.2C10.7372 17.2 10.2 16.6628 10.2 16C10.2 15.3373 10.7372 14.8 11.4 14.8Z"
      fill="currentColor"
    />
    <path
      d="M16.2 14.8C16.8627 14.8 17.4 15.3373 17.4 16C17.4 16.6628 16.8627 17.2 16.2 17.2C15.5372 17.2 15 16.6628 15 16C15 15.3373 15.5372 14.8 16.2 14.8Z"
      fill="currentColor"
    />
    <path
      d="M22.2 16C22.2 15.3373 21.6627 14.8 21 14.8C20.3372 14.8 19.8 15.3373 19.8 16C19.8 16.6628 20.3372 17.2 21 17.2C21.6627 17.2 22.2 16.6628 22.2 16Z"
      fill="currentColor"
    />
  </svg>
);
