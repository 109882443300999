import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useTypedOutletContext } from "hooks/useTypedOutletContext";
import { SER } from "utils/serverErrorHandler";
import { getTimeDiff } from "utils/getTimeDiff";
import { getDeviceType } from "utils/getDeviceType";
import { DropdownMenuDevice } from "components/DropdownMenu/DropdownMenuDevice";
import { ModalAddDevice } from "components/Modal/ModalAddDevice";
import { Button } from "components/Button";
import { Image } from "components/Image";
import { Search } from "components/Search";
import { Empty } from "components/Empty";
import { Loading } from "components/Loading";
import { DeviceEmptyIcon } from "icons/DeviceEmptyIcon";
import { PlusIcon } from "icons/PlusIcon";
import { Device } from "types";

export const LaboratoryDevices: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const [devices, setDevices] = useState<Device[]>([]);
  const [isModalAddDevice, setIsModalAddDevice] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  const { t } = useTranslation();

  const { permissions } = useTypedOutletContext();

  const labId = useMemo(() => Number(params.labId), [params]);

  const getDevices = useCallback(() => {
    SER(async () => {
      setIsLoading(true);

      const { data: machines } = await serverApi.getLabMachineList(labId);
      const { data: devices } = await serverApi.getDevices(
        machines.data.map(({ uuid }) => uuid)
      );

      setDevices(devices);

      setIsLoading(false);
    });
  }, [labId]);

  useEffect(() => {
    getDevices();
  }, [getDevices]);

  const showModalAddDevice = useCallback(() => {
    setIsModalAddDevice(true);
  }, []);

  const hideModalAddDevice = useCallback(() => {
    setIsModalAddDevice(false);
    getDevices();
  }, [getDevices]);

  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target }) => {
      setSearchValue(target.value);
    }, []);

  const filteredDevices = useMemo(
    () =>
      devices.filter((device) =>
        device.unitName.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [devices, searchValue]
  );

  const devicesComponent = useMemo(
    () => (
      <>
        <div className="mt-6 sm:mt-4 pr-16 flex min-h-[48px] border-b border-gray3 items-center">
          <div className="min-w-[72px]" />

          <div className="w-3/12 px-4">
            <span className="text-gray6 text-sm font-normal">
              {t("common.name")}
            </span>
          </div>
          <div className="w-3/12 px-4">
            <span className="text-gray6 text-sm font-normal">
              {t("common.typology")}
            </span>
          </div>
          <div className="w-6/12 px-4">
            <span className="text-gray6 text-sm font-normal">
              {t("common.latest_update")}
            </span>
          </div>
        </div>

        {filteredDevices.map((device) => (
          <div
            key={device.unitKey}
            className="group flex py-3 pr-8 sm:pr-6 items-center justify-between transition-all duration-200 ease-in-out hover:bg-gray1"
          >
            <div className="flex flex-1 items-center">
              <div className="min-w-[72px] px-4">
                <Image
                  rounded="lg"
                  size={40}
                  iconSize={20}
                  src={device.imageUrl}
                />
              </div>

              <div className="w-3/12 px-4 flex flex-col">
                <span className="text-base font-semibold text-white leading-5">
                  {device.unitName}
                </span>
                <span className="text-base font-normal text-gray6 leading-5">
                  {device.unitKey}
                </span>
              </div>
              <div className="w-3/12 px-4">
                <span className="text-white text-base font-normal capitalize">
                  {getDeviceType(device, t)}
                </span>
              </div>
              <div className="w-6/12 px-4">
                <span className="text-white text-base font-normal">
                  {getTimeDiff(t, device.updatedAt)}
                </span>
              </div>
            </div>

            <DropdownMenuDevice
              labId={labId}
              device={device}
              removeDeviceCB={getDevices}
            />
          </div>
        ))}
      </>
    ),
    [t, filteredDevices, labId, getDevices]
  );

  return (
    <>
      <div className="flex justify-between sm:flex-wrap sm:justify-end">
        {devices.length ? (
          <Search
            placeholder={t("common.search_device")}
            onChange={onChangeSearch}
          />
        ) : (
          <div className="flex-1" />
        )}

        {permissions.devices && (
          <div className={devices.length ? "sm:mt-4" : ""}>
            <Button IconBefore={PlusIcon} onClick={showModalAddDevice}>
              {t("common.add_device")}
            </Button>
          </div>
        )}
      </div>

      {isLoading ? (
        <Loading />
      ) : devices.length ? (
        devicesComponent
      ) : (
        <Empty
          title={t("message.no_added_devices.0")}
          subtitle={t("message.no_added_devices.1")}
          Icon={DeviceEmptyIcon}
        />
      )}

      {isModalAddDevice && (
        <ModalAddDevice labId={labId} hideModal={hideModalAddDevice} />
      )}
    </>
  );
};
