import React from "react";

import { CloseIcon } from "icons/CloseIcon";
import { IconProps } from "icons/types";

const sizes = {
  md: {
    width: 48,
    iconSize: 24,
  },
  sm: {
    width: 32,
    iconSize: 18,
  },
};

interface ButtonIconProps {
  size: keyof typeof sizes;
  Icon?: React.FC<IconProps>;
  onClick: () => void;
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  size,
  Icon = CloseIcon,
  onClick,
}) => (
  <div
    className="flex aspect-square justify-center items-center rounded-lg cursor-pointer text-white transition-all duration-200 ease-in-out hover:bg-[rgba(255,255,255,.1)]"
    style={{ width: sizes[size].width }}
    onClick={() => onClick()}
  >
    <Icon size={sizes[size].iconSize} />
  </div>
);
