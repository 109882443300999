import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { ROUTES } from "constants/routes";
import { AuthLayout, DeviceLayout, LabLayout, RootLayout } from "layouts";
import {
  Login,
  Invitation,
  Laboratories,
  Devices,
  LaboratoryMembers,
  Settings,
  LaboratoryGroups,
  LaboratoryLicenses,
  LaboratoryDataConnections,
  DeviceProcesses,
  DeviceMaintenance,
  DeviceHistory,
  DeviceProcess,
  LaboratoryDevices,
  DeviceMessages,
} from "pages";
import { AuthProvider } from "provider";
import { Toast } from "components/Toast";
import "locales/i18n";

export const App: React.FC = () => (
  <AuthProvider>
    <BrowserRouter>
      <Routes>
        {/* auth routes */}
        <Route element={<AuthLayout />}>
          <Route path={ROUTES.LOGIN} Component={Login} />
          <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
        </Route>

        {/* root routes */}
        <Route element={<RootLayout />}>
          {/* lab routes */}
          <Route path={ROUTES.LABORATORIES} Component={Laboratories} />
          <Route element={<LabLayout />}>
            <Route
              path={ROUTES.LABORATORY_MEMBERS}
              Component={LaboratoryMembers}
            />
            <Route
              path={ROUTES.LABORATORY_GROUPS}
              Component={LaboratoryGroups}
            />
            <Route
              path={ROUTES.LABORATORY_LICENSES}
              Component={LaboratoryLicenses}
            />
            <Route
              path={ROUTES.LABORATORY_DATA_CONNECTIONS}
              Component={LaboratoryDataConnections}
            />
            <Route
              path={ROUTES.LABORATORY_DEVICES}
              Component={LaboratoryDevices}
            />
          </Route>

          {/* device routes */}
          <Route path={ROUTES.DEVICES} Component={Devices} />
          <Route element={<DeviceLayout />}>
            <Route path={ROUTES.DEVICE_PROCESSES} Component={DeviceProcesses} />
            <Route path={ROUTES.DEVICE_PROCESS} Component={DeviceProcess} />
            <Route path={ROUTES.DEVICE_HISTORY} Component={DeviceHistory} />
            <Route
              path={ROUTES.DEVICE_MAINTENANCE}
              Component={DeviceMaintenance}
            />
            <Route path={ROUTES.DEVICE_MESSAGES} Component={DeviceMessages} />
          </Route>

          <Route path={ROUTES.SETTINGS} Component={Settings} />
          <Route path={ROUTES.INVITATION} Component={Invitation} />
          <Route path="*" element={<Navigate to={ROUTES.LABORATORIES} />} />
        </Route>
      </Routes>
    </BrowserRouter>

    <Toast />
  </AuthProvider>
);
