import { serverApi } from "api";

export const getDevicesMessagesUnreadCount = async (unitKeys: string[]) => {
  const definedUnitKeys = unitKeys.filter((value) => value !== "");

  if (definedUnitKeys.length) {
    try {
      const [{ data: devices }, { data: messagesUnreadCount }] =
        await Promise.all([
          serverApi.getDevices(definedUnitKeys),
          serverApi.getMessagesUnreadCount(definedUnitKeys),
        ]);

      return devices.map((device) => ({
        ...device,
        messagesUnreadCount: messagesUnreadCount[device.unitKey],
      }));
    } catch (err) {
      return [];
    }
  }

  return [];
};
