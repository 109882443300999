import React from "react";

import { DropdownIcon } from "icons/DropdownIcon";
import { Chart } from "components/Chart";
import { CircleProgressBar } from "components/CircleProgressBar";
import { Loading } from "components/Loading";
import { formatDate } from "utils/formatDate";
import { Baking, BakingDetails } from "types";

interface ItemFurnaceProcessProps {
  baking: Baking | BakingDetails;
  isActive: boolean;
  toggleActiveBaking?: (id: string) => void;
}

const isBakingDetails = (
  baking: Baking | BakingDetails
): baking is BakingDetails => (baking as BakingDetails).status !== undefined;

export const ItemFurnaceProcess: React.FC<ItemFurnaceProcessProps> = ({
  baking,
  isActive,
  toggleActiveBaking,
}) => (
  <div className="bg-gray1 rounded-lg">
    <div
      className={`px-8 xl:px-4 h-[96px] xl:h-[78px] flex rounded-lg items-center justify-between ${
        isActive ? "bg-blue rounded-b-none" : ""
      }`}
    >
      <span className="text-xl md:text-lg sm:text-base font-bold text-white whitespace-nowrap text-ellipsis overflow-hidden">
        {baking.name}
      </span>

      <div className="ml-6 flex items-center text-white">
        {isBakingDetails(baking) && baking.progress ? (
          <div className="flex items-center">
            <span className="text-white text-xl md:text-lg sm:text-base font-bold mr-2">
              {baking.progress}%
            </span>
            <CircleProgressBar size={60} strokeWidth={4} progress={40} />
          </div>
        ) : (
          <span className="text-xl md:text-lg sm:text-base font-bold">
            {formatDate.toLocaleDateString(baking.date)}
          </span>
        )}

        {toggleActiveBaking ? (
          <div
            className={`ml-10 md:ml-6 sm:ml-4 cursor-pointer ${
              isActive ? "rotate-180" : ""
            }`}
            onClick={() => toggleActiveBaking(baking.id)}
          >
            <DropdownIcon />
          </div>
        ) : null}
      </div>
    </div>

    {isActive ? (
      <div className="p-8 xl:p-4 xl:w-[calc(100vw-505px)] lg:w-[calc(100vw-128px)] md:w-[calc(100vw-112px)] sm:w-[calc(100vw-32px)] flex items-center justify-center">
        {isBakingDetails(baking) && baking.temperatureCurve ? (
          <Chart temperatureCurve={baking.temperatureCurve} />
        ) : (
          <Loading />
        )}
      </div>
    ) : null}
  </div>
);
