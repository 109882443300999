import { serverInstance as api } from "api/instances";

type Machine = {
  id: number;
  lab: number;
  uuid: string;
  created: string;
};

const USER_URL = "user/machine";
const LAB_URL = "lab/machine";

// USER MACHINES

export const getUserMachineList = () => api.get<{ data: Machine[] }>(USER_URL);

export const addUserMachine = (unitKey: string) =>
  api.post(`${USER_URL}/${unitKey}`);

export const removeUserMachine = (unitKey: string) =>
  api.delete(`${USER_URL}/${unitKey}`);

// LAB MACHINES

export const getLabMachineList = (labId: number) =>
  api.get<{ data: Machine[] }>(`${LAB_URL}/${labId}`);

export const addLabMachine = (labId: number, unitKey: string) =>
  api.post(`${LAB_URL}/${labId}/${unitKey}`);

export const removeLabMachine = (labId: number, unitKey: string) =>
  api.delete(`${LAB_URL}/${labId}/${unitKey}`);
