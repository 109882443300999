export const createUrlencoded = (
  data: Record<string, string | number | number[] | string[]>
) => {
  const urlencoded = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      if (value.length) {
        value.forEach((item) => {
          urlencoded.append(`${key}[]`, item.toString());
        });
      } else {
        urlencoded.append(`${key}[]`, "");
      }
    } else {
      urlencoded.append(key, value.toString());
    }
  });

  return urlencoded.toString();
};
