import React from "react";

import { IconProps } from "./types";

export const CloseIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.6339 8.63388C26.122 8.14573 26.122 7.35427 25.6339 6.86612C25.1457 6.37796 24.3543 6.37796 23.8661 6.86612L16 14.7322L8.13388 6.86612C7.64573 6.37796 6.85427 6.37796 6.36612 6.86612C5.87796 7.35427 5.87796 8.14573 6.36612 8.63388L14.2322 16.5L6.36612 24.3661C5.87796 24.8543 5.87796 25.6457 6.36612 26.1339C6.85427 26.622 7.64573 26.622 8.13388 26.1339L16 18.2678L23.8661 26.1339C24.3543 26.622 25.1457 26.622 25.6339 26.1339C26.122 25.6457 26.122 24.8543 25.6339 24.3661L17.7678 16.5L25.6339 8.63388Z"
      fill="currentColor"
    />
  </svg>
);
