import React from "react";

import { CloseIcon } from "icons/CloseIcon";
import { PlusIcon } from "icons/PlusIcon";

interface ItemRoleGroupProps {
  title: string;
  isActive?: boolean;
  isShowPlus?: boolean;
  isShowClose?: boolean;
  isTransparent?: boolean;
  onClick?: () => void;
}

export const ItemRoleGroup: React.FC<ItemRoleGroupProps> = ({
  title,
  isActive,
  isShowPlus,
  isShowClose,
  isTransparent,
  onClick,
}) => (
  <div
    className={`relative h-[48px] sm:h-[42px] px-4 border rounded-full flex justify-center items-center ${
      isActive
        ? "bg-darkGreen border-green"
        : isTransparent
        ? "border-gray4"
        : "bg-gray3 border-gray4"
    } ${onClick ? "btn-hover-after" : ""}`}
    onClick={onClick}
  >
    {isShowPlus && (
      <div className="text-white mr-2">
        <PlusIcon size={16} />
      </div>
    )}

    <span
      className={`whitespace-nowrap text-base sm:text-sm font-normal capitalize ${
        isActive ? "text-green" : "text-white"
      }`}
    >
      {title}
    </span>

    {isShowClose && (
      <div className="text-white ml-2">
        <CloseIcon size={16} />
      </div>
    )}
  </div>
);
