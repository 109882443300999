import React from "react";

import { ThreeDotsHorizontalIcon } from "icons/ThreeDotsHorizontalIcon";

interface ThreeDotsButtonProps {
  onClick?: () => void;
}

export const ThreeDotsButton: React.FC<ThreeDotsButtonProps> = ({
  onClick,
}) => (
  <div className="text-white cursor-pointer" onClick={onClick}>
    <ThreeDotsHorizontalIcon />
  </div>
);
