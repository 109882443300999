import React, { useCallback, useState } from "react";
import { User } from "types";

import { getProfilePicture } from "utils/getProfilePicture";
import { ModalMembers } from "components/Modal/ModalMembers";
import { Image } from "components/Image";

const usersToShow = 5;

interface MemberListProps {
  users?: User[];
}

export const MemberList: React.FC<MemberListProps> = ({ users }) => {
  const [isModalMembers, setIsModalMembers] = useState(false);

  const showModalMembers = useCallback(() => {
    setIsModalMembers(true);
  }, []);

  const hideModalMembers = useCallback(() => {
    setIsModalMembers(false);
  }, []);

  return (
    <>
      <div className="flex">
        {users?.length ? (
          <>
            {users.slice(0, usersToShow).map((user) => (
              <div
                key={user.id}
                className="-ml-2 h-[40px] aspect-square border-2 border-gray1 rounded-full flex overflow-hidden cursor-pointer"
                title={`${user.name} ${user.surname}`}
                onClick={showModalMembers}
              >
                <Image
                  rounded="full"
                  size={40}
                  iconSize={20}
                  src={getProfilePicture(user)}
                />
              </div>
            ))}

            {users.length > usersToShow && (
              <div
                className="-ml-2 h-[40px] min-w-[40px] px-2 rounded-full border-2 border-gray1 bg-gray4 flex items-center justify-center cursor-pointer"
                onClick={showModalMembers}
              >
                <span className="font-normal text-sm text-white">
                  +{users.length - usersToShow}
                </span>
              </div>
            )}
          </>
        ) : (
          <div className="h-[36px]" />
        )}
      </div>

      {isModalMembers && users?.length && (
        <ModalMembers users={users} hideModal={hideModalMembers} />
      )}
    </>
  );
};
