import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/Modal/Modal";
import { ItemRoleGroup } from "components/Item/ItemRoleGroup";
import { ItemUser } from "components/Item/ItemUser";
import { User } from "types";

interface ModalGroupsProps {
  user: User;
  hideModal: () => void;
}

export const ModalGroups: React.FC<ModalGroupsProps> = ({
  user,
  hideModal,
}) => {
  const { t } = useTranslation();

  const titleComponent = useCallback(
    (title: string) => (
      <h2 className="mb-6 sm:mb-4 text-white font-bold text-base leading-5">
        {title}
      </h2>
    ),
    []
  );

  return (
    <Modal title={t("common.groups")} hideModal={hideModal}>
      <div className="px-8 py-6 sm:px-6">
        <div className="pb-6 border-b border-gray3">
          {titleComponent(t("common.software"))}

          <div className="flex flex-wrap">
            <ItemUser user={user} />
          </div>
        </div>

        <div className="pt-6">
          {titleComponent(t("common.groups"))}

          <div className="flex flex-wrap gap-4">
            {user.groups.map((group) => (
              <ItemRoleGroup key={group.id} title={group.name} />
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};
