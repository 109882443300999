import React from "react";

import { IconProps } from "./types";

export const PuzzleIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M2 11.9975C2 13.4161 2.99946 14.1899 4.12251 14.1899C4.61149 14.1899 4.96614 14.0609 5.42826 13.76C5.80978 13.5236 6.0892 13.674 6.0892 14.0018V16.6456C6.0892 17.8975 6.78237 18.5693 8.05051 18.5693H9.99567C10.3235 18.5693 10.4793 18.2898 10.2375 17.9083C9.93657 17.4462 9.80765 17.0915 9.80765 16.6025C9.80765 15.4795 10.5868 14.48 12 14.48C13.4186 14.48 14.1977 15.4795 14.1977 16.6025C14.1977 17.0915 14.0634 17.4462 13.7625 17.9083C13.5261 18.2898 13.6765 18.5693 14.0043 18.5693H15.9495C17.223 18.5693 17.9108 17.8975 17.9108 16.6456V14.0018C17.9108 13.674 18.1902 13.5236 18.5717 13.76C19.0392 14.0609 19.3885 14.1899 19.8774 14.1899C21.0005 14.1899 22 13.4161 22 11.9975C22 10.5843 21.0005 9.80514 19.8774 9.80514C19.3885 9.80514 19.0392 9.9341 18.5717 10.235C18.1902 10.4768 17.9108 10.321 17.9108 9.99321V7.35485C17.9108 6.10821 17.223 5.43115 15.9495 5.43115H14.0043C13.6765 5.43115 13.5261 5.71057 13.7625 6.09209C14.0634 6.55958 14.1977 6.91422 14.1977 7.39784C14.1977 8.52088 13.4186 9.52035 12 9.52035C10.5868 9.52035 9.80765 8.52088 9.80765 7.39784C9.80765 6.91422 9.93657 6.55958 10.2375 6.09209C10.4793 5.71057 10.3235 5.43115 9.99567 5.43115H8.05051C6.78237 5.43115 6.0892 6.10821 6.0892 7.35485V9.99321C6.0892 10.321 5.80978 10.4768 5.42826 10.235C4.96614 9.9341 4.61149 9.80514 4.12251 9.80514C2.99946 9.80514 2 10.5843 2 11.9975Z"
      fill="currentColor"
    />
  </svg>
);
