import React from "react";

import { IconProps } from "./types";

export const DropdownIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M13.5047 17.4101L20.5396 10.3791C21.106 9.81626 21.276 8.97612 20.9711 8.23703C20.6661 7.49662 19.9512 7.01855 19.1497 7.01855L5.08871 7.01855C4.29207 7.01855 3.57935 7.49399 3.27264 8.22955C3.1707 8.47518 3.12061 8.73268 3.12061 8.98754C3.12061 9.49725 3.31966 9.99774 3.69579 10.3765L10.7197 17.4092L10.7237 17.4136C11.49 18.1707 12.7357 18.1707 13.5047 17.4101Z"
      fill="currentColor"
    />
  </svg>
);
