import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { SER } from "utils/serverErrorHandler";
import { ItemFurnaceProcess } from "components/Item/ItemFurnaceProcess";
import { Empty } from "components/Empty";
import { Loading } from "components/Loading";
import { NetworkIcon } from "icons/NetworkIcon";
import { Baking, BakingDetails } from "types";

export const DeviceHistory: React.FC = () => {
  const [furnaceBakings, setFurnaceBakings] = useState<
    (Baking | BakingDetails)[]
  >([]);
  const [activeBakingId, setActiveBakingId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const { unitKey } = useParams();

  const getFurnaceBakings = useCallback(() => {
    if (unitKey) {
      SER(async () => {
        setIsLoading(true);

        const { data: furnaceBakings } = await serverApi.getFurnaceBakings(
          unitKey
        );
        setFurnaceBakings(furnaceBakings);

        setIsLoading(false);
      });
    }
  }, [unitKey]);

  useEffect(() => {
    getFurnaceBakings();
  }, [getFurnaceBakings]);

  const toggleActiveBaking = useCallback(
    (id: string) => {
      if (id === activeBakingId) {
        setActiveBakingId(null);
      } else {
        SER(async () => {
          setActiveBakingId(id);

          const { data: bakingDetails } = await serverApi.getFurnaceBaking(id);

          setFurnaceBakings(
            furnaceBakings.map((baking) =>
              baking.id === id ? bakingDetails : baking
            )
          );
        });
      }
    },
    [activeBakingId, furnaceBakings]
  );

  return (
    <div className="pt-6 sm:p-4 flex flex-1 flex-col gap-4 overflow-y-scroll">
      {isLoading ? (
        <Loading />
      ) : furnaceBakings.length ? (
        furnaceBakings.map((baking) => (
          <ItemFurnaceProcess
            key={baking.id}
            baking={baking}
            isActive={baking.id === activeBakingId}
            toggleActiveBaking={toggleActiveBaking}
          />
        ))
      ) : (
        <Empty title={t("common.no_history_warning")} Icon={NetworkIcon} />
      )}
    </div>
  );
};
