import React from "react";

import { IconProps } from "./types";

export const LoadingIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    color="#00714F"
  >
    <linearGradient id="spinner">
      <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
      <stop offset="100%" stopOpacity="0" stopColor="currentColor" />
    </linearGradient>

    <path
      stroke="url(#spinner)"
      strokeLinecap="round"
      strokeWidth={4}
      d="M16 2a14 14 0 1 0 14 14"
    />
  </svg>
);
