export enum ROUTES {
  LABORATORIES = "/laboratories",
  LABORATORY_MEMBERS = "/laboratories/:labId/members",
  LABORATORY_GROUPS = "/laboratories/:labId/groups",
  LABORATORY_LICENSES = "/laboratories/:labId/licenses",
  LABORATORY_DATA_CONNECTIONS = "/laboratories/:labId/data_connections",
  LABORATORY_DEVICES = "/laboratories/:labId/devices",

  DEVICES = "/devices/:deviceType",
  DEVICE_PROCESSES = "/devices/:deviceType/:unitKey/processes", // milling
  DEVICE_PROCESS = "/devices/:deviceType/:unitKey/process", // furnace
  DEVICE_HISTORY = "/devices/:deviceType/:unitKey/history", // furnace
  DEVICE_MAINTENANCE = "/devices/:deviceType/:unitKey/maintenance", // common
  DEVICE_MESSAGES = "/devices/:deviceType/:unitKey/messages", // common

  LOGIN = "/login",
  INVITATION = "/invitation/:uuid",
  SETTINGS = "/settings",
}
