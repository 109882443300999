import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { getProfilePicture } from "utils/getProfilePicture";
import { SER } from "utils/serverErrorHandler";
import { Modal } from "components/Modal/Modal";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { ButtonIcon } from "components/ButtonIcon";
import { Image } from "components/Image";
import { Id, UserJoinRequest } from "types";

interface ModalJoinLabProps {
  hideModal: () => void;
}

type FormEventValues = {
  realm: { value: string };
};

export const ModalJoinLab: React.FC<ModalJoinLabProps> = ({ hideModal }) => {
  const [userJoinRequestList, setUserJoinRequestList] = useState<
    UserJoinRequest[]
  >([]);

  const { t } = useTranslation();

  const getUserJoinRequestList = useCallback(async () => {
    SER(async () => {
      const { data: userJoinRequestList } =
        await serverApi.getUserJoinRequestList();
      setUserJoinRequestList(userJoinRequestList.data);
    });
  }, []);

  useEffect(() => {
    getUserJoinRequestList();
  }, [getUserJoinRequestList]);

  const deleteJoinRequest = useCallback(
    (joinId: Id) => {
      SER(async () => {
        await serverApi.deleteJoinRequest(joinId);
        setUserJoinRequestList(
          userJoinRequestList.filter(({ id }) => id !== joinId)
        );
        toast.success(t("message.request_deleted"));
      });
    },
    [t, userJoinRequestList]
  );

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      const target = event.target as typeof event.target & FormEventValues;

      SER(async () => {
        await serverApi.joinLab(target.realm.value);
        getUserJoinRequestList();
        toast.success(t("message.request_sent"));
      });
    },
    [t, getUserJoinRequestList]
  );

  return (
    <Modal title={t("common.join_laboratory")} hideModal={hideModal}>
      <div className="px-8 py-6 sm:px-6 sm:py-4">
        <form className="flex" onSubmit={onSubmit}>
          <Input
            buttonOneLine
            name="realm"
            placeholder={t("common.type_name_or_realm")}
          />
          <Button>{t("common.send_request")}</Button>
        </form>

        {userJoinRequestList.length ? (
          <h2 className="mt-6 text-base text-white font-bold leading-5">
            {t("common.requests_pending")}
          </h2>
        ) : null}

        {userJoinRequestList.map((item) => (
          <div key={item.id} className="mt-4 flex items-center justify-between">
            <div className="flex items-center">
              <Image
                rounded="full"
                size={40}
                iconSize={20}
                src={getProfilePicture(item)}
              />

              <span className="ml-4 text-base text-gray6 font-normal">
                {item.name} ({item.realm})
              </span>
            </div>

            <ButtonIcon size="sm" onClick={() => deleteJoinRequest(item.id)} />
          </div>
        ))}
      </div>
    </Modal>
  );
};
