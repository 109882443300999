import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useTypedOutletContext } from "hooks/useTypedOutletContext";
import { SER } from "utils/serverErrorHandler";
import { Button } from "components/Button";
import { ItemRoleGroup } from "components/Item/ItemRoleGroup";
import { ItemLicense } from "components/Item/ItemLicense";
import { ModalManageGroup } from "components/Modal/ModalManageGroup";
import { ThreeDotsButton } from "components/ThreeDotsButton";
import { Search } from "components/Search";
import { Loading } from "components/Loading";
import { Empty } from "components/Empty";
import { MemberList } from "components/MemberList";
import { PlusIcon } from "icons/PlusIcon";
import { NetworkIcon } from "icons/NetworkIcon";
import { Group } from "types";

const licensesToShow = 3;

export const LaboratoryGroups: React.FC = () => {
  const [labGroups, setLabGroups] = useState<Group[]>([]);
  const [isModalManageGroup, setIsModalManageGroup] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { permissions } = useTypedOutletContext();

  const params = useParams();
  const { t } = useTranslation();

  const labId = useMemo(() => Number(params.labId), [params]);

  const getLabGroups = useCallback(() => {
    SER(async () => {
      setIsLoading(true);

      const { data: groups } = await serverApi.getLabGroups(labId);
      setLabGroups(groups.data);

      setIsLoading(false);
    });
  }, [labId]);

  useEffect(() => {
    getLabGroups();
  }, [getLabGroups]);

  const showModalManageGroup = useCallback(() => {
    setIsModalManageGroup(true);
  }, []);

  const hideModalManageGroup = useCallback(() => {
    setIsModalManageGroup(false);
    setSelectedGroup(null);
    getLabGroups();
  }, [getLabGroups]);

  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target }) => {
      setSearchValue(target.value);
    }, []);

  const editGroup = useCallback(
    (labGroup: Group) => {
      setSelectedGroup(labGroup);
      showModalManageGroup();
    },
    [showModalManageGroup]
  );

  const filteredLabGroups = useMemo(
    () =>
      labGroups.filter((labGroup) =>
        labGroup.name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [labGroups, searchValue]
  );

  const labGroupsComponent = useMemo(
    () => (
      <div className="xl:overflow-x-scroll xl:max-w-[calc(100vw-128px)] md:max-w-[calc(100vw-112px)] sm:max-w-[calc(100vw-32px)]">
        <div className="xl:min-w-[1150px]">
          <div
            className={`mt-6 sm:mt-4 flex min-h-[48px] border-b border-gray3 items-center ${
              permissions.groups ? "pr-16" : "pr-8"
            }`}
          >
            <div className="w-3/12 px-4">
              <span className="text-gray6 text-sm font-normal">
                {t("common.name")}
              </span>
            </div>
            <div className="w-6/12 px-4">
              <span className="text-gray6 text-sm font-normal">
                {t("common.licenses")}
              </span>
            </div>
            <div className="w-3/12 px-4">
              <span className="text-gray6 text-sm font-normal">
                {t("common.members")}
              </span>
            </div>
          </div>

          {filteredLabGroups.map((labGroup) => (
            <div
              key={labGroup.id}
              className="group flex py-2 pr-8 items-center justify-between hover:bg-gray1 transition-all duration-200 ease-in-out"
            >
              <div className="flex flex-1 items-center">
                <div className="w-3/12 px-4 flex">
                  <ItemRoleGroup title={labGroup.name} />
                </div>

                <div className="w-6/12 px-4 flex flex-wrap gap-x-4 gap-y-2">
                  {labGroup.licenses.slice(0, licensesToShow).map((license) => (
                    <ItemLicense key={license.id} license={license} />
                  ))}

                  {labGroup.licenses.length > licensesToShow && (
                    <div className="h-[48px] sm:h-[42px] min-w-[48px] sm:min-w-[42px] px-2 aspect-square rounded-lg bg-gray4 flex items-center justify-center">
                      <span className="font-normal text-sm text-white">
                        +{labGroup.licenses.length - licensesToShow}
                      </span>
                    </div>
                  )}
                </div>

                <div className="w-3/12 px-4">
                  <MemberList users={labGroup.members} />
                </div>
              </div>

              {permissions.groups && (
                <ThreeDotsButton onClick={() => editGroup(labGroup)} />
              )}
            </div>
          ))}
        </div>
      </div>
    ),
    [t, permissions, filteredLabGroups, editGroup]
  );

  return (
    <>
      <div className="flex justify-between sm:flex-wrap sm:justify-end">
        {labGroups.length && (
          <Search
            placeholder={t("common.search_group")}
            onChange={onChangeSearch}
          />
        )}

        {permissions.groups && (
          <div className={labGroups.length ? "sm:mt-4" : ""}>
            <Button IconBefore={PlusIcon} onClick={showModalManageGroup}>
              {t("common.add_group")}
            </Button>
          </div>
        )}
      </div>

      {isLoading ? (
        <Loading />
      ) : labGroups.length ? (
        labGroupsComponent
      ) : (
        <Empty Icon={NetworkIcon} title={t("common.no_groups_warning")} />
      )}

      {isModalManageGroup ? (
        <ModalManageGroup
          labId={labId}
          group={selectedGroup}
          hideModal={hideModalManageGroup}
        />
      ) : null}
    </>
  );
};
