import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/Modal/Modal";
import { Button } from "components/Button";
import { TrashIcon } from "icons/TrashIcon";
import { CloseIcon } from "icons/CloseIcon";

interface ModalConfirmationProps {
  children?: React.ReactNode;
  type: "remove" | "delete" | "leave" | "disconnect";
  title: string;
  text: string;
  hideModal: () => void;
  onClick: () => void;
}

export const ModalConfirmation: React.FC<ModalConfirmationProps> = ({
  children,
  type,
  title,
  text,
  hideModal,
  onClick,
}) => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    switch (type) {
      case "delete":
        return {
          buttonText: t("common.delete"),
          Icon: TrashIcon,
        };
      case "remove":
        return {
          buttonText: t("common.remove"),
          Icon: CloseIcon,
        };
      case "leave":
        return {
          buttonText: t("common.leave"),
          Icon: CloseIcon,
        };
      case "disconnect":
        return {
          buttonText: t("common.disconnect"),
          Icon: CloseIcon,
        };
    }
  }, [type, t]);

  return (
    <Modal
      title={`${title}?`}
      Icon={data.Icon}
      footer={
        <div className="flex flex-1">
          <Button type="confirmationModalCancel" onClick={hideModal}>
            {t("common.cancel")}
          </Button>
          <Button type="confirmationModalAction" onClick={onClick}>
            {data.buttonText}
          </Button>
        </div>
      }
    >
      {children ? (
        <div className="flex px-8 py-6 sm:px-6 sm:py-4 border-b border-gray3">
          {children}
        </div>
      ) : null}
      <div className="px-8 sm:px-6 py-5 sm:py-4">
        <span className="text-white font-normal text-base sm:text-sm">
          {text}
        </span>
      </div>
    </Modal>
  );
};
