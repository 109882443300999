import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { EditIcon } from "icons/EditIcon";
import { CloseIcon } from "icons/CloseIcon";
import { TrashIcon } from "icons/TrashIcon";
import { ShareIcon } from "icons/ShareIcon";

export const useMenuActions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      MENU_ACTIONS: {
        editMember: (action = () => {}) => ({
          title: t("common.edit_member"),
          icon: EditIcon,
          color: "#fff",
          action,
        }),
        editMembers: (action = () => {}) => ({
          title: t("common.edit_members"),
          icon: EditIcon,
          color: "#fff",
          action,
        }),
        removeMember: (action = () => {}) => ({
          title: t("common.remove_member"),
          icon: CloseIcon,
          color: "#EB5757",
          action,
        }),
        removeMembers: (action = () => {}) => ({
          title: t("common.remove_members"),
          icon: CloseIcon,
          color: "#EB5757",
          action,
        }),
        editLaboratory: (action = () => {}) => ({
          title: t("common.edit_laboratory"),
          icon: EditIcon,
          color: "#fff",
          action,
        }),
        deleteLaboratory: (action = () => {}) => ({
          title: t("common.delete_laboratory"),
          icon: TrashIcon,
          color: "#EB5757",
          action,
        }),
        leaveLaboratory: (action = () => {}) => ({
          title: t("common.leave_laboratory"),
          icon: CloseIcon,
          color: "#EB5757",
          action,
        }),
        disconnectLaboratory: (action = () => {}) => ({
          title: t("common.disconnect_lab"),
          icon: CloseIcon,
          color: "#EB5757",
          action,
        }),
        openDevicePage: (action = () => {}) => ({
          title: t("common.open_device_page"),
          icon: ShareIcon,
          color: "#fff",
          action,
        }),
        removeDevice: (action = () => {}) => ({
          title: t("common.remove_device"),
          icon: CloseIcon,
          color: "#EB5757",
          action,
        }),
      },
    }),
    [t]
  );
};
