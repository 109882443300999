import React, { useCallback, useMemo } from "react";
import { To, useNavigate } from "react-router-dom";

import { ChevronLeftIcon } from "icons/ChevronLeftIcon";

interface HeaderProps {
  title: string;
  backRoute?: To;
  children?: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  backRoute,
  children,
}) => {
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    if (window.history.state.idx > 0) navigate(-1);
    else if (backRoute) navigate(backRoute);
  }, [backRoute, navigate]);

  const titleComponent = useMemo(
    () =>
      backRoute ? (
        <div
          onClick={goBack}
          className="flex items-center cursor-pointer text-gray6 hover:text-white transition-all duration-200 ease-in-out"
        >
          <ChevronLeftIcon />
          <span className="ml-2 text-xl md:text-lg font-normal">{title}</span>
        </div>
      ) : (
        <h1 className="text-white text-[34px] font-bold md:text-2xl md:leading-[3rem]">
          {title}
        </h1>
      ),
    [title, backRoute, goBack]
  );

  return (
    <header className="flex flex-row px-8 py-6 justify-between items-center md:flex-wrap md:px-6 md:py-4 sm:px-4">
      {titleComponent}

      {children}
    </header>
  );
};
