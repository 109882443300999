import React from "react";

import { IconProps } from "./types";

export const InfoIcon: React.FC<IconProps> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      d="M8 12.8C10.651 12.8 12.8 10.651 12.8 8C12.8 5.34903 10.651 3.2 8 3.2C5.34903 3.2 3.2 5.34903 3.2 8C3.2 10.651 5.34903 12.8 8 12.8ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 6.8C8.33137 6.8 8.6 7.06863 8.6 7.4V11C8.6 11.3314 8.33137 11.6 8 11.6C7.66863 11.6 7.4 11.3314 7.4 11V7.4C7.4 7.06863 7.66863 6.8 8 6.8ZM8 6.01858C8.39447 6.01858 8.71426 5.6988 8.71426 5.30432C8.71426 4.90985 8.39447 4.59006 8 4.59006C7.60553 4.59006 7.28574 4.90985 7.28574 5.30432C7.28574 5.6988 7.60553 6.01858 8 6.01858Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
