export const getCurrentScreenData = <T>(
  data: Partial<Record<"sm" | "md" | "lg" | "xl" | "all", T>>
) => {
  if (window.innerWidth <= 639 && data["sm"]) return data["sm"];
  if (window.innerWidth <= 767 && data["md"]) return data["md"];
  if (window.innerWidth <= 1023 && data["lg"]) return data["lg"];
  if (window.innerWidth <= 1279 && data["xl"]) return data["xl"];

  return data["all"] as T;
};
