import React from "react";

import { IconProps } from "./types";

export const SearchIcon: React.FC<IconProps> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.98249 10.7997C9.19969 11.4082 8.21606 11.7705 7.14779 11.7705C4.59471 11.7705 2.52502 9.70087 2.52502 7.14779C2.52502 4.59471 4.59471 2.52502 7.14779 2.52502C9.70087 2.52502 11.7705 4.59471 11.7705 7.14779C11.7705 8.21606 11.4082 9.19969 10.7997 9.98249L13.3058 12.4886C13.5314 12.7142 13.5314 13.0801 13.3058 13.3058C13.0801 13.5314 12.7142 13.5314 12.4886 13.3058L9.98249 10.7997ZM10.6149 7.14779C10.6149 9.0626 9.0626 10.6149 7.14779 10.6149C5.23298 10.6149 3.68072 9.0626 3.68072 7.14779C3.68072 5.23298 5.23298 3.68072 7.14779 3.68072C9.0626 3.68072 10.6149 5.23298 10.6149 7.14779Z"
      fill="currentColor"
    />
  </svg>
);
