import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useMenuActions } from "hooks/useMenuActions";
import { SER } from "utils/serverErrorHandler";
import { ModalEditMember } from "components/Modal/ModalEditMember";
import { ModalConfirmation } from "components/Modal/ModalConfirmation";
import { DropdownMenu } from "components/DropdownMenu/DropdownMenu";
import { ItemUser } from "components/Item/ItemUser";
import { User } from "types";

interface DropdownMenuMemberProps {
  children: React.ReactNode;
  labId: number;
  members: User[];
  hideModalCB: () => void;
}

export const DropdownMenuMember: React.FC<DropdownMenuMemberProps> = ({
  children,
  labId,
  members,
  hideModalCB,
}) => {
  const [isModalEditMember, setIsModalEditMember] = useState(false);
  const [isModalConfirmation, setIsModalConfirmation] = useState(false);

  const { t } = useTranslation();

  const { MENU_ACTIONS } = useMenuActions();

  const showModalConfirmation = useCallback(() => {
    setIsModalConfirmation(true);
  }, []);

  const hideModalConfirmation = useCallback(() => {
    setIsModalConfirmation(false);
    hideModalCB();
  }, [hideModalCB]);

  const showModalEditMember = useCallback(() => {
    setIsModalEditMember(true);
  }, []);

  const hideModalEditMember = useCallback(() => {
    setIsModalEditMember(false);
    hideModalCB();
  }, [hideModalCB]);

  const removeMembers = useCallback(() => {
    SER(
      async () => {
        await Promise.all(
          members.map(({ id }) => serverApi.removeLabUser(labId, id))
        );
      },
      undefined,
      hideModalConfirmation
    );
  }, [labId, members, hideModalConfirmation]);

  const menuOptions = useMemo(() => {
    const isOneMember = members.length === 1;

    return [
      MENU_ACTIONS[isOneMember ? "editMember" : "editMembers"](
        showModalEditMember
      ),
      MENU_ACTIONS[isOneMember ? "removeMember" : "removeMembers"](
        showModalConfirmation
      ),
    ];
  }, [MENU_ACTIONS, members, showModalEditMember, showModalConfirmation]);

  return (
    <>
      <DropdownMenu options={menuOptions}>{children}</DropdownMenu>

      {isModalEditMember && (
        <ModalEditMember
          labId={labId}
          users={members}
          hideModal={hideModalEditMember}
        />
      )}

      {isModalConfirmation && (
        <ModalConfirmation
          type="remove"
          title={
            members.length === 1
              ? t("common.remove_member")
              : t("common.remove_members")
          }
          text={t("common.modal_confirmation_member")}
          hideModal={hideModalConfirmation}
          onClick={removeMembers}
        >
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            {members.map((user) => (
              <ItemUser key={user.id} user={user} />
            ))}
          </div>
        </ModalConfirmation>
      )}
    </>
  );
};
