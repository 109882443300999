type ConvertImageToBase64 = (file: File | Blob) => Promise<string>;

export const convertImageToBase64: ConvertImageToBase64 = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
  });
