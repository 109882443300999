import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { SER } from "utils/serverErrorHandler";
import { convertTime } from "utils/convertTime";
import { formatDate } from "utils/formatDate";
import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { Image } from "components/Image";
import { CircleProgressBar } from "components/CircleProgressBar";
import { Loading } from "components/Loading";
import { Empty } from "components/Empty";
import { DropdownIcon } from "icons/DropdownIcon";
import { CheckIcon } from "icons/CheckIcon";
import { ClockIcon } from "icons/ClockIcon";
import { NetworkIcon } from "icons/NetworkIcon";
import { ProcessesEmptyIcon } from "icons/ProcessesEmptyIcon";
import { Elaboration, MillingDevice } from "types";

const contentSize = getCurrentScreenData({
  xl: { image: 100, icon: 46 },
  all: { image: 120, icon: 65 },
});

export const DeviceProcesses: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [millingDevice, setMillingDevice] = useState<MillingDevice>();
  const [activeElaborationId, setActiveElaborationId] = useState<string | null>(
    null
  );

  const { unitKey } = useParams();
  const { t } = useTranslation();

  const getMillingDevice = useCallback(() => {
    if (unitKey) {
      SER(async () => {
        setIsLoading(true);

        const { data: millingDevice } = await serverApi.getMillingDevice(
          unitKey
        );

        setMillingDevice({
          ...millingDevice,
          completedElaborationList: millingDevice.completedElaborationList.sort(
            (a, b) => (a.fileInfo.endTime < b.fileInfo.endTime ? 1 : -1)
          ),
        });

        setIsLoading(false);
      });
    }
  }, [unitKey]);

  useEffect(() => {
    getMillingDevice();
  }, [getMillingDevice]);

  const toggleActiveElaboration = useCallback(
    (id: string) => {
      setActiveElaborationId(id === activeElaborationId ? null : id);
    },
    [activeElaborationId]
  );

  const infoBlockComponent = useCallback(
    (title: string, data: { name: string; value: string | number }[]) => (
      <div className="flex flex-1 flex-col border-r border-gray3">
        <div className="h-[72px] xl:h-[68px] sm:h-[62px] border-b border-gray3 px-8 xl:px-6 sm:px-4 flex items-center">
          <h1 className="text-white text-xl font-bold">{title}</h1>
        </div>
        <div className="px-8 xl:px-6 sm:px-4 py-4">
          {data.map(({ name, value }, i) => (
            <div key={i} className="h-[32px] flex items-center">
              <span className="w-[120px] text-gray6 text-base font-normal">
                {name}
              </span>
              <span className="text-white text-base font-normal whitespace-nowrap">
                {value}
              </span>
            </div>
          ))}
        </div>
      </div>
    ),
    []
  );

  const elaborationComponent = useCallback(
    (elaborationList: Elaboration[]) =>
      elaborationList.map((item) => {
        const isActive = item.id === activeElaborationId;
        const { startTime, endTime, state } = item.fileInfo;
        const isProcessDone = state === "Done";

        const blockData = [
          { name: t("common.block_id"), value: item.blankInfo.nestingBlockId },
          { name: t("common.diameter"), value: item.blankInfo.diameter },
          { name: t("common.height"), value: item.blankInfo.height },
          { name: t("common.shrinkage"), value: item.blankInfo.shrinkage },
          { name: t("common.lot_number"), value: item.blankInfo.lotNumber },
          { name: t("common.article"), value: item.blankInfo.article },
          {
            name: t("common.material_type"),
            value: item.blankInfo.materialType,
          },
        ];

        const timeData = [
          {
            name: t("common.duration"),
            value: convertTime(endTime - startTime).seconds,
          },
          {
            name: t("common.start_time"),
            value: formatDate.toLocaleString(startTime * 1000) || "",
          },
          {
            name: t("common.end_time"),
            value: formatDate.toLocaleString(endTime * 1000) || "",
          },
        ];

        return (
          <div key={item.id} className="bg-gray1 rounded-lg">
            <div
              className={`p-4 rounded-lg flex items-center ${
                isActive ? "bg-blue rounded-b-none" : ""
              }`}
            >
              <div className="sm:hidden">
                <Image
                  rounded="full"
                  size={contentSize.image}
                  iconSize={contentSize.icon}
                  src={item.imageUrl}
                  PlaceholderIcon={ProcessesEmptyIcon}
                />
              </div>

              <div className="ml-8 mr-[5%] xl:ml-6 sm:ml-0 xl:mr-10 sm:mr-6 flex flex-1 items-center justify-between xl:flex-wrap overflow-hidden">
                <div className="flex flex-col xl:basis-full overflow-hidden">
                  <span
                    className="text-xl xl:text-base font-bold text-white whitespace-nowrap text-ellipsis overflow-hidden"
                    title={item.fileInfo.fileName}
                  >
                    {item.fileInfo.fileName}
                  </span>
                  <span
                    className={`text-base font-normal max-w-[200px] ${
                      isActive ? "text-white" : "text-gray6"
                    }`}
                  >
                    {item.blankInfo.nestingBlockId} -{" "}
                    {item.blankInfo.materialType}
                  </span>
                </div>

                {startTime && endTime ? (
                  <div className="min-w-[160px] flex flex-col items-center xl:items-start xl:mt-3">
                    <div
                      className={`flex items-center ${
                        isActive ? "text-white" : "text-gray6"
                      }`}
                    >
                      <div className="xl:hidden">
                        <ClockIcon />
                      </div>
                      <span className="ml-2 xl:ml-0 text-lg xl:text-base font-normal whitespace-nowrap">
                        {isProcessDone
                          ? t("common.duration_time")
                          : t("common.time_remaining")}
                      </span>
                    </div>
                    <h1 className="text-white font-bold text-2xl xl:text-base">
                      {
                        convertTime(
                          isProcessDone ? endTime - startTime : item.countDown
                        ).seconds
                      }
                    </h1>
                  </div>
                ) : null}
              </div>

              <div className="flex items-center">
                {isProcessDone ? (
                  <div className="w-[70px] flex justify-center text-white">
                    <CheckIcon size={32} />
                  </div>
                ) : (
                  <div className="relative">
                    <CircleProgressBar
                      size={70}
                      strokeWidth={4}
                      strokeColor="#fff"
                      progress={item.progress || 0}
                    />

                    <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center">
                      <span className="text-base font-semibold text-white">
                        {item.progress?.toFixed(0)}%
                      </span>
                    </div>
                  </div>
                )}

                <div
                  className={`text-white cursor-pointer ml-8 sm:ml-6 mr-4 sm:mr-2 ${
                    isActive ? "rotate-180" : ""
                  }`}
                  onClick={() => toggleActiveElaboration(item.id)}
                >
                  <DropdownIcon />
                </div>
              </div>
            </div>

            {isActive ? (
              <div className="flex flex-1 xl:flex-wrap">
                {infoBlockComponent(t("common.block_info"), blockData)}
                {infoBlockComponent(t("common.time"), timeData)}
              </div>
            ) : null}
          </div>
        );
      }),
    [t, activeElaborationId, infoBlockComponent, toggleActiveElaboration]
  );

  return isLoading ? (
    <Loading />
  ) : millingDevice?.currentElaborationList.length ||
    millingDevice?.completedElaborationList.length ? (
    <div className="pt-6 sm:p-4 flex flex-col gap-4 overflow-y-scroll">
      {elaborationComponent(millingDevice?.currentElaborationList || [])}

      {millingDevice?.completedElaborationList.length ? (
        <div className="flex items-center">
          <div className="flex flex-1 h-[1px] bg-gray4" />
          <span className="mx-4 text-base font-normal text-gray4">
            {t("common.completed_tasks")}
          </span>
          <div className="flex flex-1 h-[1px] bg-gray4" />
        </div>
      ) : null}

      {elaborationComponent(millingDevice?.completedElaborationList || [])}
    </div>
  ) : (
    <Empty title={t("common.no_active_processes_warning")} Icon={NetworkIcon} />
  );
};
