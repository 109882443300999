import { serverInstance as api } from "api/instances";

import { Id, Role } from "types";

const URL = "lab/roles";

export const getLabRoles = (labId: Id) =>
  api.get<{ data: Role[] }>(`${URL}/${labId}`);

export const getUserRole = (labId: Id, userId: Id) =>
  api.get<{ data: Role }>(`${URL}/${labId}/user/${userId}/role`);
