import React from "react";

import { IconProps } from "./types";

export const DeviceIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M11.2 7.875C10.3163 7.875 9.6 8.60254 9.6 9.5V14.375C9.6 15.2725 10.3163 16 11.2 16H20.8C21.6837 16 22.4 15.2725 22.4 14.375V9.5C22.4 8.60254 21.6837 7.875 20.8 7.875H11.2Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 24.125C0 26.8174 2.14903 29 4.8 29H27.2C29.851 29 32 26.8174 32 24.125V7.875C32 5.18261 29.851 3 27.2 3H4.8C2.14903 3 0 5.18261 0 7.875V24.125ZM27.2 6.25H4.8C3.91634 6.25 3.2 6.97754 3.2 7.875V19.25H28.8V7.875C28.8 6.97754 28.0837 6.25 27.2 6.25ZM28.8 24.125V22.5H3.2V24.125C3.2 25.0225 3.91634 25.75 4.8 25.75H27.2C28.0837 25.75 28.8 25.0225 28.8 24.125Z"
      fill="currentColor"
    />
  </svg>
);
