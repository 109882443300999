import React from "react";

import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { Input } from "components/Input";
import { SearchIcon } from "icons/SearchIcon";
import { FilterIcon } from "icons/FilterIcon";

const iconSize = getCurrentScreenData({ sm: 20, all: 24 });

interface SearchProps {
  placeholder: string;
  isActiveFitler?: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onClickFilter?: () => void;
}

export const Search: React.FC<SearchProps> = ({
  placeholder,
  isActiveFitler,
  onChange,
  onClickFilter,
}) => (
  <div className="w-[452px] flex relative rounded-lg overflow-hidden lg:w-full">
    <div className="absolute w-[48px] sm:w-[42px] aspect-square text-gray6 flex justify-center items-center top-0 left-0">
      <SearchIcon />
    </div>

    {onClickFilter ? (
      <div
        className={`absolute w-[48px] sm:w-[42px] aspect-square flex justify-center items-center top-0 right-0 border-l border-gray3 cursor-pointer ${
          isActiveFitler ? "bg-white text-black" : "text-[rgba(255,255,255,.5)]"
        }`}
        onClick={onClickFilter}
      >
        <FilterIcon size={iconSize} />
      </div>
    ) : null}

    <Input
      buttonOneLine
      name="search"
      placeholder={placeholder}
      className={`!bg-gray1 ${
        onClickFilter ? "!px-12 sm:!px-10" : "!pl-12 !pr-6 sm:!pl-10"
      }`}
      onChange={onChange}
    />
  </div>
);
