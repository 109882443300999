import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { OnResultFunction, QrReader } from "react-qr-reader";

import { serverApi } from "api";
import { SER } from "utils/serverErrorHandler";
import { Modal } from "components/Modal/Modal";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { QRCodeIcon } from "icons/QRCodeIcon";
import { InfoIcon } from "icons/InfoIcon";

const constraints = { aspectRatio: 1, facingMode: "environment" };
const containerStyle = {
  height: 300,
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
};

interface ModalAddDeviceProps {
  labId?: number;
  hideModal: () => void;
}

type FormEventValues = {
  unitKey: { value: string };
};

export const ModalAddDevice: React.FC<ModalAddDeviceProps> = ({
  labId,
  hideModal,
}) => {
  const [isQRScannerEnabled, setIsQRScannerEnabled] = useState(false);

  const { t } = useTranslation();

  const addUserMachine = useCallback(
    (unitKey: string) => {
      SER(async () => {
        const { data: exists } = await serverApi.deviceExists(unitKey);

        if (exists) {
          if (labId) await serverApi.addLabMachine(labId, unitKey);
          else await serverApi.addUserMachine(unitKey);

          toast.success(t("message.device_added"));
        } else {
          toast.error(t("message.device_does_not_exists"));
        }
        hideModal();
      });
    },
    [t, labId, hideModal]
  );

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      const target = event.target as typeof event.target & FormEventValues;

      addUserMachine(target.unitKey.value);
    },
    [addUserMachine]
  );

  const onResult: OnResultFunction = useCallback(
    (result, error) => {
      if (error?.name === "NotAllowedError") {
        setIsQRScannerEnabled(false);
        toast.error(error.message);
      } else if (result) {
        addUserMachine(result.getText());
      }
    },
    [addUserMachine]
  );

  return (
    <Modal
      title={
        isQRScannerEnabled ? t("common.scan_qr_code") : t("common.add_device")
      }
      hideModal={hideModal}
      footer={
        <div className="flex text-gray6 items-center">
          <InfoIcon />
          <span className="ml-2 font-normal text-sm">
            {t("common.qr_code_info")}
          </span>
        </div>
      }
    >
      <div className="px-8 py-6 sm:px-6 sm:py-4">
        {isQRScannerEnabled ? (
          <QrReader
            constraints={constraints}
            containerStyle={containerStyle}
            onResult={onResult}
          />
        ) : (
          <>
            <form className="flex" onSubmit={onSubmit}>
              <Input
                buttonOneLine
                name="unitKey"
                placeholder={t("common.unit_key")}
              />
              <Button>{t("common.add")}</Button>
            </form>

            <span className="block my-6 sm:my-4 font-normal text-gray6 text-sm text-center">
              {t("common.or")}
            </span>

            <Button
              type="scan"
              IconBefore={QRCodeIcon}
              onClick={() => setIsQRScannerEnabled(true)}
            >
              {t("common.scan_qr_code")}
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
};
