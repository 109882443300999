import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { SER } from "utils/serverErrorHandler";
import { ItemFurnaceProcess } from "components/Item/ItemFurnaceProcess";
import { Empty } from "components/Empty";
import { Loading } from "components/Loading";
import { NetworkIcon } from "icons/NetworkIcon";
import { BakingDetails } from "types";

export const DeviceProcess: React.FC = () => {
  const [activeBaking, setActiveBaking] = useState<BakingDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const { unitKey } = useParams();

  const getFurnaceDevice = useCallback(() => {
    if (unitKey) {
      SER(async () => {
        setIsLoading(true);

        const { data: furnaceDevice } = await serverApi.getFurnaceDevice(
          unitKey
        );

        if (furnaceDevice.bakingId) {
          const { data: activeBaking } = await serverApi.getFurnaceBaking(
            furnaceDevice.bakingId
          );
          setActiveBaking(activeBaking);
        } else {
          setActiveBaking(null);
        }

        setIsLoading(false);
      });
    }
  }, [unitKey]);

  useEffect(() => {
    getFurnaceDevice();
  }, [getFurnaceDevice]);

  return isLoading ? (
    <Loading />
  ) : activeBaking ? (
    <div className="pt-6 sm:p-4 flex flex-1 flex-col gap-4 overflow-y-scroll">
      <ItemFurnaceProcess isActive baking={activeBaking} />
    </div>
  ) : (
    <Empty title={t("common.no_active_process_warning")} Icon={NetworkIcon} />
  );
};
