import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslation from "./en/translation.json";
import deTranslation from "./de/translation.json";
import frTranslation from "./fr/translation.json";
import esTranslation from "./es/translation.json";
import itTranslation from "./it/translation.json";

export type T = TFunction<"translation">;

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    de: { translation: deTranslation },
    fr: { translation: frTranslation },
    es: { translation: esTranslation },
    it: { translation: itTranslation },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
