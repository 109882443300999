import React from "react";

import { IconProps } from "./types";

export const RefreshIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 25" fill="none">
    <path
      d="M18.5989 8.25815C18.2357 7.83031 17.5932 7.77764 17.1644 8.14038C16.736 8.50412 16.6834 9.14612 17.0466 9.57495C18.0578 10.767 18.6148 12.2851 18.6148 13.8498C18.6148 17.4971 15.6473 20.4647 12 20.4647C8.35271 20.4647 5.38518 17.4971 5.38518 13.8498C5.38518 10.3684 8.09084 7.51298 11.5088 7.25993L10.5714 8.19753C10.1739 8.59506 10.1739 9.23955 10.5714 9.63658C10.7702 9.83534 11.0305 9.93472 11.2909 9.93472C11.5513 9.93472 11.8117 9.83534 12.0104 9.63658L14.2599 7.38658C14.9049 6.74159 14.9049 5.69263 14.2599 5.04764L12.0104 2.79814C11.6129 2.40062 10.9689 2.40062 10.5714 2.79814C10.1739 3.19566 10.1739 3.83966 10.5714 4.23718L11.5561 5.22218C6.99318 5.45541 3.34985 9.22985 3.34985 13.8498C3.34985 18.6197 7.2302 22.5 12 22.5C16.7698 22.5 20.6502 18.6197 20.6502 13.8498C20.6502 11.8031 19.9217 9.81744 18.5989 8.25815Z"
      fill="currentColor"
    />
  </svg>
);
