import React from "react";

import { IconProps } from "./types";

export const DeviceEmptyIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none">
    <path
      d="M6.66666 8.83301C4.82571 8.83301 3.33333 10.3254 3.33333 12.1663V23.833H20.0374C20.1447 22.6415 20.4801 21.5157 20.9995 20.4997H15C14.0795 20.4997 13.3333 19.7535 13.3333 18.833V13.833C13.3333 12.9125 14.0795 12.1663 15 12.1663H25C25.9205 12.1663 26.6667 12.9125 26.6667 13.833V15.8447C27.4614 15.62 28.3 15.4997 29.1667 15.4997C32.2669 15.4997 35.0077 17.0388 36.6667 19.3945V12.1663C36.6667 10.3254 35.1743 8.83301 33.3333 8.83301H6.66666Z"
      fill="currentColor"
    />
    <path
      d="M20.0374 25.4997C20.2856 28.2544 21.7525 30.6577 23.8949 32.1663H6.66666C4.82571 32.1663 3.33333 30.674 3.33333 28.833V25.4997H20.0374Z"
      fill="currentColor"
    />
    <path
      d="M29.1667 32.1663C31.1558 32.1663 33.0634 31.3762 34.47 29.9696C35.8765 28.5631 36.6667 26.6555 36.6667 24.6663C36.6667 22.6772 35.8765 20.7696 34.47 19.363C33.0634 17.9565 31.1558 17.1663 29.1667 17.1663C27.1775 17.1663 25.2699 17.9565 23.8634 19.363C22.4568 20.7696 21.6667 22.6772 21.6667 24.6663C21.6667 26.6555 22.4568 28.5631 23.8634 29.9696C25.2699 31.3762 27.1775 32.1663 29.1667 32.1663ZM30.3437 24.6663L32.2552 26.5778C32.5781 26.9007 32.5781 27.432 32.2552 27.7549C31.9323 28.0778 31.401 28.0778 31.0781 27.7549L29.1667 25.8434L27.2552 27.7549C26.9323 28.0778 26.401 28.0778 26.0781 27.7549C25.7552 27.432 25.7552 26.9007 26.0781 26.5778L27.9896 24.6663L26.0781 22.7549C25.7552 22.432 25.7552 21.9007 26.0781 21.5778C26.401 21.2549 26.9323 21.2549 27.2552 21.5778L29.1667 23.4893L31.0781 21.5778C31.401 21.2549 31.9323 21.2549 32.2552 21.5778C32.5781 21.9007 32.5781 22.432 32.2552 22.7549L30.3437 24.6663Z"
      fill="currentColor"
    />
  </svg>
);
