import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { Modal } from "components/Modal/Modal";
import { formatDate } from "utils/formatDate";
import { SER } from "utils/serverErrorHandler";
import { ItemUser } from "components/Item/ItemUser";
import { ItemLicense } from "components/Item/ItemLicense";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { ButtonIcon } from "components/ButtonIcon";
import { PlusIcon } from "icons/PlusIcon";
import { Id, License, Group, User } from "types";

type FormEventValues = {
  name: { value: string };
};

interface ModalManageGroupProps {
  labId: Id;
  group: Group | null;
  hideModal: () => void;
}

export const ModalManageGroup: React.FC<ModalManageGroupProps> = ({
  labId,
  group,
  hideModal,
}) => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const [labUsers, setLabUsers] = useState<User[]>([]);
  const [selectedLicenseIds, setSelectedLicenseIds] = useState<Id[]>(
    group?.licenses.map(({ id }) => id) || []
  );
  const [selectedLabUserIds, setSelectedLabUserIds] = useState<Id[]>(
    group?.members.map(({ id }) => id) || []
  );

  const formRef = useRef<HTMLFormElement>(null);

  const { t } = useTranslation();

  const getData = useCallback(() => {
    SER(async () => {
      const [{ data: licenses }, { data: labUsers }] = await Promise.all([
        serverApi.getLicenses(labId),
        serverApi.getLabUsers(labId),
      ]);

      setLicenses(licenses.data);
      setLabUsers(labUsers.data);
    });
  }, [labId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      const target = event.target as typeof event.target & FormEventValues;

      const data = {
        name: target.name.value,
        members: selectedLabUserIds,
        licenses: selectedLicenseIds,
      };

      SER(async () => {
        if (group) {
          await serverApi.updateGroup(labId, group.id, data);
          toast.success(t("message.group_updated"));
        } else {
          await serverApi.createGroup(labId, data);
          toast.success(t("message.group_added"));
        }

        hideModal();
      });
    },
    [t, labId, group, selectedLabUserIds, selectedLicenseIds, hideModal]
  );

  const selectedLabUsers = useMemo(
    () => labUsers.filter(({ id }) => selectedLabUserIds.includes(id)),
    [labUsers, selectedLabUserIds]
  );

  const notSelectedLabUsers = useMemo(
    () => labUsers.filter(({ id }) => !selectedLabUserIds.includes(id)),
    [labUsers, selectedLabUserIds]
  );

  const selectLabUser = useCallback(
    (userId: Id) => {
      setSelectedLabUserIds([...new Set([...selectedLabUserIds, userId])]);
    },
    [selectedLabUserIds]
  );

  const deselectLabUser = useCallback(
    (userId: Id) => {
      setSelectedLabUserIds(selectedLabUserIds.filter((id) => id !== userId));
    },
    [selectedLabUserIds]
  );

  const selectedLicenses = useMemo(
    () => licenses.filter(({ id }) => selectedLicenseIds.includes(id)),
    [licenses, selectedLicenseIds]
  );

  const notSelectedLicenses = useMemo(
    () => licenses.filter(({ id }) => !selectedLicenseIds.includes(id)),
    [licenses, selectedLicenseIds]
  );

  const selectLicense = useCallback(
    (license: License) => {
      setSelectedLicenseIds([...new Set([...selectedLicenseIds, license.id])]);
    },
    [selectedLicenseIds]
  );

  const deselectLicense = useCallback(
    (license: License) => {
      setSelectedLicenseIds(
        selectedLicenseIds.filter((id) => id !== license.id)
      );
    },
    [selectedLicenseIds]
  );

  const titleComponent = useCallback(
    (title: string) => (
      <h2 className="my-6 text-white font-bold text-base leading-5">{title}</h2>
    ),
    []
  );

  const emptyComponent = useCallback(
    (title: string) => (
      <div className="h-[64px] border border-dashed border-gray3 flex items-center justify-center rounded-lg">
        <span className="text-gray6 text-sm font-normal">{title}</span>
      </div>
    ),
    []
  );

  const userComponent = useCallback(
    (labUser: User, isSelected: boolean) => (
      <ItemUser
        key={labUser.id}
        user={labUser}
        isShowPlus={!isSelected}
        isShowClose={isSelected}
        onClick={() =>
          isSelected ? deselectLabUser(labUser.id) : selectLabUser(labUser.id)
        }
      />
    ),
    [selectLabUser, deselectLabUser]
  );

  const licenseComponent = useCallback(
    (license: License, isSelected: boolean) => (
      <div key={license.id} className="h-[64px] flex items-center">
        <div className="w-2/12">
          <span className="text-white text-sm font-normal">
            {license.software.name}
          </span>
        </div>

        <div className="w-6/12 flex px-4">
          <ItemLicense license={license} />
        </div>
        <div className="w-2/12 flex px-4">
          <span className="text-white text-sm font-normal">
            {formatDate.toLocaleDateString(license.end_date) ||
              t("common.unlimited")}
          </span>
        </div>
        <div className="w-2/12 flex justify-end">
          {isSelected ? (
            <ButtonIcon size="sm" onClick={() => deselectLicense(license)} />
          ) : (
            <ButtonIcon
              size="sm"
              Icon={PlusIcon}
              onClick={() => selectLicense(license)}
            />
          )}
        </div>
      </div>
    ),
    [t, selectLicense, deselectLicense]
  );

  return (
    <Modal
      title={group ? t("common.update_group") : t("common.add_group")}
      footer={
        <div className="flex flex-1 justify-end">
          <Button type="transparent" onClick={hideModal}>
            {t("common.cancel")}
          </Button>
          <Button onClick={() => formRef.current?.requestSubmit()}>
            {group ? t("common.update") : t("common.add")}
          </Button>
        </div>
      }
    >
      <form ref={formRef} onSubmit={onSubmit}>
        <div className="px-8 py-6 sm:px-6 sm:py-4 border-b border-gray3">
          <Input
            name="name"
            placeholder={t("common.name")}
            defaultValue={group?.name}
          />
        </div>

        <div className="px-8 sm:px-6">
          {titleComponent(t("common.licenses"))}

          {selectedLicenses.length
            ? selectedLicenses.map((license) => licenseComponent(license, true))
            : emptyComponent(t("common.add_license_warning"))}
        </div>

        {notSelectedLicenses.length ? (
          <div className="mt-6 px-8 py-6 sm:px-6 sm:py-4 bg-gray3">
            {notSelectedLicenses.map((license) =>
              licenseComponent(license, false)
            )}
          </div>
        ) : null}

        <div className="mb-6 px-8 sm:mb-4 sm:px-6">
          {titleComponent(t("common.members"))}

          {selectedLabUsers.length ? (
            <div className="flex flex-wrap gap-x-4 gap-y-2">
              {selectedLabUsers.map((labUser) => userComponent(labUser, true))}
            </div>
          ) : (
            emptyComponent(t("common.add_member_warning"))
          )}
        </div>

        {!notSelectedLabUsers.length ? null : (
          <div className="px-8 py-6 sm:px-6 sm:py-4 bg-gray3 flex flex-wrap gap-x-4 gap-y-2">
            {notSelectedLabUsers.map((labUser) =>
              userComponent(labUser, false)
            )}
          </div>
        )}
      </form>
    </Modal>
  );
};
