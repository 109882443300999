import React, { useMemo } from "react";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";

import { convertTime } from "utils/convertTime";
import { TemperatureCurve } from "types";

const lineChartMargin = { top: 48, right: 48, left: 48, bottom: 48 };
const axisProps = {
  stroke: "#fff",
  axisLine: false,
  tickLine: false,
  tickMargin: 24,
};
const lineProps = {
  dataKey: "temperature",
  dot: false,
  strokeWidth: 3,
};

const xAxisTicksCount = 7;
const minTimeValue = 30;

const getMaxValue = (
  temperatureCurve: TemperatureCurve,
  minValue: number,
  key: "t" | "temperature"
) => {
  const value = Math.max(
    ...[...temperatureCurve.current, ...temperatureCurve.desired].map(
      (item) => item[key]
    )
  );

  return Math.max(minValue, value);
};

const getMaxTimeValue = (data: TemperatureCurve) => {
  const verticalLinesCommonDivisor = minTimeValue * xAxisTicksCount;

  const maxTimeValue = getMaxValue(data, verticalLinesCommonDivisor, "t");

  return (
    maxTimeValue +
    verticalLinesCommonDivisor -
    (maxTimeValue % verticalLinesCommonDivisor || verticalLinesCommonDivisor)
  );
};

const getGridValues = (maxValue: number, length: number) =>
  new Array(length + 1).fill(null).map((_, i) => (maxValue / length) * i);

const tickFormatter = (t: number) => convertTime(t * 60).minutes;

interface ChartProps {
  temperatureCurve: TemperatureCurve;
}

export const Chart: React.FC<ChartProps> = ({ temperatureCurve }) => {
  const xAxisTicks = useMemo(
    () => getGridValues(getMaxTimeValue(temperatureCurve), xAxisTicksCount),
    [temperatureCurve]
  );

  return (
    <LineChart
      width={800}
      height={400}
      className="bg-gray3 rounded-lg"
      margin={lineChartMargin}
    >
      <CartesianGrid vertical={false} strokeDasharray="10 7" stroke="#595959" />
      <XAxis
        ticks={xAxisTicks}
        tickFormatter={tickFormatter}
        scale="time"
        type="number"
        dataKey="t"
        {...axisProps}
      />
      <YAxis {...axisProps} />

      <Line data={temperatureCurve.desired} stroke="#fff" {...lineProps} />
      <Line data={temperatureCurve.current} stroke="red" {...lineProps} />
    </LineChart>
  );
};
