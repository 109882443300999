import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { getProfilePicture } from "utils/getProfilePicture";
import { SER } from "utils/serverErrorHandler";
import { Modal } from "components/Modal/Modal";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { ButtonIcon } from "components/ButtonIcon";
import { Image } from "components/Image";
import { CheckIcon } from "icons/CheckIcon";
import { Contact, Id } from "types";

interface ModalNewConnectionProps {
  labId: Id;
  contactsPending: Contact[];
  contactsOutgoing: Contact[];
  hideModal: () => void;
}

type FormEventValues = {
  name: { value: string };
};

export const ModalNewConnection: React.FC<ModalNewConnectionProps> = ({
  labId,
  hideModal,
  ...props
}) => {
  const [contactsPending, setContactsPending] = useState<Contact[]>(
    props.contactsPending
  );
  const [contactsOutgoing, setContactsOutgoing] = useState<Contact[]>(
    props.contactsOutgoing
  );

  const { t } = useTranslation();

  const getList = useCallback(() => {
    SER(async () => {
      const [{ data: contactsPending }, { data: contactsOutgoing }] =
        await Promise.all([
          serverApi.getContactsPending(labId),
          serverApi.getContactsOutgoing(labId),
        ]);

      setContactsPending(contactsPending.data);
      setContactsOutgoing(contactsOutgoing.data);
    });
  }, [labId]);

  useEffect(() => {
    getList();
  }, [getList]);

  const declineContactPending = useCallback(
    (contactId: Id) => {
      SER(async () => {
        await serverApi.declineContactPending(contactId);
        setContactsPending(
          contactsPending.filter(({ id }) => id !== contactId)
        );
        toast.success(t("message.connection_rejected"));
      });
    },
    [t, contactsPending]
  );

  const acceptContactPending = useCallback(
    (contactId: Id) => {
      SER(async () => {
        await serverApi.acceptContactPending(contactId);
        setContactsPending(
          contactsPending.filter(({ id }) => id !== contactId)
        );
        toast.success(t("message.connection_accepted"));
      });
    },
    [t, contactsPending]
  );

  const deleteContactOutgoing = useCallback(
    (contactId: Id) => {
      SER(async () => {
        await serverApi.deleteContactOutgoing(contactId);
        setContactsOutgoing(
          contactsOutgoing.filter(({ id }) => id !== contactId)
        );
        toast.success(t("message.connection_deleted"));
      });
    },
    [t, contactsOutgoing]
  );

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      const target = event.target as typeof event.target & FormEventValues;

      SER(async () => {
        const { data: contactOutgoing } =
          await serverApi.requestContactOutgoing(labId, target.name.value);
        setContactsOutgoing([...contactsOutgoing, contactOutgoing.data]);
        toast.success(t("message.invitation_created"));
      });
    },
    [t, labId, contactsOutgoing]
  );

  return (
    <Modal title={t("common.connect_laboratory")} hideModal={hideModal}>
      {contactsPending.length ? (
        <div className="px-8 py-6 sm:px-6 sm:py-4 border-b border-gray3 bg-darkGreen">
          <h2 className="text-base text-white font-bold leading-5">
            {t("common.connection_request")}
          </h2>

          {contactsPending.map((item) => (
            <div
              key={item.id}
              className="mt-6 h-[40px] flex items-center justify-between"
            >
              <div className="flex items-center">
                <Image
                  rounded="lg"
                  size={40}
                  iconSize={20}
                  src={getProfilePicture(item)}
                />

                <div className="ml-4 flex flex-col">
                  <span className="text-base font-semibold text-white leading-5">
                    {item.name}
                  </span>
                  <span className="text-base font-normal text-gray6 leading-5">
                    {item.realm}
                  </span>
                </div>
              </div>

              <div className="flex">
                <ButtonIcon
                  size="sm"
                  onClick={() => declineContactPending(item.id)}
                />

                <div
                  className="ml-4 text-white w-[48px] h-[32px] rounded-full bg-green flex items-center justify-center cursor-pointer"
                  onClick={() => acceptContactPending(item.id)}
                >
                  <CheckIcon />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}

      <div className="px-8 py-6 sm:px-6 sm:py-4">
        <form className="flex" onSubmit={onSubmit}>
          <Input
            buttonOneLine
            name="name"
            placeholder={t("common.type_lab_realm")}
          />
          <Button>{t("common.send_request")}</Button>
        </form>

        {contactsOutgoing.length ? (
          <h2 className="mt-6 text-base text-white font-bold leading-5">
            {t("common.requests_pending")}
          </h2>
        ) : null}

        {contactsOutgoing.map((item) => (
          <div key={item.id} className="mt-4 flex items-center justify-between">
            <div className="flex items-center">
              <Image
                rounded="lg"
                size={40}
                iconSize={20}
                src={getProfilePicture(item)}
              />

              <div className="ml-4 flex flex-col">
                <span className="text-base font-semibold text-white leading-5">
                  {item.name}
                </span>
                <span className="text-base font-normal text-gray6 leading-5">
                  {item.realm}
                </span>
              </div>
            </div>

            <ButtonIcon
              size="sm"
              onClick={() => deleteContactOutgoing(item.id)}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};
