import { serverInstance as api } from "api/instances";
import { URL as ENV_URL } from "constants/ENV";
import { createUrlencoded } from "utils/createUrlencoded";
import { Id, Invitation } from "types";

const URL = "lab/invitation";

export const getLabInvitationList = (labId: Id) =>
  api.get<{ data: Invitation[] }>(`${URL}/${labId}/invitations`);

export const createLabEmailInvitation = (labId: Id, email: string) =>
  api.request({
    method: "post",
    url: `${URL}/${labId}/invite`,
    data: createUrlencoded({ url: ENV_URL.INVITATION, email }),
  });

export const acceptLabInvitation = (uuid: string) =>
  api.get(`${URL}/uuid/${uuid}`);

export const revokeLabInvitation = (uuid: string) =>
  api.delete(`${URL}/uuid/${uuid}`);
