import React, { useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { SER } from "utils/serverErrorHandler";
import { Modal } from "components/Modal/Modal";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { License } from "types";

interface ModalEditLicenseNameProps {
  license: License;
  hideModal: () => void;
}

type FormEventValues = {
  name: { value: string };
};

export const ModalEditLicenseName: React.FC<ModalEditLicenseNameProps> = ({
  license,
  hideModal,
}) => {
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      const target = event.target as typeof event.target & FormEventValues;

      SER(async () => {
        await serverApi.updateLicenseName(license.lab_id, license.id, {
          name: target.name.value,
        });
        toast.success(t("message.license_name_updated"));
        hideModal();
      });
    },
    [t, license, hideModal]
  );

  return (
    <Modal
      title={t("common.edit_name")}
      footer={
        <div className="flex flex-1 justify-end">
          <Button type="transparent" onClick={hideModal}>
            {t("common.cancel")}
          </Button>
          <Button onClick={() => formRef.current?.requestSubmit()}>
            {t("common.save_changes")}
          </Button>
        </div>
      }
    >
      <div className="px-8 py-6 sm:px-6 sm:py-4">
        <form ref={formRef} className="flex" onSubmit={onSubmit}>
          <Input
            name="name"
            placeholder={t("common.license_name")}
            defaultValue={license.name}
          />
        </form>
      </div>
    </Modal>
  );
};
