const addZero = (value: number) => `${value < 10 ? "0" : ""}${value}`;

export const convertTime = (seconds: number) => {
  const d = new Date(seconds * 1000);

  const h = addZero(d.getUTCHours());
  const m = addZero(d.getUTCMinutes());
  const s = addZero(d.getUTCSeconds());

  return {
    seconds: `${h}:${m}:${s}`,
    minutes: `${h}:${m}`,
  };
};
