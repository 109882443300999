import React from "react";

import { getProfilePicture } from "utils/getProfilePicture";
import { Image } from "components/Image";
import { License } from "types";

interface ItemLicenseProps {
  license: License;
  showSoftwareName?: boolean;
}

export const ItemLicense: React.FC<ItemLicenseProps> = ({
  license,
  showSoftwareName,
}) => (
  <div
    className="h-[48px] sm:h-[42px] rounded-lg px-2 border flex items-center"
    style={{ borderColor: `#${license.software.color}` }}
  >
    <Image
      rounded="lg"
      size={32}
      iconSize={20}
      src={getProfilePicture({ profile_picture: license.software.picture })}
    />

    <h2 className="ml-2 text-white text-base sm:text-sm font-normal whitespace-nowrap overflow-hidden">
      {showSoftwareName ? license.software.name : license.name}
    </h2>
  </div>
);
