import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { serverApi } from "api";
import { useMenuActions } from "hooks/useMenuActions";
import { SER } from "utils/serverErrorHandler";
import { ModalConfirmation } from "components/Modal/ModalConfirmation";
import { DropdownMenu } from "components/DropdownMenu/DropdownMenu";
import { ItemLab } from "components/Item/ItemLab";
import { ThreeDotsButton } from "components/ThreeDotsButton";
import { Contact } from "types";

interface DropdownMenuContactProps {
  contact: Contact;
  disconnectContactCB: () => void;
}

export const DropdownMenuContact: React.FC<DropdownMenuContactProps> = ({
  contact,
  disconnectContactCB,
}) => {
  const [isModalConfirmationDisconnect, setIsModalConfirmationDisconnect] =
    useState(false);

  const { t } = useTranslation();

  const { MENU_ACTIONS } = useMenuActions();

  const showModalConfirmationDisconnect = useCallback(() => {
    setIsModalConfirmationDisconnect(true);
  }, []);

  const hideModalConfirmationDisconnect = useCallback(() => {
    setIsModalConfirmationDisconnect(false);
  }, []);

  const disconnectContact = useCallback(() => {
    SER(async () => {
      await serverApi.deleteContact(contact.id);
      hideModalConfirmationDisconnect();

      disconnectContactCB();

      toast.success(t("message.disconnect_lab_success"));
    });
  }, [t, contact, hideModalConfirmationDisconnect, disconnectContactCB]);

  const menuOptions = useMemo(
    () => [MENU_ACTIONS.disconnectLaboratory(showModalConfirmationDisconnect)],
    [MENU_ACTIONS, showModalConfirmationDisconnect]
  );

  return (
    <>
      <DropdownMenu options={menuOptions}>
        <ThreeDotsButton />
      </DropdownMenu>

      {isModalConfirmationDisconnect && (
        <ModalConfirmation
          type="disconnect"
          title={t("common.disconnect_lab")}
          text={t("common.modal_confirmation_lab_delete")}
          hideModal={hideModalConfirmationDisconnect}
          onClick={disconnectContact}
        >
          <ItemLab lab={contact} />
        </ModalConfirmation>
      )}
    </>
  );
};
