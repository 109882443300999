import React from "react";

import { IconProps } from "./types";

export const SuccessIcon: React.FC<IconProps> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      d="M9.91181 5.55138C10.1044 5.28127 10.4791 5.21945 10.7488 5.41164C11.0185 5.60442 11.0809 5.97912 10.8883 6.24865L7.88826 10.4487C7.88664 10.4509 7.88472 10.4528 7.88278 10.4547C7.88097 10.4564 7.87916 10.4582 7.8776 10.4603C7.85739 10.4871 7.83399 10.5117 7.80883 10.5353C7.8052 10.5387 7.80176 10.5424 7.79833 10.546C7.79335 10.5513 7.78838 10.5565 7.78288 10.5611L7.78056 10.5634L7.7781 10.5658C7.74654 10.5915 7.71285 10.6123 7.67819 10.6305C7.6723 10.6336 7.66628 10.6362 7.66024 10.6387C7.65672 10.6402 7.65319 10.6417 7.64968 10.6433C7.61926 10.6573 7.58832 10.6686 7.55657 10.6772C7.55427 10.6778 7.55199 10.6784 7.54971 10.6791C7.54128 10.6814 7.53292 10.6837 7.52432 10.6854C7.48329 10.6942 7.44189 10.7 7.40033 10.7H7.3996L7.3978 10.6997C7.3443 10.6995 7.29166 10.6899 7.24 10.6756C7.22701 10.672 7.21457 10.6674 7.20188 10.6628C7.20008 10.6621 7.19829 10.6614 7.19648 10.6608C7.15381 10.6454 7.11322 10.6252 7.07449 10.6C7.07098 10.5977 7.06722 10.5961 7.06347 10.5946C7.05928 10.5929 7.0551 10.5911 7.05126 10.5884C7.04942 10.5871 7.04783 10.5855 7.04624 10.584C7.04475 10.5826 7.04327 10.5812 7.04157 10.5799C7.034 10.5742 7.027 10.5677 7.01998 10.5612C7.01607 10.5576 7.01216 10.554 7.00814 10.5505C6.9858 10.531 6.96438 10.5112 6.94557 10.4892C6.94386 10.4872 6.94192 10.4855 6.93999 10.4838C6.93792 10.482 6.93586 10.4801 6.93408 10.4779L5.50058 8.71134C5.29184 8.45412 5.33124 8.07618 5.58847 7.86759C5.84555 7.6587 6.22362 7.69826 6.43251 7.95548L7.36967 9.1104L9.91181 5.55138Z"
      fill="currentColor"
    />
    <path
      d="M2 8C2 4.6915 4.69165 2 8 2C11.3083 2 14 4.6915 14 8C14 11.3085 11.3083 14 8 14C4.69165 14 2 11.3085 2 8ZM3.2 8C3.2 10.6467 5.35332 12.8 8 12.8C10.6467 12.8 12.8 10.6467 12.8 8C12.8 5.35332 10.6467 3.2 8 3.2C5.35332 3.2 3.2 5.35332 3.2 8Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
