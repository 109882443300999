import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "components/Modal/Modal";
import { ItemUser } from "components/Item/ItemUser";
import { User } from "types";

interface ModalMembersProps {
  users: User[];
  hideModal: () => void;
}

export const ModalMembers: React.FC<ModalMembersProps> = ({
  users,
  hideModal,
}) => {
  const { t } = useTranslation();

  return (
    <Modal title={t("common.members")} hideModal={hideModal}>
      <div className="px-8 py-6 sm:px-6">
        <div className="flex flex-wrap gap-4">
          {users.map((user) => (
            <ItemUser key={user.id} user={user} />
          ))}
        </div>
      </div>
    </Modal>
  );
};
