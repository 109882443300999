import React from "react";

import { IconProps } from "./types";

export const ProcessesEmptyIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none">
    <path
      d="M20 23.3333C23.6392 23.3333 27.0151 22.6484 29.5437 21.4684C31.0385 20.7708 32.4696 19.7774 33.3334 18.4857V22.5C33.3334 25.0833 31.2813 26.9687 28.9948 28.1146C26.6094 29.3073 23.4219 30 20 30C16.5781 30 13.3906 29.3073 11.0052 28.1146C8.71877 26.9687 6.66669 25.0833 6.66669 22.5V18.4857C7.53046 19.7774 8.96155 20.7708 10.4564 21.4684C12.9849 22.6484 16.3609 23.3333 20 23.3333Z"
      fill="currentColor"
    />
    <path
      d="M32.5 15.8333C32.5 19.055 26.9036 21.6667 20 21.6667C13.0965 21.6667 7.50002 19.055 7.50002 15.8333C7.50002 12.6117 13.0965 10 20 10C26.9036 10 32.5 12.6117 32.5 15.8333Z"
      fill="currentColor"
    />
  </svg>
);
