import { authInstance as api } from "api/instances";
import { URL, TOKEN } from "constants/ENV";
import { LSAccessToken, LSRefreshToken } from "utils/LocalStorage";
import { createUrlencoded } from "utils/createUrlencoded";

type GetAccessToken = (code: string) => Promise<boolean>;
type RefreshAccessToken = () => Promise<string | undefined>;

export const login = () => {
  window.location.href = `${api.getUri()}/auth?${createUrlencoded({
    client_id: TOKEN.CLIENT_ID,
    response_type: "code",
    scope: "openid profile refresh_token",
    redirect_uri: URL.CALLBACK,
  })}`;
};

export const logout = () => {
  window.location.href = `${api.getUri()}/session/end?${createUrlencoded({
    client_id: TOKEN.CLIENT_ID,
    post_logout_redirect_uri: URL.LOGOUT,
  })}`;
};

export const getAccessToken: GetAccessToken = async (code) => {
  const { status, data } = await api.request({
    method: "post",
    url: "token",
    data: createUrlencoded({
      code,
      grant_type: "authorization_code",
      client_id: TOKEN.CLIENT_ID,
      client_secret: TOKEN.CLIENT_SECRET,
      redirect_uri: URL.CALLBACK,
    }),
  });

  if (status === 200) {
    LSAccessToken.set(data.access_token);
    LSRefreshToken.set(data.refresh_token);

    return true;
  }

  return false;
};

export const refreshAccessToken: RefreshAccessToken = async () => {
  try {
    const { status, data } = await api.request({
      method: "post",
      url: "token",
      data: createUrlencoded({
        grant_type: "refresh_token",
        client_id: TOKEN.CLIENT_ID,
        client_secret: TOKEN.CLIENT_SECRET,
        refresh_token: LSRefreshToken.get()!,
      }),
    });

    if (status === 200) return data.access_token;

    throw new Error("Failed to refresh access token");
  } catch (err) {
    clearLocalStorage();
    login();
  }
};

export const clearLocalStorage = () => {
  LSAccessToken.remove();
  LSRefreshToken.remove();
};
