import React from "react";

import { IconProps } from "./types";

export const CheckboxIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_8314_3549)">
      <circle cx="12" cy="12" r="12" fill="#00714F" />
      <path
        d="M17.297 9.79678L11.297 15.7968C10.8563 16.2374 10.1438 16.2374 9.70791 15.7968L6.70791 12.7968C6.26729 12.3562 6.26729 11.6437 6.70791 11.2077C7.14854 10.7718 7.86103 10.7671 8.29697 11.2077L10.5001 13.4108L15.7032 8.20303C16.1438 7.7624 16.8563 7.7624 17.2923 8.20303C17.7282 8.64365 17.7329 9.35615 17.2923 9.79209L17.297 9.79678Z"
        fill="white"
      />
    </g>
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      rx="11.5"
      stroke="white"
      strokeOpacity="0.1"
    />
    <defs>
      <clipPath id="clip0_8314_3549">
        <rect width="24" height="24" rx="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
