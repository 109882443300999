import { serverInstance as api } from "api/instances";
import { createUrlencoded } from "utils/createUrlencoded";
import { Id, User } from "types";

export type UpdateLabUsersData = { members: Id[]; groups: Id[]; role?: Id };

const URL = "lab/users";

export const getLabUsers = (labId: Id) =>
  api.get<{ data: User[] }>(`${URL}/${labId}`);

export const getLabUsersSummary = (labId: Id) =>
  api.get<{ data: User[] }>(`${URL}/${labId}/summary`);

export const updateLabUsers = (labId: Id, data: UpdateLabUsersData) =>
  api.request({
    method: "patch",
    url: `${URL}/edit/${labId}`,
    data: createUrlencoded(data),
  });

export const removeLabUser = (labId: Id, userId: Id) =>
  api.delete(`${URL}/remove/${labId}/${userId}`);
