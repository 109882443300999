import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { serverApi } from "api";
import { useMenuActions } from "hooks/useMenuActions";
import { useTypedOutletContext } from "hooks/useTypedOutletContext";
import { SER } from "utils/serverErrorHandler";
import { getDeviceDetailsRoute } from "utils/getDeviceDetailsRoute";
import { ModalConfirmation } from "components/Modal/ModalConfirmation";
import { DropdownMenu } from "components/DropdownMenu/DropdownMenu";
import { ThreeDotsButton } from "components/ThreeDotsButton";
import { Device } from "types";

interface DropdownMenuDeviceProps {
  labId: number;
  device: Device;
  removeDeviceCB: () => void;
}

export const DropdownMenuDevice: React.FC<DropdownMenuDeviceProps> = ({
  labId,
  device,
  removeDeviceCB,
}) => {
  const [isModalConfirmation, setIsModalConfirmation] = useState(false);

  const { t } = useTranslation();

  const { MENU_ACTIONS } = useMenuActions();
  const { permissions } = useTypedOutletContext();

  const openDevicePage = useCallback(() => {
    window.open(getDeviceDetailsRoute(device), "_blank");
  }, [device]);

  const showModalConfirmation = useCallback(() => {
    setIsModalConfirmation(true);
  }, []);

  const hideModalConfirmation = useCallback(() => {
    setIsModalConfirmation(false);
  }, []);

  const removeDevice = useCallback(() => {
    SER(async () => {
      await serverApi.removeLabMachine(labId, device.unitKey);
      hideModalConfirmation();

      removeDeviceCB();

      toast.success(t("message.device_remove"));
    });
  }, [t, labId, device, hideModalConfirmation, removeDeviceCB]);

  const menuOptions = useMemo(() => {
    const options = [MENU_ACTIONS.openDevicePage(openDevicePage)];

    if (permissions.devices) {
      options.push(MENU_ACTIONS.removeDevice(showModalConfirmation));
    }

    return options;
  }, [MENU_ACTIONS, permissions, openDevicePage, showModalConfirmation]);

  return (
    <>
      <DropdownMenu options={menuOptions}>
        <ThreeDotsButton />
      </DropdownMenu>

      {isModalConfirmation && (
        <ModalConfirmation
          type="remove"
          title={t("common.remove_device")}
          text={t("common.modal_confirmation_device")}
          hideModal={hideModalConfirmation}
          onClick={removeDevice}
        />
      )}
    </>
  );
};
