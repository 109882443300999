import { serverInstance as api } from "api/instances";
import type { Message } from "types";

type MessagesUnreadCount = Record<string, number>;

const URL = "e/notification/v8/mydevices";

export const getDeviceMessages = (unitKey: string) =>
  api.get<Message[]>(`${URL}/messages`, { params: { unitKey, visible: true } });

export const getMessagesUnreadCount = (unitKeys: string[]) =>
  api.get<MessagesUnreadCount>(`${URL}/messagesUnreadCount`, {
    params: { unitKeys },
  });

export const setDeviceMessagesAsRead = (unitKey: string) =>
  api.patch(`${URL}/messages/setRead`, { unitKey });
