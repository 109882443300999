import React from "react";

import { IconProps } from "./types";

export const CheckIcon: React.FC<IconProps> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7 3.12734C14.0314 3.37587 14.0985 3.84596 13.85 4.17733L6.69096 13.7227C6.55527 13.9037 6.34519 14.0137 6.1192 14.0222C5.89322 14.0307 5.67545 13.9368 5.52653 13.7666L2.18564 9.94846C1.91288 9.63673 1.94447 9.16291 2.25619 8.89015C2.56792 8.61739 3.04174 8.64898 3.3145 8.96071L6.04611 12.0826L12.65 3.27734C12.8985 2.94597 13.3686 2.87881 13.7 3.12734Z"
      fill="currentColor"
    />
  </svg>
);
