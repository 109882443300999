import React, { useMemo } from "react";

interface CircleProgressBarProps {
  size: number;
  progress: number;
  strokeWidth?: number;
  strokeColor?: string;
}

export const CircleProgressBar: React.FC<CircleProgressBarProps> = ({
  size,
  progress,
  strokeWidth = 8,
  strokeColor = "",
}) => {
  const r = useMemo(() => size / 2 - strokeWidth / 2, [size, strokeWidth]);
  const commonProps = useMemo(
    () => ({
      cx: size / 2,
      cy: size / 2,
      r,
      strokeWidth,
      stroke: "currentColor",
      fill: "transparent",
    }),
    [size, strokeWidth, r]
  );

  const strokeDasharray = useMemo(() => ((2 * 22) / 7) * r, [r]);
  const strokeDashoffset = useMemo(
    () => strokeDasharray - (progress / 100) * strokeDasharray,
    [strokeDasharray, progress]
  );

  return (
    <svg className="transform -rotate-90 aspect-square" style={{ width: size }}>
      <circle {...commonProps} className="text-gray3" />

      <circle
        {...commonProps}
        strokeLinecap="round"
        strokeDasharray={strokeDasharray}
        strokeDashoffset={strokeDashoffset}
        style={{ color: strokeColor }}
      />
    </svg>
  );
};
