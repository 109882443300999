import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import { getUniqueArray } from "utils/getUniqueArray";
import { Modal } from "components/Modal/Modal";
import { Button } from "components/Button";
import { ItemRoleGroup } from "components/Item/ItemRoleGroup";
import { Id, User } from "types";

interface ModalMembersFilterProps {
  labUsers: User[];
  selectedGroups: Id[];
  selectedRoles: Id[];
  setSelectedGroups: Dispatch<SetStateAction<Id[]>>;
  setSelectedRoles: Dispatch<SetStateAction<Id[]>>;
  hideModal: () => void;
}

export const ModalMembersFilter: React.FC<ModalMembersFilterProps> = ({
  labUsers,
  hideModal,
  ...props
}) => {
  const [selectedRoles, setSelectedRoles] = useState<Id[]>(props.selectedRoles);
  const [selectedGroups, setSelectedGroups] = useState<Id[]>(
    props.selectedGroups
  );

  const formRef = useRef<HTMLFormElement>(null);

  const { t } = useTranslation();

  const roles = useMemo(
    () =>
      getUniqueArray(
        labUsers.map((user) => user.role),
        "id"
      ),
    [labUsers]
  );

  const groups = useMemo(
    () => getUniqueArray(labUsers.map((user) => user.groups).flat(), "id"),
    [labUsers]
  );

  const onSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      props.setSelectedRoles(selectedRoles);
      props.setSelectedGroups(selectedGroups);

      hideModal();
    },
    [props, selectedRoles, selectedGroups, hideModal]
  );

  const selectRole = useCallback(
    (roleId: Id) => {
      if (selectedRoles.includes(roleId)) {
        setSelectedRoles(selectedRoles.filter((id) => id !== roleId));
      } else {
        setSelectedRoles([...selectedRoles, roleId]);
      }
    },
    [selectedRoles, setSelectedRoles]
  );

  const selectGroup = useCallback(
    (groupId: Id) => {
      if (selectedGroups.includes(groupId)) {
        setSelectedGroups(selectedGroups.filter((id) => id !== groupId));
      } else {
        setSelectedGroups([...selectedGroups, groupId]);
      }
    },
    [selectedGroups, setSelectedGroups]
  );

  const clearFilter = useCallback(() => {
    setSelectedRoles([]);
    setSelectedGroups([]);
  }, [setSelectedRoles, setSelectedGroups]);

  return (
    <Modal
      title={t("common.filters")}
      header={
        <Button type="clear" onClick={clearFilter}>
          {t("common.clear_all")}
        </Button>
      }
      footer={
        <div className="flex flex-1 justify-end">
          <Button type="transparent" onClick={hideModal}>
            {t("common.cancel")}
          </Button>
          <Button onClick={() => formRef.current?.requestSubmit()}>
            {t("common.apply")}
          </Button>
        </div>
      }
    >
      <form ref={formRef} onSubmit={onSubmit}>
        <div className="px-8 py-6 sm:px-6 sm:py-4">
          <h2 className="text-base text-white font-bold leading-5">
            {t("common.roles")}
          </h2>

          <div className="flex flex-wrap mt-6 sm:mt-4 gap-x-4 gap-y-2 max-w-[680px]">
            {roles.map((role) => (
              <ItemRoleGroup
                key={role.id}
                title={role.name}
                isActive={selectedRoles.includes(role.id)}
                onClick={() => selectRole(role.id)}
              />
            ))}
          </div>
        </div>

        {groups.length ? (
          <div className="px-8 py-6 sm:px-6 sm:py-4 border-t border-gray3">
            <h2 className="text-base text-white font-bold leading-5">
              {t("common.groups")}
            </h2>

            <div className="flex flex-wrap mt-6 sm:mt-4 gap-x-4 gap-y-2 max-w-[680px]">
              {groups.map((group) => (
                <ItemRoleGroup
                  key={group.id}
                  title={group.name}
                  isActive={selectedGroups.includes(group.id)}
                  onClick={() => selectGroup(group.id)}
                />
              ))}
            </div>
          </div>
        ) : null}
      </form>
    </Modal>
  );
};
