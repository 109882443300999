import React from "react";

import { IconProps } from "./types";

export const FilterIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M4.12183 5.71563C4.32808 5.27813 4.76558 5 5.24996 5H18.75C19.2343 5 19.6718 5.27813 19.8781 5.71563C20.0843 6.15313 20.0218 6.66875 19.7156 7.04375L14 14.0281V18C14 18.3781 13.7875 18.725 13.4468 18.8938C13.1062 19.0625 12.7031 19.0281 12.4 18.8L10.4 17.3C10.1468 17.1125 9.99996 16.8156 9.99996 16.5V14.0281L4.28121 7.04063C3.97808 6.66875 3.91246 6.15 4.12183 5.71563Z"
      fill="currentColor"
    />
  </svg>
);
