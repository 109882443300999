import React from "react";

import { IconProps } from "./types";

export const NetworkIcon: React.FC<IconProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 40 40" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6667 10.5003H23.3333V13.8337H16.6667V10.5003ZM15.8333 7.16699C14.4531 7.16699 13.3333 8.28678 13.3333 9.66699V14.667C13.3333 16.0472 14.4531 17.167 15.8333 17.167H18.3333V18.8337H4.99999C4.07812 18.8337 3.33333 19.5785 3.33333 20.5003C3.33333 21.4222 4.07812 22.167 4.99999 22.167H10V23.8337H7.5C6.11979 23.8337 4.99999 24.9535 4.99999 26.3337V31.3337C4.99999 32.7139 6.11979 33.8337 7.5 33.8337H15.8333C17.2135 33.8337 18.3333 32.7139 18.3333 31.3337V26.3337C18.3333 24.9535 17.2135 23.8337 15.8333 23.8337H13.3333V22.167H20.9995C21.6809 20.834 22.6792 19.6898 23.8949 18.8337H21.6667V17.167H24.1667C25.5469 17.167 26.6667 16.0472 26.6667 14.667V9.66699C26.6667 8.28678 25.5469 7.16699 24.1667 7.16699H15.8333ZM8.33333 30.5003V27.167H15V30.5003H8.33333ZM34.47 31.637C33.0634 33.0435 31.1558 33.8337 29.1667 33.8337C27.1775 33.8337 25.2699 33.0435 23.8634 31.637C22.4568 30.2304 21.6667 28.3228 21.6667 26.3337C21.6667 24.3445 22.4568 22.4369 23.8634 21.0304C25.2699 19.6238 27.1775 18.8337 29.1667 18.8337C31.1558 18.8337 33.0634 19.6238 34.47 21.0304C35.8765 22.4369 36.6667 24.3445 36.6667 26.3337C36.6667 28.3228 35.8765 30.2304 34.47 31.637ZM32.2552 28.2451L30.3437 26.3337L32.2552 24.4222C32.5781 24.0993 32.5781 23.568 32.2552 23.2451C31.9323 22.9222 31.401 22.9222 31.0781 23.2451L29.1667 25.1566L27.2552 23.2451C26.9323 22.9222 26.401 22.9222 26.0781 23.2451C25.7552 23.568 25.7552 24.0993 26.0781 24.4222L27.9896 26.3337L26.0781 28.2451C25.7552 28.568 25.7552 29.0993 26.0781 29.4222C26.401 29.7451 26.9323 29.7451 27.2552 29.4222L29.1667 27.5107L31.0781 29.4222C31.401 29.7451 31.9323 29.7451 32.2552 29.4222C32.5781 29.0993 32.5781 28.568 32.2552 28.2451Z"
      fill="currentColor"
    />
  </svg>
);
