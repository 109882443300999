type GetImageDimension = (
  file: File
) => Promise<{ width: number; height: number; size: number }>;

export const getImageDimension: GetImageDimension = (file) =>
  new Promise((resolve) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = function () {
      const { width, height } = this as unknown as {
        width: number;
        height: number;
      };
      URL.revokeObjectURL(objectUrl);

      resolve({ width, height, size: file.size });
    };

    img.src = objectUrl;
  });
