import React, { useCallback, useEffect, useMemo, useState } from "react";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useAuthProvider } from "provider";
import { ROUTES } from "constants/routes";
import { SER } from "utils/serverErrorHandler";
import { UsersIcon } from "icons/UsersIcon";
import { DeviceIcon } from "icons/DeviceIcon";
import { SettingsIcon } from "icons/SettingsIcon";
import { ChevronLeftIcon } from "icons/ChevronLeftIcon";
import { Lab } from "types";

export const NavBar: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [lab, setLab] = useState<Lab | null>(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const { user } = useAuthProvider();

  const getLabs = useCallback(() => {
    if (!Boolean(user?.multilab)) {
      SER(async () => {
        const { data: labs } = await serverApi.getLabs();

        if (labs.data.length === 1) {
          setLab(labs.data[0]);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    getLabs();
  }, [getLabs]);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const renderButton = useCallback(
    ({
      title,
      Icon,
      isActive,
      nav,
    }: (typeof navItems)[keyof typeof navItems]) => (
      <button
        className={`flex flex-row w-full sm:w-[64px] h-[64px] items-center transition-all duration-200 ease-in-out hover:text-white hover:bg-[rgba(0,0,0,.5)] ${
          isActive ? "bg-black text-white" : "text-gray6"
        }`}
        onClick={nav}
      >
        <div className="min-w-[64px] flex justify-center">
          <Icon />
        </div>
        <span className="text-sm font-bold whitespace-nowrap overflow-hidden">
          {title}
        </span>
      </button>
    ),
    []
  );

  const navItems = useMemo(() => {
    const getActiveStatus = (route: ROUTES) =>
      route.split("/")[1] === pathname.split("/")[1];

    return {
      laboratories: {
        title: Boolean(user?.multilab)
          ? t("common.laboratories")
          : lab
          ? lab.name
          : t("common.laboratory"),
        Icon: UsersIcon,
        isActive: getActiveStatus(ROUTES.LABORATORIES),
        nav: () => {
          if (lab) {
            navigate(
              generatePath(ROUTES.LABORATORY_MEMBERS, {
                labId: lab.id.toString(),
              }),
              { state: lab }
            );
          } else {
            navigate(generatePath(ROUTES.LABORATORIES));
          }
        },
      },
      devices: {
        title: t("common.devices"),
        Icon: DeviceIcon,
        isActive: getActiveStatus(ROUTES.DEVICES),
        nav: () => {
          navigate(generatePath(ROUTES.DEVICES, { deviceType: "all" }));
        },
      },
      settings: {
        title: t("common.settings"),
        Icon: SettingsIcon,
        isActive: getActiveStatus(ROUTES.SETTINGS),
        nav: () => {
          navigate(generatePath(ROUTES.SETTINGS));
        },
      },
    };
  }, [t, user, lab, pathname, navigate]);

  return (
    <nav
      className="group flex flex-col justify-between bg-gray1 relative transition-all duration-200 ease-in-out xl:!w-[64px] xl:!min-w-[64px] sm:flex-row sm:!w-full sm:!min-w-full"
      style={{
        width: isCollapsed ? "64px" : "232px",
        minWidth: isCollapsed ? "64px" : "232px",
      }}
    >
      <div className="sm:flex">
        <div className="h-[64px] mb-[64px] relative sm:w-[64px] sm:mb-0">
          <div
            className="px-5 w-[232px] h-full flex items-center absolute top-0 transition-all duration-200 ease-in-out xl:!left-[-232px]"
            style={{ left: isCollapsed ? "-232px" : "0" }}
          >
            <img
              src={require("../assets/Logo-Expanded.png")}
              alt=""
              className="w-full"
            />
          </div>

          <img
            src={require("../assets/Logo-Collapsed.png")}
            alt=""
            className="h-full aspect-square absolute top-0 transition-all duration-200 ease-in-out xl:!left-0"
            style={{ left: isCollapsed ? "0" : "-64px" }}
          />
        </div>

        {renderButton(navItems.laboratories)}
        {renderButton(navItems.devices)}
      </div>

      {renderButton(navItems.settings)}

      <div
        className="w-[32px] h-[72px] rounded-lg rounded-l-none absolute bg-gray1 top-0 bottom-0 right-[-32px] mt-auto mb-auto text-gray6 flex items-center justify-center cursor-pointer invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-all duration-200 ease-in-out xl:hidden"
        onClick={toggleCollapse}
      >
        <div style={{ rotate: isCollapsed ? "180deg" : "0deg" }}>
          <ChevronLeftIcon size={16} />
        </div>
      </div>
    </nav>
  );
};
