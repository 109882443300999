import { LS_KEYS } from "constants/LSKeys";

export class LS {
  private _key;

  constructor(key: LS_KEYS) {
    this._key = key;
  }

  set = (value: string) => localStorage.setItem(this._key, value);

  get = () => localStorage.getItem(this._key);

  remove = () => localStorage.removeItem(this._key);
}
