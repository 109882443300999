import React from "react";

import { IconProps } from "./types";

export const TrashIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 26 26" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5684 6.79053H16.5684V5.29053C16.5684 3.90982 15.4491 2.79053 14.0684 2.79053H11.0684C9.68765 2.79053 8.56836 3.90982 8.56836 5.29053V6.79053H4.56836C4.01607 6.79053 3.56836 7.23824 3.56836 7.79053C3.56836 8.34281 4.01607 8.79053 4.56836 8.79053H6.10169L6.53614 21.8238C6.5541 22.3629 6.99627 22.7905 7.53558 22.7905H17.6011C18.1405 22.7905 18.5826 22.3629 18.6006 21.8238L19.035 8.79053H20.5684C21.1206 8.79053 21.5684 8.34281 21.5684 7.79053C21.5684 7.23824 21.1206 6.79053 20.5684 6.79053ZM10.5684 5.29053C10.5684 5.01438 10.7922 4.79053 11.0684 4.79053H14.0684C14.3445 4.79053 14.5684 5.01438 14.5684 5.29053V6.79053H10.5684V5.29053ZM16.5677 10.3155C16.5815 10.0397 16.3691 9.80494 16.0933 9.79115C15.8175 9.77736 15.5828 9.98976 15.569 10.2656L15.069 20.2656C15.0552 20.5414 15.2676 20.7761 15.5434 20.7899C15.8192 20.8037 16.0539 20.5913 16.0677 20.3155L16.5677 10.3155ZM9.04339 9.79115C8.76759 9.80494 8.55519 10.0397 8.56898 10.3155L9.06898 20.3155C9.08277 20.5913 9.31753 20.8037 9.59333 20.7899C9.86913 20.7761 10.0815 20.5414 10.0677 20.2656L9.56774 10.2656C9.55395 9.98976 9.31919 9.77736 9.04339 9.79115ZM13.0684 10.2905C13.0684 10.0144 12.8445 9.79053 12.5684 9.79053C12.2922 9.79053 12.0684 10.0144 12.0684 10.2905V20.2905C12.0684 20.5667 12.2922 20.7905 12.5684 20.7905C12.8445 20.7905 13.0684 20.5667 13.0684 20.2905V10.2905Z"
      fill="currentColor"
    />
  </svg>
);
