import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { authApi, serverApi } from "api";
import { getURLSearchParam } from "utils/getURLSearchParam";
import { LSAccessToken } from "utils/LocalStorage";
import { User } from "types";

type AuthProviderProps = {
  children: React.ReactNode;
};

interface AuthContextType {
  user: User | null | undefined;
  login: () => void;
  checkAuthCode: () => void;
  logout: () => void;
  setUser: React.Dispatch<React.SetStateAction<AuthContextType["user"]>>;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<AuthContextType["user"]>();

  const { i18n } = useTranslation();

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) i18n.changeLanguage(user.lang);
  }, [user, i18n]);

  const getUser = useCallback(async () => {
    const token = LSAccessToken.get();

    if (token) {
      serverApi.setAccessToken(token);

      const { status, data } = await serverApi.getUser();

      if (status === 200) {
        setUser(data.data);
      } else {
        setUser(null);
      }
    } else {
      setUser(null);
    }
  }, []);

  const login = useCallback(() => {
    authApi.login();
  }, []);

  const checkAuthCode = useCallback(async () => {
    const code = getURLSearchParam("code");

    if (code) {
      const ok = await authApi.getAccessToken(code);
      if (ok) getUser();
    } else {
      login();
    }
  }, [getUser, login]);

  const logout = useCallback(() => {
    serverApi.clearAccessToken();
    authApi.clearLocalStorage();
    authApi.logout();
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, login, checkAuthCode, logout, setUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthProvider = () => useContext(AuthContext);
