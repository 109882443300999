import React, { useCallback, useEffect, useState } from "react";

import { IconProps } from "icons/types";

type Action = () => void;

interface DropdownMenuProps {
  children: React.ReactNode;
  options: {
    title: string;
    icon: React.FC<IconProps>;
    color: string;
    action: Action;
  }[];
  onHideMenu?: () => void;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  children,
  options,
  onHideMenu,
}) => {
  const [isMenuActive, setIsMenuActive] = useState(false);

  const showMenu = useCallback(() => {
    setIsMenuActive(true);
  }, []);

  const hideMenu = useCallback(() => {
    setIsMenuActive(false);
  }, []);

  const handleAction = useCallback(
    (action: Action) => {
      hideMenu();
      action();
    },
    [hideMenu]
  );

  const preventClicks = useCallback(
    (event: MouseEvent) => {
      if ((event.target as Element).closest("[data-allow-click]")) return;

      event.stopPropagation();
      event.preventDefault();

      hideMenu();
      if (onHideMenu) onHideMenu();
    },
    [hideMenu, onHideMenu]
  );

  useEffect(() => {
    if (isMenuActive) {
      document.addEventListener("click", preventClicks, true);
    } else {
      document.removeEventListener("click", preventClicks, true);
    }

    return () => {
      document.removeEventListener("click", preventClicks, true);
    };
  }, [isMenuActive, preventClicks]);

  return (
    <div className="relative flex">
      <div className="flex" onClick={showMenu}>
        {children}
      </div>

      {isMenuActive && (
        <div
          className="absolute min-w-[256px] rounded-lg bg-gray3 border border-gray4 right-0 overflow-hidden z-10"
          style={{ bottom: -(options.length * 48 + 12) }}
        >
          {options.map((option, i) => (
            <div
              key={i}
              data-allow-click
              className="h-[48px] px-4 flex justify-between items-center cursor-pointer transition-all duration-200 ease-in-out hover:bg-[rgba(255,255,255,.15)]"
              style={{ color: option.color }}
              onClick={() => handleAction(option.action)}
            >
              <span className="text-sm font-normal">{option.title}</span>
              <option.icon size={24} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
