import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useAuthProvider } from "provider";
import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { getProfilePicture } from "utils/getProfilePicture";
import { SER } from "utils/serverErrorHandler";
import { ModalChangeLanguage } from "components/Modal/ModalChangeLanguage";
import { Header } from "components/Header";
import { Image } from "components/Image";
import { Button } from "components/Button";
import { ExitIcon } from "icons/ExitIcon";
import { ShareIcon } from "icons/ShareIcon";
import { UserLanguage } from "types";

const contentSize = getCurrentScreenData({
  sm: { image: 70, icon: 40 },
  all: { image: 96, icon: 46 },
});

export const Settings: React.FC = () => {
  const [isModalChangeLanguage, setIsModalChangeLanguage] = useState(false);
  const [userLanguages, setUserLanguages] = useState<UserLanguage[]>([]);

  const { t } = useTranslation();
  const { user, logout } = useAuthProvider();

  const getUserLanguages = useCallback(() => {
    SER(async () => {
      const { data: languages } = await serverApi.getUserLanguages();
      setUserLanguages(languages.data);
    });
  }, []);

  useEffect(() => {
    getUserLanguages();
  }, [getUserLanguages]);

  const showModalChangeLanguage = useCallback(() => {
    setIsModalChangeLanguage(true);
  }, []);

  const hideModalChangeLanguage = useCallback(() => {
    setIsModalChangeLanguage(false);
  }, []);

  const manageAccount = useCallback(() => {
    window.open("https://account.zirkonzahn.cloud", "_blank");
  }, []);

  const selectedLanguage = useMemo(
    () => userLanguages.find((language) => language.lang === user?.lang)?.name,
    [userLanguages, user]
  );

  return (
    <>
      <Header title={t("common.settings")} />

      {user ? (
        <section className="px-8 py-6 md:px-6 md:py-4 sm:px-4 sm:py-2">
          <div className="bg-gray1 rounded-lg p-6 sm:p-4 flex items-center justify-between lg:flex-wrap">
            <div className="flex items-center lg:min-w-full">
              <Image
                rounded="full"
                size={contentSize.image}
                iconSize={contentSize.icon}
                src={getProfilePicture(user)}
              />

              <div className="ml-8 md:ml-6 sm:ml-4">
                <h2 className="text-white text-2xl md:text-xl sm:text-lg font-bold">
                  {user.name} {user.surname}
                </h2>
                <span className="text-gray6 text-base sm:text-sm font-normal">
                  {user.email}
                </span>
              </div>
            </div>

            <div className="flex lg:mt-6 lg:flex-1 lg:justify-between">
              <Button
                type="transparentBorder"
                IconAfter={ShareIcon}
                onClick={manageAccount}
              >
                {t("common.manage_account")}
              </Button>

              <Button type="logout" IconAfter={ExitIcon} onClick={logout}>
                {t("common.logout")}
              </Button>
            </div>
          </div>

          <div className="mt-20 md:mt-16 sm:mt-10">
            <h2 className="text-white text-2xl md:text-xl font-bold">
              {t("common.preferences")}
            </h2>
            <span className="block mt-1 text-gray9 text-base sm:text-sm font-normal">
              {t("common.preferences_info")}
            </span>

            <div className="px-6 sm:px-4 mt-8 h-[80px] sm:h-[70px] bg-gray1 rounded-lg flex items-center">
              <span className="text-white text-base sm:text-sm font-bold w-1/5 lg:w-2/5">
                {t("common.language")}
              </span>
              <span className="text-white text-base sm:text-sm font-normal w-4/5 lg:w-3/5">
                {selectedLanguage}
              </span>

              <Button
                type="transparentBorder"
                onClick={showModalChangeLanguage}
              >
                {t("common.change")}
              </Button>
            </div>
          </div>
        </section>
      ) : null}

      {isModalChangeLanguage && (
        <ModalChangeLanguage
          userLanguages={userLanguages}
          hideModal={hideModalChangeLanguage}
        />
      )}
    </>
  );
};
