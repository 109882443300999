import { toast } from "react-toastify";

// SERVER ERROR HANDLER
export const SER = async (
  tryCb: () => void,
  catchCb?: () => void,
  finallyCb?: () => void
) => {
  try {
    await tryCb();
  } catch (error: any) {
    toast.error(error.response.data.error_description);
    if (catchCb) await catchCb();
  } finally {
    if (finallyCb) await finallyCb();
  }
};
