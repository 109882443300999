import { serverInstance as api } from "api/instances";
import { createUrlencoded } from "utils/createUrlencoded";

export type UpdateUserData = {
  lang: string;
};

export type CheckUserData = {
  email: string;
};

const URL = "user";

export const getUser = () => api.get(URL);

export const getUserLanguages = () => api.get(`${URL}/languages`);

export const updateUser = (data: UpdateUserData) =>
  api.request({
    method: "patch",
    url: URL,
    data: createUrlencoded(data),
  });

export const checkUser = (data: CheckUserData) =>
  api.request({
    method: "post",
    url: `${URL}/check/invitable`,
    data: createUrlencoded(data),
  });
