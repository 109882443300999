import React from "react";

import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { stopPropagation } from "utils/stopPropagation";
import { IconProps } from "icons/types";

const styles = {
  default: {
    backgroundColor: "#00714F",
  },
  danger: {
    backgroundColor: "transparent",
    borderColor: "#4D4D4D",
    color: "#EB5757",
  },
  transparent: {
    backgroundColor: "transparent",
  },
  transparentBorder: {
    marginLeft: 0,
    backgroundColor: "transparent",
    borderColor: "#4D4D4D",
  },
  gray: {
    backgroundColor: "#333",
  },
  grayNoOffset: {
    marginLeft: 0,
    backgroundColor: "#333",
  },
  confirmationModalCancel: {
    marginLeft: 0,
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#333",
  },
  confirmationModalAction: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#EB5757",
  },
  logout: {
    backgroundColor: "rgb(189,62,62,0.1)",
    borderColor: "#EB5757",
    color: "#EB5757",
  },
  scan: {
    marginLeft: 0,
    width: "100%",
    justifyContent: "center",
    backgroundColor: "#00714F",
  },
  clear: {
    color: "#00714F",
    backgroundColor: "transparent",
  },
};

const iconSize = getCurrentScreenData({ sm: 20, md: 22, all: 24 });

interface ButtonProps {
  children: React.ReactNode;
  isLabelFor?: boolean;
  isButton?: boolean;
  type?: keyof typeof styles;
  disabled?: boolean;
  IconBefore?: React.FC<IconProps>;
  IconAfter?: React.FC<IconProps>;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  isLabelFor,
  isButton,
  type = "default",
  disabled,
  IconBefore,
  IconAfter,
  onClick,
}) => (
  <button
    type={isLabelFor || isButton ? "button" : undefined}
    className={`relative h-[48px] sm:h-[42px] px-6 sm:px-4 ml-4 rounded-lg disabled:opacity-50 disabled:after:hover:opacity-50 text-white flex items-center border border-transparent ${
      isLabelFor ? "pointer-events-none" : ""
    } btn-hover-after after:rounded-lg`}
    style={styles[type]}
    onClick={stopPropagation(onClick)}
    disabled={disabled}
  >
    {IconBefore ? (
      <div className="mr-4 md:mr-3 sm:mr-2">
        <IconBefore size={iconSize} />
      </div>
    ) : null}

    <span className="font-semibold whitespace-nowrap capitalize text-base sm:text-sm">
      {children}
    </span>

    {IconAfter ? (
      <div className="ml-4 md:ml-3 sm:ml-2">
        <IconAfter size={iconSize} />
      </div>
    ) : null}
  </button>
);
