import { serverInstance as api } from "api/instances";
import {
  Baking,
  BakingDetails,
  Device,
  FurnaceDevice,
  Maintenance,
  MillingDevice,
} from "types";

const URL = "e/machine/device/v5";

export const getDevices = (unitKeys: string[]) =>
  api.get<Device[]>(`${URL}/all`, { params: { unitKeys } });

export const deviceExists = (unitKey: string) =>
  api.get<boolean>(`${URL}/all/${unitKey}/exists`);

export const getMaintenances = (unitKey: string) =>
  api.get<Maintenance[]>(`${URL}/maintenance/${unitKey}`);

export const getMillingDevice = (unitKey: string) =>
  api.get<MillingDevice>(`${URL}/milling/${unitKey}`);

export const getFurnaceDevice = (unitKey: string) =>
  api.get<FurnaceDevice>(`${URL}/furnace/${unitKey}`);

export const getFurnaceBakings = (unitKey: string) =>
  api.get<Baking[]>(`${URL}/furnace/${unitKey}/bakings`);

export const getFurnaceBaking = (bakingId: string) =>
  api.get<BakingDetails>(`${URL}/furnace/baking/${bakingId}`);
