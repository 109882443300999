import React from "react";

import { IconProps } from "./types";

export const ExitIcon: React.FC<IconProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M13.2925 5H5.05029C4.49801 5 4.05029 5.44772 4.05029 6V18C4.05029 18.5523 4.49801 19 5.05029 19H13.2925C13.8448 19 14.2925 18.5523 14.2925 18V14.826L16.2925 14.826V18C16.2925 19.6569 14.9493 21 13.2925 21H5.05029C3.39344 21 2.05029 19.6569 2.05029 18V6C2.05029 4.34315 3.39344 3 5.05029 3H13.2925C14.9493 3 16.2925 4.34315 16.2925 6V9.17414L14.2925 9.17414V6C14.2925 5.44772 13.8448 5 13.2925 5Z"
      fill="currentColor"
    />
    <path
      d="M17.8827 10.3474L18.5355 11.0001L10.0504 11.0001C9.49809 11.0001 9.05037 11.4478 9.05037 12.0001C9.05037 12.5524 9.49809 13.0001 10.0504 13.0001L18.5354 13.0001L17.8827 13.6528C17.4922 14.0433 17.4922 14.6765 17.8827 15.067C18.2733 15.4575 18.9064 15.4575 19.297 15.067L21.6568 12.7072C21.8443 12.5196 21.9497 12.2653 21.9497 12.0001C21.9497 11.7349 21.8443 11.4805 21.6568 11.293L19.297 8.93316C18.9064 8.54263 18.2733 8.54263 17.8827 8.93316C17.4922 9.32368 17.4922 9.95685 17.8827 10.3474Z"
      fill="currentColor"
    />
  </svg>
);
