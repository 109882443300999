import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useTypedOutletContext } from "hooks/useTypedOutletContext";
import { SER } from "utils/serverErrorHandler";
import { getUniqueArray } from "utils/getUniqueArray";
import { getProfilePicture } from "utils/getProfilePicture";
import { Modal } from "components/Modal/Modal";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { ButtonIcon } from "components/ButtonIcon";
import { Image } from "components/Image";
import { CheckIcon } from "icons/CheckIcon";
import { DashedCircle } from "icons/DashedCircle";
import { Id, Invitation } from "types";

interface ModalAddMemberProps {
  labId: Id;
  hideModal: () => void;
}

type FormEventValues = {
  email: { value: string };
};

export const ModalAddMember: React.FC<ModalAddMemberProps> = ({
  labId,
  hideModal,
}) => {
  const [labInvitationList, setLabInvitationList] = useState<Invitation[]>([]);

  const { t } = useTranslation();

  const { labJoinRequestList, getLabJoinRequestList } = useTypedOutletContext();

  const getData = useCallback(() => {
    SER(async () => {
      const { data: labInvitationList } = await serverApi.getLabInvitationList(
        labId
      );
      setLabInvitationList(labInvitationList.data);
    });
  }, [labId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const declineJoinRequest = useCallback(
    (joinId: Id) => {
      SER(async () => {
        await serverApi.declineJoinRequest(joinId);
        await getLabJoinRequestList();
        toast.success(t("message.join_rejected"));
      });
    },
    [t, getLabJoinRequestList]
  );

  const acceptJoinRequest = useCallback(
    (joinId: Id) => {
      SER(async () => {
        await serverApi.acceptJoinRequest(joinId);
        await getLabJoinRequestList();
        toast.success(t("message.join_accepted"));
      });
    },
    [t, getLabJoinRequestList]
  );

  const revokeLabInvitation = useCallback(
    (invitationUuid: string) => {
      SER(async () => {
        await serverApi.revokeLabInvitation(invitationUuid);
        setLabInvitationList(
          labInvitationList.filter(({ uuid }) => uuid !== invitationUuid)
        );
        toast.success(t("message.invitation_revoked"));
      });
    },
    [t, labInvitationList]
  );

  const onSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      const target = event.target as typeof event.target & FormEventValues;

      SER(async () => {
        const { data: invitation } = await serverApi.createLabEmailInvitation(
          labId,
          target.email.value
        );

        setLabInvitationList(
          getUniqueArray(
            [...labInvitationList, invitation.data as Invitation],
            "id"
          )
        );

        target.email.value = "";

        toast.success(t("message.invitation_created"));
      });
    },
    [t, labId, labInvitationList]
  );

  return (
    <Modal title={t("common.add_member")} hideModal={hideModal}>
      {labJoinRequestList.length ? (
        <div className="px-8 py-6 border-b border-gray3 bg-darkGreen sm:px-6 sm:py-4">
          <h2 className="text-base text-white font-bold leading-5">
            {t("common.new_members_request")}
          </h2>

          {labJoinRequestList.map((item) => (
            <div
              key={item.id}
              className="mt-6 h-[40px] flex items-center justify-between"
            >
              <div className="flex items-center">
                <Image
                  rounded="full"
                  size={40}
                  iconSize={20}
                  src={getProfilePicture(item)}
                />

                <div className="ml-4 flex flex-col">
                  <span className="text-base font-semibold text-white leading-5">
                    {item.name} {item.surname}
                  </span>
                  <span className="text-base font-normal text-gray6 leading-5">
                    {item.email}
                  </span>
                </div>
              </div>

              <div className="flex">
                <ButtonIcon
                  size="sm"
                  onClick={() => declineJoinRequest(item.id)}
                />

                <div
                  className="ml-4 text-white w-[48px] h-[32px] rounded-full bg-green flex items-center justify-center cursor-pointer"
                  onClick={() => acceptJoinRequest(item.id)}
                >
                  <CheckIcon />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}

      <div className="px-8 py-6 sm:px-6 sm:py-4">
        <form className="flex" onSubmit={onSubmit}>
          <Input
            buttonOneLine
            name="email"
            type="email"
            placeholder={t("common.type_email")}
          />
          <Button>{t("common.invite")}</Button>
        </form>

        {labInvitationList.length ? (
          <h2 className="mt-6 text-base text-white font-bold leading-5">
            {t("common.pending_invites")}
          </h2>
        ) : null}

        {labInvitationList.map((item) => (
          <div key={item.id} className="mt-4 flex items-center justify-between">
            <div className="flex items-center text-gray4">
              <DashedCircle />

              <span className="ml-4 text-base text-gray6 font-normal">
                {item.email}
              </span>
            </div>

            <ButtonIcon
              size="sm"
              onClick={() => revokeLabInvitation(item.uuid)}
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};
