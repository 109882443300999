import { serverInstance as api } from "api/instances";
import { createUrlencoded } from "utils/createUrlencoded";
import { Group, Id } from "types";

type GroupData = {
  name: string;
  licenses: number[];
  members: number[];
};

const URL = "lab/group";

export const getLabGroups = (labId: Id) =>
  api.get<{ data: Group[] }>(`${URL}/${labId}`);

export const createGroup = (labId: Id, data: GroupData) =>
  api.request({
    method: "post",
    url: `${URL}/${labId}`,
    data: createUrlencoded(data),
  });

export const updateGroup = (labId: Id, groupId: Id, data: GroupData) =>
  api.request({
    method: "patch",
    url: `${URL}/${labId}/${groupId}`,
    data: createUrlencoded(data),
  });
