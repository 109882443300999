import React from "react";

import { PuzzleIcon } from "icons/PuzzleIcon";
import { Module } from "types";

interface ItemModuleProps {
  module: Module;
}

export const ItemModule: React.FC<ItemModuleProps> = ({ module }) => (
  <div className="h-[28px] px-2 flex rounded-full border text-gray6 border-gray4 bg-gray3">
    <PuzzleIcon />

    <span className="ml-1 text-base text-white font-normal">{module.name}</span>
  </div>
);
