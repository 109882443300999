import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { getTimeDiff } from "utils/getTimeDiff";
import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { getDeviceType } from "utils/getDeviceType";
import { CircleProgressBar } from "components/CircleProgressBar";
import { Image } from "components/Image";
import { Device } from "types";
import { Badge } from "components/Badge";

const contentSize = getCurrentScreenData({
  sm: { circle: 120, strokeWidth: 5, image: 70, icon: 26 },
  all: { circle: 216, strokeWidth: 8, image: 120, icon: 40 },
});

interface ItemDeviceProps {
  device: Device;
  isActive?: boolean;
  isDraggable?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const ItemDevice: React.FC<ItemDeviceProps> = ({
  device,
  isActive,
  isDraggable,
  children,
  onClick,
}) => {
  const [isClick, setIsClick] = useState(true);

  const { t } = useTranslation();

  const {
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
    setNodeRef,
  } = useSortable({ id: isDraggable ? device.unitKey : "" });

  const style = useMemo(
    () => ({
      transition,
      transform: CSS.Transform.toString(transform),
      zIndex: isDragging ? 1000 : "auto",
      cursor: onClick ? "pointer" : "auto",
    }),
    [isDragging, transform, transition, onClick]
  );

  const handleMouseDown = useCallback(() => {
    setIsClick(true);
  }, []);

  const handleMouseMove = useCallback(() => {
    setIsClick(false);
  }, []);

  const handleMouseUp = useCallback(() => {
    if (isClick && onClick) onClick();
  }, [isClick, onClick]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...(isDraggable ? listeners : {})}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      className={`relative bg-gray1 rounded-lg w-[312px] lg:w-[280px] md:w-[calc(50%-8px)] sm:w-full border-2 ${
        isActive ? "border-green" : "border-transparent"
      }`}
    >
      <div className="h-[54px] sm:h-[48px] mx-4 border-b border-gray3 flex flex-col sm:flex-row justify-center items-center sm:justify-between">
        <span
          className="text-base font-normal"
          style={{ color: device.stateColor }}
        >
          {device.state}
        </span>
        {device.state === "Offline" ? (
          <span
            className="ml-2 text-xs font-normal"
            style={{ color: device.stateColor }}
          >
            {t("common.last_status_update")}: {getTimeDiff(t, device.updatedAt)}
          </span>
        ) : null}
      </div>

      <div className="relative flex flex-col sm:flex-row items-center py-8 lg:py-6 md:py-4 sm:px-4">
        {device.progress ? (
          <span className="absolute right-4 top-4 md:top-2 text-white text-xl md:text-lg font-bold">
            {device.progress}%
          </span>
        ) : null}

        <div className="relative">
          <CircleProgressBar
            size={contentSize.circle}
            strokeWidth={contentSize.strokeWidth}
            strokeColor={device.stateColor}
            progress={device.progress || 0}
          />

          <div className="absolute top-0 right-0 left-0 bottom-0 flex justify-center items-center">
            <Image
              rounded="lg"
              size={contentSize.image}
              iconSize={contentSize.icon}
              src={device.imageUrl}
            />
          </div>
        </div>

        <div className="flex max-w-full items-center flex-col sm:items-start sm:ml-4 overflow-hidden">
          <span
            title={device.unitName}
            className="max-w-full mt-4 sm:mt-0 px-6 sm:px-0 text-white text-xl font-bold whitespace-nowrap text-ellipsis overflow-hidden"
          >
            {device.unitName}
          </span>

          <span className="text-gray6 font-normal text-base capitalize">
            {getDeviceType(device, t)}
          </span>
        </div>
      </div>

      {children}

      {device.messagesUnreadCount > 0 ? <Badge /> : null}
    </div>
  );
};
