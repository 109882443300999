import React from "react";

import { Image } from "components/Image";
import { getProfilePicture } from "utils/getProfilePicture";
import { Contact, Lab } from "types";

interface ItemLabProps {
  lab: Lab | Contact;
  children?: React.ReactNode;
}

export const ItemLab: React.FC<ItemLabProps> = ({ lab }) => (
  <div className="flex items-center p-2 pr-4 rounded-2xl border border-gray4 bg-gray3">
    <Image rounded="lg" size={32} iconSize={20} src={getProfilePicture(lab)} />

    <span className="text-white text-sm font-normal ml-4">{lab.name}</span>
  </div>
);
