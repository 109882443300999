import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AvatarEditor from "react-avatar-editor";

import { getCurrentScreenData } from "utils/getCurrentScreenData";
import { Modal } from "components/Modal/Modal";
import { Button } from "components/Button";
import { InfoIcon } from "icons/InfoIcon";
import { RefreshIcon } from "icons/RefreshIcon";

const editorSize = getCurrentScreenData({ sm: 180, md: 280, all: 340 });
const minScale = 0.4;
const maxScale = 2;
const stepScale = 0.2;

interface ModalEditImageProps {
  blobImage: string;
  rounded?: boolean;
  setBlobImage: React.Dispatch<React.SetStateAction<string>>;
  hideModal: (isResetBlobImage?: boolean) => void;
}

export const ModalEditImage: React.FC<ModalEditImageProps> = ({
  blobImage,
  rounded,
  setBlobImage,
  hideModal,
}) => {
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1.2);

  const editor = useRef<AvatarEditor | null>(null);

  const { t } = useTranslation();

  const updateRotate = useCallback(() => {
    setRotate(rotate + 90);
  }, [rotate]);

  const updateScale = useCallback(
    (isIncrement: boolean) => {
      if (isIncrement && scale !== maxScale) {
        setScale(Math.round((scale + stepScale) * 10) / 10);
      } else if (!isIncrement && scale !== minScale) {
        setScale(Math.round((scale - stepScale) * 10) / 10);
      }
    },
    [scale]
  );

  const cancel = useCallback(() => {
    hideModal(true);
  }, [hideModal]);

  const done = useCallback(() => {
    editor.current?.getImageScaledToCanvas()?.toBlob((blob) => {
      if (blob) {
        setBlobImage(URL.createObjectURL(blob));
        hideModal();
      }
    });
  }, [setBlobImage, hideModal]);

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setScale(Number(event.target.value));
  }, []);

  return (
    <Modal
      title={t("common.move_the_picture")}
      footer={
        <div className="flex flex-1 justify-between sm:flex-wrap">
          <div className="flex items-center sm:flex-1 sm:justify-between sm:my-4">
            <div className="flex items-center">
              <div
                className="mr-3 w-[32px] aspect-square flex items-center justify-center border border-gray4 bg-gray3 rounded-lg cursor-pointer"
                onClick={() => updateScale(false)}
              >
                <div className="w-[10px] h-[1px] bg-white" />
              </div>

              <input
                className="slider"
                type="range"
                min={minScale}
                max={maxScale}
                value={scale}
                step={stepScale}
                onChange={onChange}
              />

              <div
                className="relative ml-3 w-[32px] aspect-square flex items-center justify-center border border-gray4 bg-gray3 rounded-lg cursor-pointer"
                onClick={() => updateScale(true)}
              >
                <div className="w-[10px] h-[1px] bg-white" />
                <div className="w-[1px] h-[10px] bg-white absolute" />
              </div>
            </div>

            <div className="ml-3 h-[32px] px-4 rounded-lg border border-gray4 flex items-center">
              <span className="text-white text-sm font-normal">
                {scale * 100}%
              </span>
            </div>
          </div>

          <div className="flex sm:flex-1 sm:justify-end">
            <Button type="transparent" onClick={cancel}>
              {t("common.cancel")}
            </Button>
            <Button onClick={done}>{t("common.done")}</Button>
          </div>
        </div>
      }
    >
      <div className="flex justify-center">
        <div className="px-8 py-6 sm:px-0 sm:py-0 relative">
          <AvatarEditor
            ref={editor}
            image={blobImage}
            width={editorSize}
            height={editorSize}
            borderRadius={rounded ? editorSize / 2 : 16}
            color={[0, 0, 0, 0.65]}
            border={[100, 50]}
            scale={scale}
            rotate={rotate}
          />

          <div
            className="text-white flex items-center px-4 py-2 rounded-lg border border-gray4 bg-gray3 absolute bottom-10 right-12 sm:bottom-2 sm:right-2 cursor-pointer hover:opacity-80 transition-all duration-300 ease-in-out"
            onClick={updateRotate}
          >
            <RefreshIcon size={16} />
            <span className="ml-2 font-semibold text-sm">
              {t("common.rotate")}
            </span>
          </div>
        </div>
      </div>

      <div className="flex px-8 py-6 sm:px-6 sm:py-4 border-t border-gray3 text-gray6 items-center">
        <InfoIcon size={24} />

        <span className="font-normal text-sm ml-2">
          {t("common.move_picture_info")}
        </span>
      </div>
    </Modal>
  );
};
