import { URL } from "constants/ENV";
import { Contact, UserJoinRequest, LabJoinRequest, Lab, User } from "types";

export const getProfilePicture = ({
  profile_picture,
}:
  | Lab
  | User
  | UserJoinRequest
  | LabJoinRequest
  | Contact
  | { profile_picture: string }) =>
  profile_picture ? `${URL.API}${profile_picture}` : "";
