import React, { useState } from "react";

import { UsersIcon } from "icons/UsersIcon";
import { IconProps } from "icons/types";

interface ImageProps {
  size: number;
  iconSize: number;
  src: string | null | undefined;
  rounded?: "none" | "full" | "lg";
  title?: string;
  PlaceholderIcon?: React.FC<IconProps>;
}

export const Image: React.FC<ImageProps> = ({
  size,
  iconSize,
  src,
  rounded = "none",
  title,
  PlaceholderIcon,
}) => {
  const [isError, setIsError] = useState(false);

  return src && !isError ? (
    <img
      src={src}
      alt=""
      className={`aspect-square object-contain rounded-${rounded}`}
      style={{ width: size }}
      onError={() => setIsError(true)}
      title={title}
    />
  ) : (
    <div
      className={`aspect-square bg-gray3 text-gray6 flex items-center justify-center rounded-${rounded}`}
      style={{ width: size }}
      title={title}
    >
      {PlaceholderIcon ? (
        <PlaceholderIcon size={iconSize} />
      ) : (
        <UsersIcon size={iconSize} />
      )}
    </div>
  );
};
