import React from "react";

import { IconProps } from "./types";

export const ErrorIcon: React.FC<IconProps> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      d="M7.37325 6.45577C7.36336 6.10274 7.64685 5.8112 8.00002 5.8112C8.35313 5.8112 8.63657 6.10268 8.6267 6.45565L8.55262 9.10413C8.54425 9.40329 8.29932 9.64148 8.00002 9.64148C7.70077 9.64148 7.45581 9.40334 7.44743 9.10415L7.37325 6.45577Z"
      fill="currentColor"
    />
    <path
      d="M7.99994 11.3696C8.34995 11.3696 8.63369 11.0859 8.63369 10.7359C8.63369 10.3858 8.34995 10.1021 7.99994 10.1021C7.64993 10.1021 7.36619 10.3858 7.36619 10.7359C7.36619 11.0859 7.64993 11.3696 7.99994 11.3696Z"
      fill="currentColor"
    />
    <path
      d="M9.42157 3.89605C8.78973 2.80169 7.21015 2.80169 6.57832 3.89605L2.78732 10.4623C2.15548 11.5566 2.94527 12.9246 4.20894 12.9246H11.7909C13.0546 12.9246 13.8444 11.5566 13.2126 10.4623L9.42157 3.89605ZM12.2648 11.0094L8.47382 4.44324C8.26321 4.07845 7.73668 4.07845 7.52607 4.44324L3.73507 11.0094C3.52445 11.3742 3.78772 11.8302 4.20894 11.8302H11.7909C12.2122 11.8302 12.4754 11.3742 12.2648 11.0094Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);
