import React from "react";

interface BadgeProps {
  absolute?: boolean;
  small?: boolean;
}

export const Badge: React.FC<BadgeProps> = ({ absolute = true, small }) => (
  <div
    className={`aspect-square bg-red rounded-full border-2 border-black ${
      small ? "w-[10px]" : "w-[12px]"
    } ${absolute ? "absolute top-[-4px] right-[-4px]" : ""}`}
  />
);
