import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { SER } from "utils/serverErrorHandler";
import { Loading } from "components/Loading";
import { Empty } from "components/Empty";
import { NetworkIcon } from "icons/NetworkIcon";
import { Message } from "types";
import { formatDate } from "utils/formatDate";
import { Badge } from "components/Badge";

export const DeviceMessages: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { unitKey } = useParams();
  const { t } = useTranslation();

  const getMaintenance = useCallback(() => {
    if (unitKey) {
      SER(async () => {
        setIsLoading(true);

        const { data: messages } = await serverApi.getDeviceMessages(unitKey);
        setMessages(messages);

        setIsLoading(false);
      });
    }
  }, [unitKey]);

  useEffect(() => {
    getMaintenance();
  }, [getMaintenance]);

  useEffect(() => {
    if (messages.length > 0 && unitKey) {
      serverApi.setDeviceMessagesAsRead(unitKey);
    }
  }, [messages, unitKey]);

  return isLoading ? (
    <Loading />
  ) : messages.length ? (
    <div className="flex flex-wrap pt-6 sm:p-4 gap-4 overflow-y-scroll">
      {messages.map((item, i) => (
        <div
          key={i}
          className="py-4 px-6 sm:py-2 sm:px-4 bg-gray1 rounded-lg flex basis-full flex-col"
        >
          <span
            title={item.body}
            className={`text-base sm:text-sm font-bold ${
              item.level === "WARNING" ? "text-red" : "text-white"
            }`}
          >
            {item.body}
          </span>

          <div className="mt-4 flex items-center">
            {!item.read ? (
              <div className="mr-2">
                <Badge absolute={false} small />
              </div>
            ) : null}

            <span className="text-gray6 text-sm">
              {formatDate.toLocaleString(item.sendDate)}
            </span>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <Empty title={t("common.no_messages_warning")} Icon={NetworkIcon} />
  );
};
