import { useOutletContext } from "react-router-dom";

import { Permissions, LabJoinRequest } from "types";

type ContextType = {
  permissions: Permissions;
  labJoinRequestList: LabJoinRequest[];
  getLabJoinRequestList: () => void;
};

export const useTypedOutletContext = useOutletContext<ContextType>;
