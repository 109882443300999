import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { useTypedOutletContext } from "hooks/useTypedOutletContext";
import { formatDate } from "utils/formatDate";
import { SER } from "utils/serverErrorHandler";
import { ItemLicense } from "components/Item/ItemLicense";
import { ItemModule } from "components/Item/ItemModule";
import { ModalEditLicenseName } from "components/Modal/ModalEditLicenseName";
import { ThreeDotsButton } from "components/ThreeDotsButton";
import { Search } from "components/Search";
import { Loading } from "components/Loading";
import { Empty } from "components/Empty";
import { MemberList } from "components/MemberList";
import { LicenseEmptyIcon } from "icons/LicenseEmptyIcon";
import { License } from "types";

const modulesToShow = 2;

export const LaboratoryLicenses: React.FC = () => {
  const [licenses, setLicenses] = useState<License[]>([]);
  const [isModalEditLicenseName, setIsModalEditLicenseName] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { permissions } = useTypedOutletContext();

  const params = useParams();

  const { t } = useTranslation();

  const labId = useMemo(() => Number(params.labId), [params]);

  const getLicenses = useCallback(() => {
    SER(async () => {
      setIsLoading(true);

      const { data: licenses } = await serverApi.getLicenses(labId);
      setLicenses(licenses.data);

      setIsLoading(false);
    });
  }, [labId]);

  useEffect(() => {
    getLicenses();
  }, [getLicenses]);

  const showModalEditLicenseName = useCallback(() => {
    setIsModalEditLicenseName(true);
  }, []);

  const hideModalEditLicenseName = useCallback(() => {
    setIsModalEditLicenseName(false);
    setSelectedLicense(null);
    getLicenses();
  }, [getLicenses]);

  const onChangeSearch: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(({ target }) => {
      setSearchValue(target.value);
    }, []);

  const editLicenseName = useCallback(
    (license: License) => {
      setSelectedLicense(license);
      showModalEditLicenseName();
    },
    [showModalEditLicenseName]
  );

  const filteredLicenses = useMemo(
    () =>
      licenses.filter((license) =>
        license.name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [licenses, searchValue]
  );

  const licensesComponent = useMemo(
    () => (
      <>
        <div className="flex">
          <Search
            placeholder={t("common.search_license")}
            onChange={onChangeSearch}
          />
        </div>

        <div className="xl:overflow-x-scroll xl:max-w-[calc(100vw-128px)] md:max-w-[calc(100vw-112px)] sm:max-w-[calc(100vw-32px)]">
          <div className="xl:min-w-[1150px]">
            <div
              className={`mt-6 sm:mt-4 flex min-h-[48px] border-b border-gray3 items-center ${
                permissions.licenses ? "pr-16" : "pr-8"
              }`}
            >
              <div className="w-2/12 px-4">
                <span className="text-gray6 text-sm font-normal">
                  {t("common.name")}
                </span>
              </div>
              <div className="w-2/12 px-4">
                <span className="text-gray6 text-sm font-normal">
                  {t("common.software")}
                </span>
              </div>
              <div className="w-3/12 px-4">
                <span className="text-gray6 text-sm font-normal">
                  {t("common.moduls")}
                </span>
              </div>
              <div className="w-2/12 px-4">
                <span className="text-gray6 text-sm font-normal">
                  {t("common.expiry")}
                </span>
              </div>
              <div className="w-3/12 px-4">
                <span className="text-gray6 text-sm font-normal">
                  {t("common.members")}
                </span>
              </div>
            </div>

            {filteredLicenses.map((license) => (
              <div
                key={license.id}
                className="group flex py-2 pr-8 items-center justify-between hover:bg-gray1 transition-all duration-200 ease-in-out"
              >
                <div className="flex flex-1 items-center">
                  <div className="w-2/12 px-4 flex">
                    <span className="text-white text-base font-normal">
                      {license.name}
                    </span>
                  </div>

                  <div className="w-2/12 px-4">
                    <ItemLicense showSoftwareName license={license} />
                  </div>

                  <div className="w-3/12 px-4 flex flex-wrap gap-x-4 gap-y-2">
                    {license.modules.slice(0, modulesToShow).map((module) => (
                      <ItemModule key={module.id} module={module} />
                    ))}

                    {license.modules.length > modulesToShow && (
                      <div className="h-[28px] min-w-[28px] px-2 rounded-full bg-gray4 flex items-center justify-center">
                        <span className="font-normal text-sm text-white">
                          +{license.modules.length - modulesToShow}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="w-2/12 px-4">
                    <span className="text-white text-base font-normal">
                      {formatDate.toLocaleDateString(license.end_date) ||
                        t("common.unlimited")}
                    </span>
                  </div>

                  <div className="w-3/12 px-4">
                    <MemberList users={license.users} />
                  </div>
                </div>

                {permissions.licenses && (
                  <ThreeDotsButton onClick={() => editLicenseName(license)} />
                )}
              </div>
            ))}
          </div>
        </div>
      </>
    ),
    [t, permissions, filteredLicenses, editLicenseName, onChangeSearch]
  );

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : licenses.length ? (
        licensesComponent
      ) : (
        <Empty
          Icon={LicenseEmptyIcon}
          title={t("common.no_licenses_warning")}
        />
      )}

      {isModalEditLicenseName && selectedLicense ? (
        <ModalEditLicenseName
          license={selectedLicense}
          hideModal={hideModalEditLicenseName}
        />
      ) : null}
    </>
  );
};
