import { generatePath } from "react-router-dom";

import { ROUTES } from "constants/routes";
import { Device } from "types";

export const getDeviceDetailsRoute = (device: Device) =>
  generatePath(
    device.type === "milling" ? ROUTES.DEVICE_PROCESSES : ROUTES.DEVICE_PROCESS,
    { deviceType: device.type, unitKey: device.unitKey }
  );
