import { T } from "locales/i18n";

export const getTimeDiff = (t: T, date: string) => {
  const seconds = (Date.now() - new Date(date).getTime()) / 1000;

  const day = Math.round(seconds / (60 * 60 * 24));
  const hour = Math.round(seconds / (60 * 60));
  const min = Math.round(seconds / 60);

  if (day >= 1) {
    return day === 1
      ? t("common.day_ago")
      : t("common.days_ago", { count: day });
  } else if (hour >= 1) {
    return hour === 1
      ? t("common.hour_ago")
      : t("common.hours_ago", { count: hour });
  }

  return min === 1 ? t("common.min_ago") : t("common.mins_ago", { count: min });
};
