import React, { useEffect } from "react";

import { useAuthProvider } from "provider";

export const Login: React.FC = () => {
  const { checkAuthCode } = useAuthProvider();

  useEffect(() => {
    checkAuthCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
