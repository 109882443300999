import { serverInstance as api } from "api/instances";
import { createUrlencoded } from "utils/createUrlencoded";
import { Id, License } from "types";

type UpdateLicenseNameData = { name: string };

const URL = "lab/license";

export const getLicenses = (labId: Id) =>
  api.get<{ data: License[] }>(`${URL}/${labId}`);

export const updateLicenseName = (
  labId: Id,
  licenseId: Id,
  data: UpdateLicenseNameData
) =>
  api.request({
    method: "post",
    url: `${URL}/${labId}/rename/${licenseId}`,
    data: createUrlencoded(data),
  });
