import { Device } from "types";
import { T } from "locales/i18n";

export const getDeviceType = (device: Device, t: T) => {
  switch (device.type) {
    case "milling":
      return t("common.milling_type");
    case "furnace":
      return t("common.furnace_type");
    default:
      return null;
  }
};
