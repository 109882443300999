import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { serverApi } from "api";
import { ROUTES } from "constants/routes";
import { SER } from "utils/serverErrorHandler";

export const Invitation: React.FC = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (uuid) {
      SER(
        async () => {
          await serverApi.acceptLabInvitation(uuid);
          toast.success(t("message.invitation_accepted"));
        },
        undefined,
        () => {
          navigate(ROUTES.LABORATORIES, { replace: true });
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
